import { authInstance } from 'utils/utils'

export const fetchRequest = async (input: NodeJS.fetch.RequestInfo, init?: RequestInit) => {
  const token = await authInstance?.currentUser?.getIdToken()
  return fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
