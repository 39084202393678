import { apiRoutes } from 'apiRoutes'
import { notify } from 'helpers'
import { t } from 'i18next'
import { rootServiceApi } from 'store/service'
import type { Channels, DeviceInfos } from 'utils/types/types'
export type SubscribedDevice = { id: string; device_info: DeviceInfos; endpoint: string; auth: string }
export type GetSubscriptionResult = Array<SubscribedDevice>
export const pushNotificationService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    subscriptions: build.query<GetSubscriptionResult, null>({
      query: () => ({
        url: apiRoutes.web_push_subscription.get,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: ['web-push-subscription'],
      // transformResponse: (baseQueryReturnValue: GetSubscriptionResult, meta, arg) => {
      //   console.log(arg.auth)
      //   return baseQueryReturnValue.filter((item) => item?.auth !== arg?.auth)
      // },
    }),
    unsubscribe: build.mutation<boolean, { id: string | undefined }>({
      query: (data) => ({
        url: apiRoutes.web_push_subscription.unsubscribe,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['web-push-subscription'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled
          if (response) {
            notify(t('profileSettings:deviceRemovedSuccess'), false)
          }
        } catch (error) {
          console.log(error)
          notify(t('profileSettings:deviceRemovedError'), false)
        }
      },
    }),
    deleteSubscription: build.mutation<boolean, { endpoint: string | undefined }>({
      query: (data) => ({
        url: apiRoutes.web_push_subscription.removeSubscription,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['get-channels'],
      // async onQueryStarted(arg, { queryFulfilled }) {
      //   try {
      //     const response = await queryFulfilled
      //     if (response) {
      //       notify(t('profileSettings:deviceRemovedSuccess'), false)
      //     }
      //   } catch (error) {
      //     console.log(error)
      //     notify(t('profileSettings:deviceRemovedError'), false)
      //   }
      // },
    }),
    removeChannel: build.mutation<boolean, { channel: string | undefined }>({
      query: (data) => ({
        url: apiRoutes.web_push_subscription.removeChannel,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled
          if (response) {
            notify(t('profileSettings:channelRemovedSuccess'), false)
          }
        } catch (error) {
          console.log(error)
          notify(t('profileSettings:channelRemovedError'), false)
        }
      },
      invalidatesTags: ['get-channels', 'web-push-subscription'],
    }),
    addChannel: build.mutation<boolean, { channel: string | undefined }>({
      query: (data) => ({
        url: apiRoutes.web_push_subscription.addChannel,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['get-channels', 'web-push-subscription'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled
          if (response) {
            notify(t('profileSettings:channelAddedSuccess'), false)
          }
        } catch (error) {
          notify(t('profileSettings:channelAddingError'), false)
          console.log(error)
        }
      },
    }),
    getNotificationChannels: build.query<Channels[], null>({
      query: () => ({
        url: apiRoutes.getNotificationChannels,
        method: 'GET',
      }),
      providesTags: ['get-channels'],
      keepUnusedDataFor: 1,
    }),
  }),
})

export const { useDeleteSubscriptionMutation, useSubscriptionsQuery, useUnsubscribeMutation, useRemoveChannelMutation, useGetNotificationChannelsQuery, useAddChannelMutation } =
  pushNotificationService
