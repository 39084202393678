import { Autocomplete, TextField } from '@mui/material'
import { CenterRow } from 'components/Core'
import { useGetAllParentProfilesQuery, type GetProfileResult } from 'pages/ParentPages/services'
import { getInitials } from 'utils'
import Styles from 'components/ClientProfileComponents/GeneralInformation/Styles.module.scss'
import { useEffect, useMemo, useState } from 'react'

type Props = {
  onChange: (value: Partial<GetProfileResult>) => void
  width?: string
  maxWidth?: string
}

export const ProfileSelector = ({ onChange, maxWidth, width }: Props) => {
  const [selectedProfile, setSelectedProfile] = useState<Partial<GetProfileResult>>({})

  const { data, isSuccess } = useGetAllParentProfilesQuery(null)
  const lastSelectedProfile = localStorage.getItem('lastSelectedProfile')
  const defaultValue = useMemo(() => {
    const result = data?.find((item) => String(item?.id) === lastSelectedProfile)
    return result
  }, [data, lastSelectedProfile, isSuccess])
  useEffect(() => {
    if (defaultValue) {
      setSelectedProfile(defaultValue)
    }
  }, [defaultValue])

  return (
    <CenterRow>
      <div className={Styles.main_container__header__initials}>{getInitials(selectedProfile?.full_name)}</div>
      <Autocomplete
        options={data ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: 20,
                fontWeight: 'bold',
                ':hover': {
                  cursor: 'pointer',
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => <li {...props}>{option?.full_name ?? ''}</li>}
        getOptionLabel={(option) => (option as Partial<GetProfileResult>)?.full_name ?? ''}
        sx={{
          maxWidth: maxWidth ?? '350px',
          width: width ?? '160px',
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        }}
        onChange={(e, value) => {
          onChange(value)
          setSelectedProfile(value as GetProfileResult)
        }}
        value={selectedProfile}
        // freeSolo
        // clearIcon={null}
        selectOnFocus={false}
        disableClearable
      />
    </CenterRow>
  )
}
