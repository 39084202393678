import { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ChatRoom } from 'pages/NewChatPage/useChat'
import { Box, CenterColumn, CenterRow, Chip } from 'components/Core'

// const StyledChip = styled(Chip)(({ theme }) => ({
//   borderRadius: '16px',
//   backgroundColor: theme.palette.primary.light,
//   '& .MuiChip-label': {
//     fontWeight: 500,
//   },
// }))

interface ChatNameDisplayProps {
  selectedChat: ChatRoom
  user: {
    user: {
      user_type: string
    }
  }
}

export const ChatNameDisplay: FC<ChatNameDisplayProps> = ({ selectedChat, user }) => {
  if (selectedChat.room_name) {
    return (
      <CenterRow flexWrap="wrap" gap={8} width="100%">
        <Typography>{selectedChat.room_name}</Typography>
        <Box pb={[1, 1, 0]} overflowX="scroll">
          <Stack direction="row" spacing={1} gap={1}>
            {selectedChat.participants.map((p, index) => (
              <Chip type="success" key={index} value={`${p.user_name} ~ ${p.role}`} />
            ))}
          </Stack>
        </Box>
      </CenterRow>
    )
  }

  if (selectedChat.participants.length) {
    return (
      <Stack alignItems="center" flexWrap="wrap" direction={'row'} spacing={1}>
        {selectedChat.participants.map((p, index) => (
          <Chip type="success" key={index} value={p.user_name ?? 'none'} />
        ))}
      </Stack>
    )
  }

  if (user.user.user_type === 'parent') {
    return <Typography>{selectedChat.parent_name}</Typography>
  }

  return <Typography>{selectedChat.parent_name}</Typography>
}
