import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { CenterColumn, CenterRow, Text } from 'components/Core'
import { theme } from 'utils/theme'
import { Alert, IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { CourseJourneyItem } from 'utils/types/types'
import { ExplanationCard, MCQContainer, OptionButton, OptionsContainer, QuestionCard, SubmitButton } from './CourseSharedComponents'
import { useAnswerQuestionMutation } from './service'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Direction } from 'components/Core/common/types'
import type { CourseContentType } from 'navigations/locale/course_english'
import { AlertMessage } from 'components/AlertMessage/AlertMessage'
type MCQProps = {
  mcqJourney: CourseJourneyItem
  course_id: number
  journey_index: number
  content: CourseContentType
  direction: Direction
  completeLesson: () => void
  onNext: () => void
  currentQuestion: number
  setCurrentQuestion: Dispatch<SetStateAction<number>>
}
export const MCQ = ({ mcqJourney, course_id, journey_index, content, direction, currentQuestion, setCurrentQuestion, completeLesson, onNext }: MCQProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>('')
  const [showExplanation, setShowExplanation] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)
  const [mcq, setMCQ] = useState<Partial<CourseJourneyItem>>({})
  const answer = useMemo(() => {
    return mcq?.questions?.[currentQuestion]?.answer
  }, [currentQuestion, JSON.stringify(mcq)])
  const correctAnswer = useMemo(() => {
    if (mcq?.questions?.[currentQuestion]?.correctAnswer) {
      return mcq?.questions?.[currentQuestion]?.correctAnswer
    }
  }, [mcq, currentQuestion])
  const questionOptionsLength = useMemo(() => {
    return mcq?.questions?.[currentQuestion]?.options?.length ?? 0
  }, [currentQuestion, mcq?.questions?.[currentQuestion]?.options?.length])
  useEffect(() => {
    setMCQ(mcqJourney)
  }, [JSON.stringify(mcqJourney)])

  const isAnswerWrong = useMemo(() => {
    return selectedAnswer !== correctAnswer
  }, [selectedAnswer, correctAnswer])

  const handleNextQuestion = () => {
    if (currentQuestion === Number(mcq?.questions?.length) - 1) {
      if (answer) {
        onNext()
        setCurrentQuestion(0)
        setShowExplanation(false)
        setSelectedAnswer('')
        setShowBackButton(false)
      }
    } else {
      if (answer) {
        setCurrentQuestion(currentQuestion + 1)
        setShowExplanation(false)
      }
    }
  }
  const handleGoBack = () => {
    if (currentQuestion === 0) {
      setShowBackButton(false)
    } else {
      setCurrentQuestion(currentQuestion - 1)
    }
  }
  useEffect(() => {
    localStorage.setItem('mcq', JSON.stringify(mcq))
  }, [mcq])

  // Api
  const [answerQuestion, { isLoading, error, isError }] = useAnswerQuestionMutation()

  const handleAnswerSubmit = () => {
    answerQuestion({
      course_id: course_id,
      journey_index: journey_index,
      current_question: currentQuestion,
      answer: selectedAnswer ?? '',
      onSuccess: () => {
        // setMCQ((prev) => ({
        //   ...prev,
        //   questions:
        //     prev.questions?.map((question) => {
        //       if (question.id === mcq?.questions?.[currentQuestion]?.id) {
        //         return {
        //           ...question,
        //           answer: selectedAnswer ?? '',
        //         }
        //       }
        //       return question
        //     }) ?? [],
        // }))
      },
    })

    if (isAnswerWrong) {
      setShowExplanation(true)
    } else {
      handleNextQuestion()
    }
  }

  useEffect(() => {
    if (currentQuestion > 0 && questionOptionsLength > currentQuestion) {
      setShowBackButton(true)
    }
  }, [currentQuestion, questionOptionsLength])

  useEffect(() => {
    if (answer) {
      setShowExplanation(isAnswerWrong)
    } else {
      setShowExplanation(false)
    }
    if (currentQuestion === 0) {
      setShowBackButton(false)
    }
  }, [isAnswerWrong, answer, currentQuestion])

  useEffect(() => {
    setSelectedAnswer(answer as string)
  }, [answer])

  const isLastQuestion = useMemo(() => {
    return currentQuestion === Number(mcq?.questions?.length ?? 0) - 1
  }, [currentQuestion, mcq?.questions?.length])

  const disabledChoose = useMemo(() => {
    return showExplanation || Boolean(answer)
  }, [showExplanation, answer])

  const isStepAnsweredAlready = useMemo(() => {
    return answer !== ''
  }, [currentQuestion, answer])

  const highlightCorrectAnswer = useCallback(
    (option: string) => {
      if (!answer) return false

      return correctAnswer === option
    },
    [selectedAnswer, isAnswerWrong, answer],
  )

  return (
    <MCQContainer style={{ gap: '16px' }} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <Text direction={direction} width="100%" maxWidth={['100%']} variant="heading2" mb={4}>
        {mcq?.title}
      </Text>
      <QuestionCard p={[2, 3, 3, 4]} direction={direction}>
        {isError && <Alert severity="error">{content.mcqSubmitError}</Alert>}
        <CenterRow>
          {showBackButton && (
            <IconButton onClick={handleGoBack}>
              <ArrowBack
                sx={{
                  transform: direction === 'rtl' ? 'rotate(180deg)' : 'none',
                }}
              />
            </IconButton>
          )}
        </CenterRow>
        <CenterColumn alignItems="flex-start" gap={1} flexWrap={['wrap']}>
          {/* <Text variant="numberHolder">{currentQuestion + 1} </Text> */}
          <Text maxWidth="100%" fontWeight="normal" variant="heading4" mb={4}>
            {mcq?.questions?.[currentQuestion]?.question}
          </Text>
        </CenterColumn>

        <OptionsContainer style={{ direction }}>
          {mcq?.questions?.[currentQuestion]?.options.map((option, index) => (
            <OptionButton
              key={index}
              onClick={() => setSelectedAnswer(option)}
              selected={selectedAnswer === option}
              correct={!isError && highlightCorrectAnswer(option)}
              wrong={!isError && showExplanation && selectedAnswer === option && option !== correctAnswer}
              disabled={!isError && disabledChoose}
              whileHover={{ scale: disabledChoose ? 1 : 1.02 }}
              whileTap={{ scale: disabledChoose ? 1 : 0.98 }}
            >
              <Text width={'100%'} textAlign={direction === 'ltr' ? 'left' : 'right'} fontWeight="normal" variant="body1">
                {option}
              </Text>
            </OptionButton>
          ))}
        </OptionsContainer>

        <CenterColumn gap={8}>
          {showExplanation && !isError && (
            <AnimatePresence>
              <CenterColumn gap={8}>
                <ExplanationCard direction={direction} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
                  <Text variant="heading4" mb={2}>
                    {content.explanation}
                  </Text>
                  <Text color={theme.colors.black[600]} variant="body1">
                    {mcq?.questions?.[currentQuestion]?.explanation}
                  </Text>
                </ExplanationCard>
                <CenterRow width="100%" justifyContent="flex-end"></CenterRow>
              </CenterColumn>
            </AnimatePresence>
          )}
          <SubmitButton
            onClick={() => {
              if (isStepAnsweredAlready && !isError) {
                handleNextQuestion()
                return
              }
              if (showExplanation && !isError) {
                handleNextQuestion()
              } else {
                handleAnswerSubmit()
              }
            }}
            disabled={!selectedAnswer && !isError}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <CenterRow width="100%" justifyContent={['center']} gap={2}>
              {isLoading ? <Spinner width="25px" /> : null}
              <Text>{isLastQuestion && content.nextLesson}</Text>
              <Text>{!isLastQuestion && (isStepAnsweredAlready && !isError ? content.nextQuestion : content.submitAnswer)}</Text>
            </CenterRow>
          </SubmitButton>
        </CenterColumn>
      </QuestionCard>
    </MCQContainer>
  )
}
