import { useEffect, useMemo, useState } from 'react'
import { ZoomMtg } from '@zoom/meetingsdk'
import { useSelector } from 'react-redux'
import { apiPath, meetingSdkKey, screeningType, userTypes } from 'constants/index'

import { Container, Text } from 'components/Core'
import { theme } from 'utils/theme'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { notify } from 'helpers'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAfterDiscoveryCallMutation } from './service'
import { selectZoomLeaveUrl } from 'defaultLayout/slice/selectors'
import { requestMessages } from 'helpers/requestMessages'
import { fetchRequest } from 'api/fetch'

export function ZoomEmbedded() {
  const navigate = useNavigate()
  const dataForJoin = {
    signature: '',
    sdkKey: meetingSdkKey,
    meetingNumber: '',
    password: '',
    userName: '',
    userEmail: '',
    zak: '',
    registrantToken: '',
  }
  const user = useSelector((state: any) => state.auth.user)
  /**@CustomHooks */
  const { isArabic, language } = useGetLanguage({ userPreferredLanguage: user.preferred_language, userType: 'parent' })

  const [therapistId, setTherapistId] = useState('')
  const [meetingId, setMeetingId] = useState('')
  const [isJoinSuccess, seIsJoinSuccess] = useState(false)
  const [isDiscoveryCall, setIsDiscoveryCall] = useState(false)

  const { isSelectedOpenZoomModal } = useSelector((state: any) => state.zoom)
  const zoomLeaveUrl = useSelector(selectZoomLeaveUrl)

  const feedbackPageUrl = useMemo(() => `${process.env.REACT_APP_ZOOM_LEAVE_URL}/feedback?id=${meetingId}`, [meetingId, process.env.REACT_APP_ZOOM_LEAVE_URL])
  const getSignature = async () => {
    if (meetingId) {
      try {
        const authEndpoint = `${apiPath}/zoom/api/meetings/${meetingId}/join/`
        const req = await fetchRequest(authEndpoint, {
          method: 'GET',
        })
        const res = await req.json()
        if (req.ok) {
          dataForJoin.signature = res.signature
          dataForJoin.meetingNumber = res.meeting_number
          dataForJoin.userName = res.username
          dataForJoin.userEmail = res.user_email

          setTherapistId(res?.therapist_id)

          if (res.screening_type === screeningType.discovery) {
            setIsDiscoveryCall(true)
          }

          startMeeting()
        } else {
          const message = res.message as keyof typeof requestMessages
          if (req.status === 403) {
            // navigate('/unauthorized')
          } else {
            notify(requestMessages[message]?.[language])
          }
        }
      } catch (e) {
        // console.log(e)
      }
    }
  }

  function startMeeting() {
    document.getElementById('zmmtg-root')!.style.display = 'block'
    ZoomMtg.init({
      leaveUrl: [userTypes.parent, userTypes.therapist, userTypes.advisor].includes(user?.user?.user_type) ? feedbackPageUrl : zoomLeaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      screenShare: true,
      success: (success: unknown) => {
        ZoomMtg.join({
          signature: dataForJoin.signature,
          sdkKey: dataForJoin.sdkKey,
          meetingNumber: dataForJoin.meetingNumber,
          passWord: dataForJoin.password,
          userName: dataForJoin.userName,
          userEmail: dataForJoin.userEmail,
          tk: dataForJoin.registrantToken,
          zak: dataForJoin.zak,

          success: (success: unknown) => {
            seIsJoinSuccess(true)
            // const zoomHolder = document.getElementById('zmmtg-root')
            // if (zoomHolder) {
            //   const buttonContainer = document.createElement('div')
            //   buttonContainer.style.position = 'absolute'
            //   buttonContainer.style.top = '10px'
            //   buttonContainer.style.left = '10px'
            //   // buttonContainer.style.zIndex = '999'
            //   zoomHolder.appendChild(buttonContainer)
            //   ReactDOM.render(<ZoomExtraComponents />, buttonContainer)
            // }
          },
          error: (error: unknown) => {
            // console.log(error)
          },
        })
      },
      error: (error: unknown) => {
        // console.log(error)
      },
    })
  }

  /**@Effects */
  useEffect(() => {
    if (meetingId) {
      getSignature()
    }
  }, [meetingId])

  useEffect(() => {
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareWebSDK()
  }, [])

  const [sendTrigger] = useAfterDiscoveryCallMutation()

  useEffect(() => {
    ZoomMtg.inMeetingServiceListener('onUserLeave', () => {
      const profileId = localStorage.getItem('lastSelectedProfile')
      if (isJoinSuccess && profileId && isDiscoveryCall) {
        sendTrigger({
          profile_id: profileId,
          therapist_id: therapistId,
        })
      }
    })
  }, [isJoinSuccess, therapistId, isDiscoveryCall])

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (meetingId) {
      searchParams.set('id', meetingId)
      setSearchParams(searchParams)
    }
  }, [meetingId])

  useEffect(() => {
    const id = isSelectedOpenZoomModal || searchParams.get('id') || ''
    setMeetingId(id)
  }, [isSelectedOpenZoomModal, searchParams])

  return (
    <Container bg={theme.colors.black[100]} className="App" height="100%" width="100%">
      <Text style={{ direction: isArabic ? 'rtl' : 'ltr' }} color={theme.colors.black[900]}>
        {isArabic ? 'جاري تحضير جلستك...' : 'Preparing your session...'}
      </Text>
    </Container>
  )
}
