// Core
// Styles
import { MAIN_STATEMENTS_ANSWERS } from '../../../data/enums'
import { categoryLocals, commonLocals, translateFamilyDynamics, translateParentalWellbeing, translateQuestionnaire } from '../../../data/locale.data'
import Styles from './Styles.module.scss'
import { Divider, Modal } from '@mui/material'
import { AdminsParentOnBoardingReport } from '../../AdminsParentOnBoardingReport/AdminsParentOnBoardingReport'
import { IconWorld, CloseIcon } from '../../../theme/assets/icons'
import { useQuestionnaireHook } from './useQuestionnaireHook'
import { TextButton } from '../../Core/Button'
import { mandatory_questions_parent_onboarding } from '../../../data'
import { Box, Button, CenterRow, Container, Flex, ModalContent, ModalContentContainer, Text } from '../../Core'

export const ParentProfileQuestionnairePartWithTranslation = ({ advisor_additional_comment, questionnaire, hideReportGenerator, profile, style }) => {
  const { handleTranslate, onClose, onOpen, handleReportTranslate, handleRestReport, open, rightAligned, parentLanguage, language, isReportArabic, reportLanguage } =
    useQuestionnaireHook({
      profile,
      Styles,
    })

  return (
    <Flex height={'100%'} flexDirection="column" gap="16px" padding={[2, 2, '24px']} bg="white" borderRadius="16px" style={style}>
      <Flex
        gap={['10px']}
        justifyContent="space-between"
        flexWrap={['wrap']}
        width={['100%']}
        style={{
          direction: language === 'en' ? 'ltr' : 'rtl',
        }}
      >
        <Box>
          <Text mb={1} fontWeight={600} fontSize={20} className={rightAligned}>
            {commonLocals[language]?.['Questionnaire']}
          </Text>
          {parentLanguage === language && <p className={Styles.main_container__subtitle}>{commonLocals[language]?.['Now viewing in parents preferred language']}</p>}
        </Box>
        <CenterRow width={['100%', '100%', '100%', 'auto']} gap={[10]} flexWrap={['wrap']}>
          <Button flexGrow={1} variant="secondary" onClick={handleTranslate}>
            Translate
          </Button>
          {!hideReportGenerator && (
            <Button
              flexGrow={1}
              onClick={() => {
                handleRestReport()
                onOpen()
              }}
              // className={Styles.main_container__btn}
            >
              Generate Report
            </Button>
          )}
        </CenterRow>
      </Flex>
      <div className={`${Styles.main_container__questionnaire} ${rightAligned}`}>
        {questionnaire?.questionnaire?.slice(0, mandatory_questions_parent_onboarding?.length)?.map((section, index) => (
          <div className={Styles.main_container__questionnaire__item} key={section?.id}>
            <p>
              {section?.id + 1}. {categoryLocals[section?.category]?.[language]}
            </p>
            <span>{translateQuestionnaire[index]?.[section?.main_statement]?.[language]}</span>
            <div>
              <p>{commonLocals[language]?.[section?.main_statement_answer] || 'Skipped'}</p>
              <CenterRow direction="ltr" flexWrap={['wrap']} bg="gray.100" gap={10} p={3} borderRadius={'10px'}>
                <Text fontWeight="bold">Advisor comment:</Text> <Text variant="charcoalBody">{section?.advisor_comment || '--'}</Text>
              </CenterRow>
            </div>
            {section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS[0] && (
              <div className={Styles.main_container__questionnaire__item__sub}>
                {section?.sub_statements?.map((statement) => (
                  <div className={Styles.main_container__questionnaire__item__sub__item} key={statement?.id}>
                    <p>
                      {section?.id + 1}.{statement?.id + 1} {translateQuestionnaire[index]?.[statement?.statement]?.[language]}
                    </p>
                    <span>{commonLocals[language]?.[statement?.sub_statement_answer]}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {questionnaire?.familyDynamics && (
          <div className={Styles.optional_sections}>
            <h6
              style={{
                textAlign: isReportArabic ? 'right' : 'left',
              }}
            >
              {categoryLocals['Family dynamics'][language]}
            </h6>
            {questionnaire?.familyDynamics?.map((section) =>
              Object.keys(section ?? {}).length ? (
                <div className={Styles.optional_sections__item} key={section?.id}>
                  <span>
                    {section?.id + 1}. {translateFamilyDynamics[section?.question]?.[language]}
                  </span>
                  <div>
                    <p>{commonLocals[language]?.[section?.answer]}</p>
                  </div>
                </div>
              ) : null,
            )}
          </div>
        )}
        {questionnaire?.parentalWellbeing && (
          <Box className={Styles.optional_sections}>
            <h6
              style={{
                textAlign: isReportArabic ? 'right' : 'left',
              }}
            >
              {categoryLocals['Parental Wellbeing']?.[language]}
            </h6>
            {questionnaire?.parentalWellbeing?.map((section) =>
              Object.keys(section ?? {}).length ? (
                <div className={Styles.optional_sections__item} key={section?.id}>
                  <span>
                    {section?.id + 1}. {translateParentalWellbeing[section?.question]?.[language]}
                  </span>
                  <div>
                    <p>{commonLocals[language]?.[section?.answer]}</p>
                  </div>
                </div>
              ) : null,
            )}
          </Box>
        )}
        <div className={Styles.optional_sections}>
          <h6
            style={{
              textAlign: isReportArabic ? 'right' : 'left',
            }}
          >
            {commonLocals?.[language]?.Additional}
          </h6>
          <p>{questionnaire?.questionnaire?.[mandatory_questions_parent_onboarding?.length]?.tell_us_more || commonLocals?.[language]?.Skipped}</p>
        </div>
        <Divider />
        {advisor_additional_comment && (
          <div className={Styles.optional_sections}>
            <h6
              style={{
                textAlign: isReportArabic ? 'right' : 'left',
              }}
            >
              Additional Advisor Comment
            </h6>
            <p>{advisor_additional_comment}</p>
          </div>
        )}
      </div>
      <Modal open={open} onClose={onClose}>
        <Box variant="modal-container" direction={isReportArabic ? 'rtl' : 'ltr'}>
          <Container flexDirection="column" alignItems="flex-start">
            <CenterRow justifyContent="space-between" width="100%">
              <div>
                <p className={Styles.main_container__typography}>{commonLocals[reportLanguage]?.Report}</p>
                {parentLanguage === reportLanguage && (
                  <p className={Styles.main_container__subtitle}>{commonLocals[reportLanguage]?.['Now viewing in parents preferred language']}</p>
                )}
              </div>
              <div
                style={{
                  direction: reportLanguage === 'en' ? 'ltr' : 'rtl',
                }}
                className={Styles.main_container__flex__center}
              >
                <TextButton onClick={handleReportTranslate}>
                  <IconWorld width={20} /> <span>Translate</span>
                </TextButton>
                <CloseIcon style={{ alignSelf: 'flex-end', cursor: 'pointer' }} onClick={onClose} />
              </div>
            </CenterRow>
            <AdminsParentOnBoardingReport questionnaire={questionnaire} childName={profile?.full_name} language={reportLanguage} />
          </Container>
        </Box>
      </Modal>
    </Flex>
  )
}
