import { selectFirebaseToken, selectUserDetail } from 'defaultLayout/slice/selectors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'reducers/authSlice'

export const useAuth = () => {
  const firebaseToken = useSelector(selectFirebaseToken)
  const user = useSelector(selectUserDetail)
  const dispatch = useDispatch()
  // Temporary fix for user, becuase all older code uses the auth slice
  useEffect(() => {
    dispatch(setUser({ user }))
    localStorage.setItem('user_type', String(user?.user_type))
  }, [JSON.stringify(user)])
  return {
    userAuthenticated: Boolean(firebaseToken),
    token: firebaseToken,
    user,
    nestedUser: { user: user },
  }
}
