import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type QueryArgs = {
  free_consultation_id: number | string
  fail_reason: string
}
const failConsultationService = rootServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    failConsultation: builder.mutation<boolean, QueryArgs>({
      query: (data) => ({
        url: apiRoutes.landing.failConsultation,
        method: 'PUT',
        data,
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
    }),
  }),
})

export const { useFailConsultationMutation } = failConsultationService
