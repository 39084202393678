// Instruments
// import axios from 'axios'
// Constants
import { apiPath } from '../constants'
import { request } from './axios'

export const parentApi = {
  getTherapyInfo: async () => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/parents/therapy/`,
    })
  },
  getOneTherapist: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/${data.id}/`,

      params: {
        profile_id: data?.profile_id,
      },
    })
  },
  declineTherapist: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/screenings/${data.id}/decline/`,

      data: JSON.stringify(data.payload),
    })
  },
  acceptTherapist: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/screenings/${data.id}/accept/`,
    })
  },
  getParentHistory: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/parents/profiles/${data.id}/history/`,
    })
  },
  getProfilesForChat: async () => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/parents/profiles/`,
    })
  },
}
