import { useParams } from 'react-router-dom'
import { useCompleteLessonMutation, useGetCourseDetailQuery, useGetSignedUrlQuery, useUpdateVideoTimeMutation } from '../service'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from 'hooks/useAuth'
import { errorPaymentStatuses } from 'constants/index'
import type { CourseJourneyItem } from 'utils/types/types'
import { useDisclosure } from 'hooks/useDisclosure'
import { useCourseProgress } from './useCourseProgress'
import { Direction } from 'components/Core/common/types'
import { notify } from 'helpers'

export const useCoursePage = () => {
  // States
  const [currentStep, setCurrentStep] = useState<number>()
  const { courseId } = useParams()
  const { user } = useAuth()
  const {
    data,
    isLoading,
    refetch,
    isUninitialized: courseDetailQueryUninitialized,
  } = useGetCourseDetailQuery(courseId as string, { skip: !courseId, refetchOnMountOrArgChange: true })
  // Memos
  const selectedJourney = useMemo(() => data?.journey?.[currentStep ?? 0], [currentStep, data?.journey])
  const youDonNotHaveAccess = useMemo(() => {
    if (errorPaymentStatuses.includes(data?.course_order?.status as string) || !data?.course_order) {
      return true
    }
  }, [data?.course_order?.status])
  const isLastLesson = useMemo(() => {
    return currentStep === Number(data?.journey.length) - 1
  }, [currentStep, data?.journey])

  const { progress } = useCourseProgress(data?.journey)
  const {
    isLoading: fetchingSignedUrl,
    data: signedUrl,
    refetch: refetchSignedUrl,
    isUninitialized,
  } = useGetSignedUrlQuery(
    {
      file_id: selectedJourney?.file_id as number,
    },
    { skip: !selectedJourney?.file_id, refetchOnMountOrArgChange: true },
  )
  useEffect(() => {
    if (!isUninitialized) refetchSignedUrl()
    if (!courseDetailQueryUninitialized) refetch()
  }, [currentStep, isUninitialized, courseDetailQueryUninitialized])
  // Handlers
  const selectCourse = (course: CourseJourneyItem) => {
    const index = data?.journey.findIndex((item) => item.uid === course.uid)
    setCurrentStep(index ?? 0)
  }
  const [updateTime] = useUpdateVideoTimeMutation()
  const updateVideoTime = (duration_seconds: number) => {
    if (duration_seconds < 1) return
    updateTime({
      course_id: Number(data?.id),
      journey_uid: selectedJourney?.uid,
      duration_seconds,
    })
  }
  const [completeLesson] = useCompleteLessonMutation()
  const { open: feedbackOpened, onOpen: handleOpenFeedback, onClose: closeFeedback } = useDisclosure()
  const handleNext = () => {
    completeLesson({
      course_id: Number(data?.id),
      journey_uid: selectedJourney?.uid,
    }).then((res) => {
      if ('error' in res && res['error']) {
        notify('Something went wrong. Please try again.')
        return
      }
      if (isLastLesson) {
        handleOpenFeedback()
        // notify('You have reached the end of the course journey.')
        return
      }

      setCurrentStep((prev) => {
        if (prev !== undefined) {
          return prev + 1
        }
        return 0
      })
    })
  }

  // Effects
  const activeLessonIndex = useMemo(() => data?.journey.findIndex((item) => item.completed === false), [JSON.stringify(data?.journey)])
  useEffect(() => {
    if (activeLessonIndex !== undefined && activeLessonIndex !== -1) {
      if (currentStep === undefined) {
        setCurrentStep(activeLessonIndex)
      }
    }
  }, [activeLessonIndex, currentStep])
  const direction = useMemo<Direction>(() => {
    if (data?.language === 'ar') return 'rtl'
    return 'ltr'
  }, [data?.language])
  return {
    courseId,
    data,
    isLoading,
    youDonNotHaveAccess,
    isLastLesson,
    user,
    currentStep: currentStep ?? 0,
    selectedJourney,
    progress,
    fetchingSignedUrl,
    signedUrl,
    feedbackOpened,
    direction,
    selectCourse,
    completeLesson,
    handleNext,
    updateVideoTime,
    handleOpenFeedback,
    closeFeedback,
    refetch,
    setCurrentStep,
    refetchSignedUrl,
  }
}
