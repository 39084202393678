// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'
import { request } from './axios'

export const profileApi = {
  updateHintModalStep: (data) => {
    return request({
      method: 'PUT',
      url: `/api/parents/profiles/${data.id}/onboarding/`,
      data: JSON.stringify(data.payload),
    })
  },
  getIPAddress: () => {
    return axios({
      url: 'https://api.ipify.org/?format=json',
      method: 'GET',
    })
  },
}
