import axios from 'axios'
import { useState } from 'react'

export const useDownload = () => {
  const [downloading, setIsDownloading] = useState(false)
  const download = async (url: string, filename: string) => {
    setIsDownloading(true)
    await axios(url)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.click()
        URL.revokeObjectURL(url)
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }
  return { download, downloading }
}
