import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, CenterRow, Container, Overflow, Text } from 'components/Core'
import { motion, AnimatePresence } from 'framer-motion'
import { CourseJourneyItem } from 'utils/types/types'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { IconButton, useMediaQuery } from '@mui/material'
import { Close, List } from '@mui/icons-material'
import { debounce } from 'lodash'
import {
  AnimatedWave,
  CloseButton,
  ContentWrapper,
  GradientBackground,
  JourneyItem,
  JourneyMenu,
  MetaInfo,
  NextEpisodeOverlay,
  StepNumber,
  Title,
  VideoContainer,
  VideoDetailOverlay,
} from './CourseSharedComponents'
import { getVideoType } from 'utils/utils'
import { CourseContentType } from 'navigations/locale/course_english'
import type { Direction } from 'components/Core/common/types'
import { theme } from 'utils/theme'

interface CourseVideoProps {
  link: string
  poster?: string
  onNext?: () => void
  journey?: Array<CourseJourneyItem>
  isLastCourse?: boolean
  onSelectCourse?: (course: CourseJourneyItem) => void
  selectedJourney?: CourseJourneyItem
  updateVideoTime?: (duration_seconds: number) => void
  currentStep?: number
  videoConfig?: {
    autoplay?: boolean
    controls?: boolean
    responsive?: boolean
    fluid?: boolean
    preload?: 'auto' | 'metadata' | 'none'
    muted?: boolean
    loop?: boolean
    poster?: string
    sources?: { src: string; type: string }[]
    playbackRates?: number[]
    controlBar?: {
      children: ReactNode[]
    }
  }
  fullScreenModeOnPlay?: boolean
  completeLesson?: () => void
  getSignedUrl: () => void
  isLastLesson?: boolean
  disableRightClick?: boolean
  content?: CourseContentType
  direction?: Direction
}

export const CourseVideo: FC<CourseVideoProps> = ({
  onNext,
  onSelectCourse,
  updateVideoTime,
  completeLesson,
  getSignedUrl,
  link,
  currentStep,
  videoConfig,
  poster,
  journey,
  isLastCourse,
  selectedJourney,
  fullScreenModeOnPlay,
  isLastLesson,
  disableRightClick,
  content,
  direction,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<any>(null)
  const [shouldCompleteLesson, setShouldCompleteLesson] = useState(false)
  const [cancelAutoPlay, setCancelAutoplay] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)
  const [showCourseJourney, setShowCourseJourney] = useState(false)
  const [currentPlayingTime, setCurrentPlayingTime] = useState(0)
  const [showVideoDetailOverlay, setShowVideoDetailOverlay] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(false)

  useEffect(() => {
    // reste eveything
    setVideoEnded(false)
    setShowNextButton(false)
    setCancelAutoplay(false)
    setShowVideoDetailOverlay(false)
    setVideoPlaying(false)
    setShouldCompleteLesson(false)
  }, [currentStep])
  useEffect(() => {
    if (videoEnded && !cancelAutoPlay) {
      onNext?.()
    }
  }, [videoEnded, cancelAutoPlay])

  useEffect(() => {
    return () => {
      updateVideoTime?.(Math.floor(currentPlayingTime))
    }
  }, [])
  const debouncedUpdateProgress = useCallback(
    debounce((time: number) => {
      updateVideoTime?.(Math.floor(time))
    }, 1500),
    [],
  )

  const debouncedCompleteLesson = useCallback(
    debounce(() => {
      completeLesson?.()
    }, 1500),
    [],
  )

  useEffect(() => {
    if (Math.floor(currentPlayingTime) % 15 === 0) {
      debouncedUpdateProgress(currentPlayingTime)
    }
  }, [currentPlayingTime])
  const handleVideoOrientation = useCallback(() => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      // if (document.documentElement.requestFullscreen) {
      //   document.documentElement.requestFullscreen()
      // }
      // @ts-ignore
      screen.orientation.lock('landscape').catch(() => {
        console.log("Doesn't support orientation lock")
      })
    }
    if (fullScreenModeOnPlay) {
      if (playerRef.current) {
        playerRef.current.requestFullscreen()
      }
    }
  }, [window.matchMedia('(max-width: 768px)').matches, fullScreenModeOnPlay])
  const videoType = useMemo(() => getVideoType(link), [link])

  const getTimeLeft = useCallback(() => {
    const currentTime = playerRef?.current?.currentTime() ?? 0
    const duration = playerRef?.current?.duration() ?? 0
    const timeLeft = duration - currentTime
    return { timeLeft, currentTime, duration }
  }, [playerRef?.current?.currentTime(), playerRef?.current?.duration()])
  useEffect(() => {
    const { timeLeft } = getTimeLeft()
    if (timeLeft <= 10 && videoPlaying) {
      setShouldCompleteLesson(true)
    }
  }, [getTimeLeft, videoPlaying])
  useEffect(() => {
    if (shouldCompleteLesson) {
      debouncedCompleteLesson()
    }
  }, [shouldCompleteLesson])
  useEffect(() => {
    if (!videoRef.current) return

    const videoOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      fill: true,
      preload: 'metadata', // Only load metadata initially
      // playbackRates: [0.5, 1, 1.5, 2], // Add playback speed options
      html5: {
        vhs: {
          overrideNative: true,
          enableLowInitialPlaylist: true, // Start with lower quality
          limitRenditionByPlayerDimensions: true,
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      },
      sources: [
        {
          src: link,
          type: videoType,
        },
      ],
    }

    // Add loadedmetadata event listener to capture video frame
    playerRef.current = videojs(videoRef.current, videoOptions)
    playerRef.current.on('loadedmetadata', () => {
      const video = playerRef.current.tech().el()
      video.currentTime = 1 // Set to 1 second to skip potential black frames

      // Once we seek to 1s, capture the frame
      video.addEventListener(
        'seeked',
        () => {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          canvas.getContext('2d')?.drawImage(video, 0, 0)
          playerRef.current.poster(canvas.toDataURL())
          video.currentTime = 0 // Reset video to start
        },
        { once: true },
      )
    })

    // Add quality selector plugin
    playerRef.current.qualityLevels()

    // Handle errors with retry logic
    playerRef.current.on('error', () => {
      setTimeout(() => {
        getSignedUrl()
      }, 1000)
    })
    playerRef.current.on('timeupdate', () => {
      const { timeLeft, currentTime, duration } = getTimeLeft()
      setCurrentPlayingTime(currentTime)
      // debouncedUpdateProgress(currentTime)
      setShowNextButton(timeLeft < 5 && !isLastCourse && duration > timeLeft)
    })
    playerRef.current.on('ended', () => {
      setVideoEnded(true)
    })
    playerRef.current.on('fullscreenchange', () => {
      const isFullscreen = playerRef.current.isFullscreen()
      // Handle fullscreen state change
      if (isFullscreen) {
        // Player entered fullscreen
        setShowCourseJourney(true)
      } else {
        setShowCourseJourney(false)
        // Player exited fullscreen
      }
    })

    playerRef.current.on('play', () => {
      handleVideoOrientation()
      setShowVideoDetailOverlay(false)
      setVideoPlaying(true)
      const { currentTime } = getTimeLeft()
      debouncedUpdateProgress(currentTime)
    })
    playerRef.current.on('pause', () => {
      setShowVideoDetailOverlay(true)
      setVideoPlaying(false)
      const { currentTime } = getTimeLeft()
      debouncedUpdateProgress(currentTime)
    })
  }, [link, selectedJourney?.left_on_video_duration, videoType, handleVideoOrientation])

  useEffect(() => {
    if (disableRightClick) {
      videoRef.current?.addEventListener('contextmenu', (e: any) => {
        e.preventDefault()
      })
    }
  }, [disableRightClick])
  const belowMd = useMediaQuery('(max-width: 992px)')
  useEffect(() => {
    playerRef.current.src(link)
  }, [link, playerRef.current])

  useEffect(() => {
    if (selectedJourney?.left_on_video_duration && videoRef.current && playerRef.current) {
      const duration = Math.abs(selectedJourney?.left_on_video_duration - 3)
      videoRef.current.currentTime = duration
      playerRef.current.currentTime(duration)
    }
  }, [selectedJourney?.left_on_video_duration, videoRef.current, playerRef.current, currentStep])
  return (
    <VideoContainer style={{ direction }}>
      <div data-vjs-player>
        <video
          onKeyDown={(e) => {
            if (e.key === ' ') {
              e.preventDefault()
              videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause()
            }
          }}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
        />
        <div className="video-overlay">
          <AnimatePresence>
            {showVideoDetailOverlay && selectedJourney && (
              <VideoDetailOverlay
                onClick={() => {
                  playerRef.current.play()
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.35 }}
              >
                <GradientBackground />
                <ContentWrapper>
                  <>
                    <Text color="white" variant="heading2">
                      {selectedJourney?.title}
                    </Text>
                    <Text
                      truncateLines={belowMd ? 2 : undefined}
                      variant="body1"
                      fontWeight="normal"
                      color="white"
                      fontSize={[13, 13, 16]}
                      maxWidth={['100%', '100%', '100%', '80%', '60%']}
                      dangerouslySetInnerHTML={{
                        __html: selectedJourney?.description?.replaceAll('\n', '<br/>') ?? '',
                      }}
                    />
                  </>
                  <MetaInfo></MetaInfo>
                </ContentWrapper>
              </VideoDetailOverlay>
            )}
          </AnimatePresence>

          {journey?.length && showCourseJourney && <JourneyComponent videoPlaying={videoPlaying} currentStep={currentStep} journey={journey} onSelectCourse={onSelectCourse} />}

          <AnimatePresence>
            {showNextButton && !cancelAutoPlay && videoPlaying && (
              <CenterRow position="absolute" bottom="32px" right="55px" gap={2}>
                <NextEpisodeOverlay initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }}>
                  <Button
                    style={{
                      fontSize: '16px !important',
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      setCancelAutoplay(true)
                    }}
                    height={'40px'}
                    position="relative"
                  >
                    {/* Fill this with the next episode or course information progress animation */}

                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: '100%' }}
                      transition={{ duration: getTimeLeft().timeLeft ?? 5 }}
                      style={{
                        height: '40px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        zIndex: -1,
                      }}
                    ></motion.div>

                    <Text fontSize={[14, 15, 16]} zIndex={11}>
                      {content?.stopAutoPlay}
                    </Text>
                  </Button>
                </NextEpisodeOverlay>
              </CenterRow>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {cancelAutoPlay && (
              <CenterRow zIndex={9999} position="absolute" bottom="35px" right="10px" gap={2}>
                <Button
                  borderRadius={'10px'}
                  py={16}
                  style={{
                    background: theme.colors.typePurpure[300],
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    onNext?.()
                    updateVideoTime?.(Math.floor(currentPlayingTime))
                  }}
                >
                  {content?.nextLesson}
                </Button>
              </CenterRow>
            )}
          </AnimatePresence>
        </div>
      </div>
    </VideoContainer>
  )
}

type JourneyComponentProps = {
  journey: Array<CourseJourneyItem> | undefined
  onSelectCourse: ((course: CourseJourneyItem) => void) | undefined
  currentStep?: number
  videoPlaying?: boolean
}

export const JourneyComponent = ({ journey, onSelectCourse, currentStep, videoPlaying }: JourneyComponentProps) => {
  const [showJourney, setShowJourney] = useState<boolean>(false)
  return (
    <Container>
      <IconButton
        sx={{
          position: 'absolute',
          top: 20,
          right: 20,
          bgcolor: 'rgba(0, 0, 0, 0.9) !important',
          color: 'white',
          '&:hover': { background: 'rgba(0, 0, 0, 0.8)' },
          display: 'flex !important',
          alignItems: 'center !important',
          justifyContent: 'center !important',
        }}
        onClick={() => setShowJourney((prev) => !prev)}
        size="medium"
      >
        {showJourney ? <Close fontSize="medium" /> : <List fontSize="medium" />}
      </IconButton>
      {showJourney && (
        <AnimatePresence>
          <JourneyMenu
            initial={{ opacity: 0, scale: 0.9, x: 20 }}
            animate={{ opacity: 1, scale: 1, x: 0 }}
            exit={{ opacity: 0, scale: 0.9, x: 20 }}
            transition={{ type: 'spring', damping: 20 }}
          >
            <Overflow maxHeight={['70vh', '80vh', 480]}>
              {journey?.map((item, index) => (
                <JourneyItem
                  key={item.uid || item.title}
                  completed={item.completed}
                  onClick={() => {
                    onSelectCourse?.(item)
                    setShowJourney(false)
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  current={index === currentStep}
                >
                  <StepNumber completed={item.completed}>{item.completed ? '✓' : index + 1}</StepNumber>
                  <Text fontWeight="bold" fontSize={[13, 13, 14]}>
                    {item.title}
                  </Text>
                  {/* {item.completed && (
                  <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2 }}>
                    <Text fontSize={[13, 13, 14]}>(completed)</Text>
                  </motion.div>
                )} */}
                  {index === currentStep && item.type === 'video' && (
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2 }}>
                      {videoPlaying && <AnimatedWave />}
                    </motion.div>
                  )}
                </JourneyItem>
              ))}
            </Overflow>
          </JourneyMenu>
        </AnimatePresence>
      )}
    </Container>
  )
}
