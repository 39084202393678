//Core
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { number, arrayOf, func, node, string } from 'prop-types'
// Mui components
import { createTheme, ThemeProvider } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
// Styles
import Styles from './styles.module.scss'
// Components
import { UserAvatar } from '../UserAvatar'
import { ChannelPreview } from './ChannelPreview'
//Types
import { ChannelType } from '../../../../types'
// Constants
import { availableChatTypesForUserRole } from '../../../../constants'
//Helpers
import { capitalizeWord } from '../../../../helpers'
//Icons
import { ReactComponent as SearchIcon } from '../../../../theme/assets/icons/search_convex.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user_updated.svg'
//Actions
import { chatActions } from '../../../../actions'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { useAuth } from 'hooks/useAuth'

const CustomeMUITheme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
  components: {
    // Name of the component
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: '14px',
        },
        input: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'var(--primary900-color)', // Custom indicator color
        },
        root: {
          borderBottom: '1px solid var(--state-selected)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minWidth: 0,
          padding: '10px 14px',
          fontWeight: '400',
          color: 'var(--text-gray)',
          fontFamily: 'var(--poppins-regular)',
          fontSize: '16px',
          '&:hover': {
            color: 'var(--text-primary)',
          },
          '&.Mui-selected': {
            color: 'var(--text-primary)',
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'red',
          },
        },
        wrapper: {
          // Custom styles for the content wrapper of the Tab component
          textTransform: 'lovercase',
        },
      },
    },
  },
})
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div role="tabpanel" className={Styles.tabpanel} hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: node,
  index: string.isRequired,
  value: string.isRequired,
}

export const ChannelsList = ({ currentUserId, currentUserType, channels, onChatSelection, selectedClientChannel, selectedChannelId, currentProfile, allProfiles }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const dropdownRef = useRef(null)

  const { user } = useAuth()

  const [activeTab, setActiveTab] = useState('all_chats')
  const [allChannels, setAllChannels] = useState([])

  const [isOpenProfilesSelector, setIsOpenProfilesSelector] = useState(false)
  const [selectedProfileId, setSelectedProfileId] = useState(0)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'channels_list', en)
    i18n.addResourceBundle('ar', 'channels_list', ar)
  }, [])

  const handleClickOutsideActions = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenProfilesSelector(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutsideActions)
    return () => document.body.removeEventListener('click', handleClickOutsideActions)
  }, [])

  useEffect(() => {
    if (selectedProfileId) {
      localStorage.setItem('lastSelectedProfile', selectedProfileId)

      navigate(`/profile/parent/chat/${selectedProfileId}`)

      const data = {
        id: selectedProfileId,
      }
      dispatch(chatActions.getAllChannelsClient(data))
    }
  }, [selectedProfileId])

  useEffect(() => {
    setAllChannels(channels)
  }, [channels])

  const handleChangeTab = (_, newValue) => {
    setActiveTab(newValue)
  }

  const handleChangeInput = (e) => {
    const newArr = channels.filter((channel) => channel?.participant?.user_name?.toLowerCase().includes(e.target.value.toLowerCase()))
    setAllChannels(newArr)
  }

  const handleOpenSelector = () => {
    event.stopPropagation()
    setIsOpenProfilesSelector((prev) => !prev)
  }

  const handleSelectProfile = (profileId) => {
    setSelectedProfileId(profileId)
  }

  const translateChatTabs = {
    advisor: {
      en: 'Advisors',
      ar: 'المستشارين',
    },
    therapist: {
      en: 'Therapists',
      ar: 'المعالجين',
    },
  }

  return (
    <ThemeProvider theme={CustomeMUITheme}>
      <div className={Styles.channelsList}>
        <div style={{ padding: '24px' }} className={Styles.channelsList__search}>
          <div className={Styles.channelsList__search__container}>
            <div className={Styles.channelsList__search__container__icon}>
              <SearchIcon />
            </div>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder={t('channels_list:search')} onChange={(e) => handleChangeInput(e)} />
          </div>
        </div>
        {user?.user_type === 'parent' && (
          <div ref={dropdownRef} className={Styles.profiles_container}>
            <div onClick={handleOpenSelector} className={Styles.profiles_container__profile}>
              <p>{currentProfile?.full_name || t('channels_list:new_profile')}</p>
              {isOpenProfilesSelector ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            {isOpenProfilesSelector && (
              <div className={Styles.profiles_container__list}>
                {allProfiles?.map((profile) => (
                  <div onClick={() => handleSelectProfile(profile?.id)} className={Styles.profiles_container__list__item} key={profile?.id}>
                    <div className={Styles.profiles_container__list__item__image}>{profile?.pic ? <img src={profile?.pic} /> : <UserIcon />}</div>
                    {profile?.full_name ? <p>{profile?.full_name}</p> : <span>{t('channels_list:new_profile')}</span>}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div style={{ padding: '0 24px' }} className={Styles.tabs}>
          <Tabs className={Styles.tabs} value={activeTab} onChange={handleChangeTab}>
            <Tab label={t('channels_list:tabs')} value="all_chats" />
            {availableChatTypesForUserRole[`${currentUserType}`]?.map((item, index) => (
              <Tab value={item.value} label={translateChatTabs[item?.name]?.[selectedLanguage || 'en']} key={index} />
            ))}
          </Tabs>
        </div>
        <TabPanel value={activeTab} index="all_chats">
          <div className={Styles.channelsList__channelsContainer}>
            {allChannels &&
              allChannels?.map((channel, index) => (
                <ChannelPreview
                  key={index}
                  channel={channel}
                  currentUserId={currentUserId}
                  chatSelection={onChatSelection}
                  selectedChannelId={selectedChannelId}
                  selectedClientChannel={selectedClientChannel}
                />
              ))}
          </div>
        </TabPanel>
        {availableChatTypesForUserRole[`${currentUserType}`]?.map((item, index) => (
          <TabPanel value={activeTab} index={item.value} key={index}>
            <div className={Styles.channelsList__channelsContainer}>
              {allChannels
                ?.filter((chat) => chat?.participant?.user_role === item.value)
                ?.map((filteredChat, index) => (
                  <ChannelPreview key={index} channel={filteredChat} currentUserId={currentUserId} chatSelection={onChatSelection} selectedChannelId={selectedChannelId} />
                ))}
            </div>
          </TabPanel>
        ))}
      </div>
    </ThemeProvider>
  )
}

ChannelsList.propTypes = {
  currentUserId: number,
  currentUserType: string,
  channels: arrayOf(ChannelType),
  onChatSelection: func,
  selectedChatId: number,
}
