import { userTypes } from 'constants/index'
import { selectUserType } from 'defaultLayout/slice/selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useUserTypeCheck = () => {
  const userType = useSelector(selectUserType)
  const isUserParent = useMemo(() => {
    if (userType === userTypes.parent) {
      return true
    }
    return false
  }, [userType])
  const isUserTherapist = useMemo(() => {
    if (userType === userTypes.therapist) {
      return true
    }
    return false
  }, [userType])

  const isUserAdvisor = useMemo(() => {
    if (userType === userTypes.advisor) {
      return true
    }
    return false
  }, [userType])
  return {
    userType,
    isUserParent,
    isUserTherapist,
    isUserAdvisor,
  }
}
