import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDisclosure } from '../../../hooks/useDisclosure'

export const useQuestionnaireHook = ({ profile, Styles }) => {
  const [language, setLanguage] = useState()
  const [reportLanguage, setReportLanguage] = useState()
  const { open, onOpen, onClose } = useDisclosure()

  /**@Handlers */
  const handleTranslate = () => {
    setLanguage((prev) => {
      const updatedLanguage = prev === 'en' ? 'ar' : 'en'
      setReportLanguage(updatedLanguage)
      return updatedLanguage
    })
  }

  const handleReportTranslate = () => {
    setReportLanguage((prev) => (prev === 'en' ? 'ar' : 'en'))
  }

  const rightAligned = useMemo(() => (language === 'ar' ? Styles?.right_align : ''), [language])
  useEffect(() => {
    setLanguage(profile?.parent_preferred_language ?? 'en')
    setReportLanguage(profile?.parent_preferred_language ?? 'en')
  }, [profile?.parent_preferred_language])
  const isReportArabic = useMemo(() => reportLanguage === 'ar', [reportLanguage])

  const handleRestReport = useCallback(() => {
    setReportLanguage(language)
  }, [language])
  const direction = useMemo(() => (language === 'ar' ? 'rtl' : 'ltr'), [language])
  return {
    open,
    rightAligned,
    parentLanguage: profile?.parent_preferred_language,
    language,
    isReportArabic,
    reportLanguage,
    direction,
    handleTranslate,
    onOpen,
    onClose,
    handleReportTranslate,
    handleRestReport,
  }
}
