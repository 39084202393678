import { ReactComponent as DummyIcon } from '../theme/assets/icons/arrow_down_icon.svg'
export const countries = [
  { value: 'AD_Andorra' },
  { value: 'AE_United Arab Emirates' },
  { value: 'AF_Afghanistan' },
  { value: 'AG_Antigua and Barbuda' },
  { value: 'AI_Anguilla' },
  { value: 'AL_Albania' },
  { value: 'AM_Armenia' },
  { value: 'AO_Angola' },
  { value: 'AQ_Antarctica' },
  { value: 'AR_Argentina' },
  { value: 'AS_American Samoa' },
  { value: 'AT_Austria' },
  { value: 'AU_Australia' },
  { value: 'AW_Aruba' },
  { value: 'AX_Alland Islands' },
  { value: 'AZ_Azerbaijan' },
  { value: 'BA_Bosnia and Herzegovina' },
  { value: 'BB_Barbados' },
  { value: 'BD_Bangladesh' },
  { value: 'BE_Belgium' },
  { value: 'BF_Burkina Faso' },
  { value: 'BG_Bulgaria' },
  { value: 'BH_Bahrain' },
  { value: 'BI_Burundi' },
  { value: 'BJ_Benin' },
  { value: 'BL_Saint Barthelemy' },
  { value: 'BM_Bermuda' },
  { value: 'BN_Brunei Darussalam' },
  { value: 'BO_Bolivia' },
  { value: 'BR_Brazil' },
  { value: 'BS_Bahamas' },
  { value: 'BT_Bhutan' },
  { value: 'BV_Bouvet Island' },
  { value: 'BW_Botswana' },
  { value: 'BY_Belarus' },
  { value: 'BZ_Belize' },
  { value: 'CA_Canada' },
  { value: 'CC_Cocos (Keeling) Islands' },
  { value: 'CD_Congo, Democratic Republic of the Congo' },
  { value: 'CF_Central African Republic' },
  { value: 'CG_Congo, Republic of the Congo' },
  { value: 'CH_Switzerland' },
  { value: "CI_Cote d'Ivoire" },
  { value: 'CK_Cook Islands' },
  { value: 'CL_Chile' },
  { value: 'CM_Cameroon' },
  { value: 'CN_China' },
  { value: 'CO_Colombia' },
  { value: 'CR_Costa Rica' },
  { value: 'CU_Cuba' },
  { value: 'CV_Cape Verde' },
  { value: 'CW_Curacao' },
  { value: 'CX_Christmas Island' },
  { value: 'CY_Cyprus' },
  { value: 'CZ_Czech Republic' },
  { value: 'DE_Germany' },
  { value: 'DJ_Djibouti' },
  { value: 'DK_Denmark' },
  { value: 'DM_Dominica' },
  { value: 'DO_Dominican Republic' },
  { value: 'DZ_Algeria' },
  { value: 'EC_Ecuador' },
  { value: 'EE_Estonia' },
  { value: 'EG_Egypt' },
  { value: 'EH_Western Sahara' },
  { value: 'ER_Eritrea' },
  { value: 'ES_Spain' },
  { value: 'ET_Ethiopia' },
  { value: 'FI_Finland' },
  { value: 'FJ_Fiji' },
  { value: 'FK_Falkland Islands (Malvinas)' },
  { value: 'FM_Micronesia, Federated States of Micronesia' },
  { value: 'FO_Faroe Islands' },
  { value: 'FR_France' },
  { value: 'GA_Gabon' },
  { value: 'GB_United Kingdom' },
  { value: 'GD_Grenada' },
  { value: 'GE_Georgia' },
  { value: 'GF_French Guiana' },
  { value: 'GG_Guernsey' },
  { value: 'GH_Ghana' },
  { value: 'GI_Gibraltar' },
  { value: 'GL_Greenland' },
  { value: 'GM_Gambia' },
  { value: 'GN_Guinea' },
  { value: 'GP_Guadeloupe' },
  { value: 'GQ_Equatorial Guinea' },
  { value: 'GR_Greece' },
  { value: 'GS_South Georgia and the South Sandwich Islands' },
  { value: 'GT_Guatemala' },
  { value: 'GU_Guam' },
  { value: 'GW_Guinea-Bissau' },
  { value: 'GY_Guyana' },
  { value: 'HK_Hong Kong' },
  { value: 'HM_Heard Island and McDonald Islands' },
  { value: 'HN_Honduras' },
  { value: 'HR_Croatia' },
  { value: 'HT_Haiti' },
  { value: 'HU_Hungary' },
  { value: 'ID_Indonesia' },
  { value: 'IE_Ireland' },
  { value: 'IL_Israel' },
  { value: 'IM_Isle of Man' },
  { value: 'IN_India' },
  { value: 'IO_British Indian Ocean Territory' },
  { value: 'IQ_Iraq' },
  { value: 'IR_Iran, Islamic Republic of Iran' },
  { value: 'IS_Iceland' },
  { value: 'IT_Italy' },
  { value: 'JE_Jersey' },
  { value: 'JM_Jamaica' },
  { value: 'JO_Jordan' },
  { value: 'JP_Japan' },
  { value: 'KE_Kenya' },
  { value: 'KG_Kyrgyzstan' },
  { value: 'KH_Cambodia' },
  { value: 'KI_Kiribati' },
  { value: 'KM_Comoros' },
  { value: 'KN_Saint Kitts and Nevis' },
  { value: "KP_Korea, Democratic People's Republic of Korea" },
  { value: 'KR_Korea, Republic of Korea' },
  { value: 'KW_Kuwait' },
  { value: 'KY_Cayman Islands' },
  { value: 'KZ_Kazakhstan' },
  { value: "LA_Lao People's Democratic Republic" },
  { value: 'LB_Lebanon' },
  { value: 'LC_Saint Lucia' },
  { value: 'LI_Liechtenstein' },
  { value: 'LK_Sri Lanka' },
  { value: 'LR_Liberia' },
  { value: 'LS_Lesotho' },
  { value: 'LT_Lithuania' },
  { value: 'LU_Luxembourg' },
  { value: 'LV_Latvia' },
  { value: 'LY_Libya' },
  { value: 'MA_Morocco' },
  { value: 'MC_Monaco' },
  { value: 'MD_Moldova, Republic of Moldova' },
  { value: 'ME_Montenegro' },
  { value: 'MF_Saint Martin (French part)' },
  { value: 'MG_Madagascar' },
  { value: 'MH_Marshall Islands' },
  { value: 'MK_Macedonia, the Former Yugoslav Republic of Macedonia' },
  { value: 'ML_Mali' },
  { value: 'MM_Myanmar' },
  { value: 'MN_Mongolia' },
  { value: 'MO_Macao' },
  { value: 'MP_Northern Mariana Islands' },
  { value: 'MQ_Martinique' },
  { value: 'MR_Mauritania' },
  { value: 'MS_Montserrat' },
  { value: 'MT_Malta' },
  { value: 'MU_Mauritius' },
  { value: 'MV_Maldives' },
  { value: 'MW_Malawi' },
  { value: 'MX_Mexico' },
  { value: 'MY_Malaysia' },
  { value: 'MZ_Mozambique' },
  { value: 'NA_Namibia' },
  { value: 'NC_New Caledonia' },
  { value: 'NE_Niger' },
  { value: 'NF_Norfolk Island' },
  { value: 'NG_Nigeria' },
  { value: 'NI_Nicaragua' },
  { value: 'NL_Netherlands' },
  { value: 'NO_Norway' },
  { value: 'NP_Nepal' },
  { value: 'NR_Nauru' },
  { value: 'NU_Niue' },
  { value: 'NZ_New Zealand' },
  { value: 'OM_Oman' },
  { value: 'PA_Panama' },
  { value: 'PE_Peru' },
  { value: 'PF_French Polynesia' },
  { value: 'PG_Papua New Guinea' },
  { value: 'PH_Philippines' },
  { value: 'PK_Pakistan' },
  { value: 'PL_Poland' },
  { value: 'PM_Saint Pierre and Miquelon' },
  { value: 'PN_Pitcairn' },
  { value: 'PR_Puerto Rico' },
  { value: 'PS_Palestine, State of Palestine' },
  { value: 'PT_Portugal' },
  { value: 'PW_Palau' },
  { value: 'PY_Paraguay' },
  { value: 'QA_Qatar' },
  { value: 'RE_Reunion' },
  { value: 'RO_Romania' },
  { value: 'RS_Serbia' },
  { value: 'RU_Russian Federation' },
  { value: 'RW_Rwanda' },
  { value: 'SA_Saudi Arabia' },
  { value: 'SB_Solomon Islands' },
  { value: 'SC_Seychelles' },
  { value: 'SD_Sudan' },
  { value: 'SE_Sweden' },
  { value: 'SG_Singapore' },
  { value: 'SH_Saint Helena' },
  { value: 'SI_Slovenia' },
  { value: 'SJ_Svalbard and Jan Mayen' },
  { value: 'SK_Slovakia' },
  { value: 'SL_Sierra Leone' },
  { value: 'SM_San Marino' },
  { value: 'SN_Senegal' },
  { value: 'SO_Somalia' },
  { value: 'SR_Suriname' },
  { value: 'SS_South Sudan' },
  { value: 'ST_Sao Tome and Principe' },
  { value: 'SV_El Salvador' },
  { value: 'SX_Sint Maarten (Dutch part)' },
  { value: 'SY_Syrian Arab Republic' },
  { value: 'SZ_Swaziland' },
  { value: 'TC_Turks and Caicos Islands' },
  { value: 'TD_Chad' },
  { value: 'TF_French Southern Territories' },
  { value: 'TG_Togo' },
  { value: 'TH_Thailand' },
  { value: 'TJ_Tajikistan' },
  { value: 'TK_Tokelau' },
  { value: 'TL_Timor-Leste' },
  { value: 'TM_Turkmenistan' },
  { value: 'TN_Tunisia' },
  { value: 'TO_Tonga' },
  { value: 'TR_Turkey' },
  { value: 'TT_Trinidad and Tobago' },
  { value: 'TV_Tuvalu' },
  { value: 'TW_Taiwan, Province of China' },
  { value: 'TZ_United Republic of Tanzania' },
  { value: 'UA_Ukraine' },
  { value: 'UG_Uganda' },
  { value: 'US_United States' },
  { value: 'UY_Uruguay' },
  { value: 'UZ_Uzbekistan' },
  { value: 'VA_Holy See (Vatican City State)' },
  { value: 'VC_Saint Vincent and the Grenadines' },
  { value: 'VE_Venezuela' },
  { value: 'VG_British Virgin Islands' },
  { value: 'VI_US Virgin Islands' },
  { value: 'VN_Vietnam' },
  { value: 'VU_Vanuatu' },
  { value: 'WF_Wallis and Futuna' },
  { value: 'WS_Samoa' },
  { value: 'XK_Kosovo' },
  { value: 'YE_Yemen' },
  { value: 'YT_Mayotte' },
  { value: 'ZA_South Africa' },
  { value: 'ZM_Zambia' },
  { value: 'ZW_Zimbabwe' },
]

export const professional_qualities = [
  { value: 'Speech and Language Therapist' },
  { value: 'Psychologist' },
  { value: 'Behavior Analyst' },
  { value: 'Physical Therapist' },
  { value: 'Learning Specialist' },
  { value: 'Psychiatrist' },
  { value: 'Occupational Therapist' },
  { value: 'Add custom' },
]

export const education = [
  { id: 1, title: 'bl', label: 'Bachelors Level' },
  { id: 2, title: 'ml', label: 'Masters Level' },
  { id: 3, title: 'dl', label: 'Doctoral Level' },
]

export const education_level = [{ value: 'Bachelors Level' }, { value: 'Masters Level' }, { value: 'Doctoral Level' }]

export const licence = [
  { value: 'American Psychological Association' },
  { value: 'American Board of School Neuropsychology' },
  { value: 'National Association of School Psychologists 3' },
  { value: 'National Board for Certification in Occupational Therapy' },
  { value: 'American Speech and Hearing Association' },
  { value: 'American Occupational Therapy Association' },
  { value: 'American Board of Pediatrics' },
  { value: 'Health and Care Professions Council (HCPC)' },
  { value: 'Behavior Analyst Certification Board' },
  { value: 'Speech-Language & Audiology Canada (SAC)' },
  { value: 'International School Psychology Association (ISPA)' },
  { value: 'Add custom' },
]

export const experience = [{ value: '>3' }, { value: '3-5' }, { value: '5-10' }, { value: '10+' }]

export const age = [{ value: '50-60' }, { value: '40-50' }, { value: '30-40' }, { value: '20-30' }]

export const languages = [
  { value: 'Arabic', ar: 'العربية' },
  { value: 'English', ar: 'الانجليزية' },
  { value: 'French', ar: 'الفرنسية' },
  { value: 'Spanish', ar: 'الاسبانية' },
  { value: 'Hindi', ar: 'الهندية' },
  { value: 'German', ar: 'الالمانية' },
  { value: 'Bengali', ar: 'البنغالية' },
  { value: 'Tagalog', ar: 'تاجالوج' },
  { value: 'Urdu', ar: 'الاوردو' },
  { value: 'Farsi', ar: 'الفارسية' },
  { value: 'Other', ar: 'أخرى' },
]

export const expertise = [
  { value: 'Academic Support' },
  { value: 'Applied Behavior Analysis' },
  { value: 'Developmental Pediatrics and Health Screenings' },
  { value: 'Diagnostics' },
  { value: 'Early Intervention' },
  { value: 'Educational or School Psychology' },
  { value: 'Occupational Therapy' },
  { value: 'Physical Therapy' },
  { value: 'Psychiatry' },
  { value: 'Speech and Language Therapy' },
  { value: 'Mental Health Counselling' },
  { value: 'Add custom' },
]

export const services = [
  { id: 1, title: 'ft', label: 'Yes, full time' },
  { id: 2, title: 'pt', label: 'Yes, part time' },
  { id: 3, title: 'na', label: 'No, I am not active clinically' },
]

export const services_selector = [{ value: 'Yes, full time' }, { value: 'Yes, part time' }, { value: 'No, I am not active clinically' }]

export const provide_clinical = [
  { value: 'College or University' },
  { value: 'Clients Home' },
  { value: 'Hospital/Health Services (private)' },
  { value: 'Hospital/Health Services (public)' },
  { value: 'Preschool' },
  { value: 'Elementary School (public)' },
  { value: 'Elementary School (private)' },
  { value: 'Secondary School (public)' },
  { value: 'Special Day/ Residential School' },
  { value: 'Clinic Chain or Franchise' },
  { value: 'Rehabilitative Center (Public)' },
  { value: 'Rehabilitative Center (Private)' },
  { value: 'Add custom' },
]

export const age_groups = [{ value: '<6 months' }, { value: '7 months - 2 years' }, { value: '3-5 years' }, { value: '6-11 years' }, { value: '12-15 years' }]

export const j_advantage = [
  {
    value: 'Connecting you to clients (schools and families) that are not currently in your network',
  },
  { value: "Taking Care of Scheduling and Billing so you don't have to" },
  {
    value: 'Having a secure, easy to use therapy platform that is more than simple video conferencing',
  },
  { value: 'Filling up empty slots in your schedule' },
  {
    value: 'Taking clients at times that are more convenient for you- (advantage of different time zones)',
  },
  { value: 'Add custom' },
]

export const timezones = [
  { value: 'UTC +12' },
  { value: 'UTC +11' },
  { value: 'UTC +10' },
  { value: 'UTC +9' },
  { value: 'UTC +8' },
  { value: 'UTC +7' },
  { value: 'UTC +6' },
  { value: 'UTC +5' },
  { value: 'UTC +4' },
  { value: 'UTC +3' },
  { value: 'UTC +2' },
  { value: 'UTC +1' },
  { value: 'UTC 0' },
  { value: 'UTC -1' },
  { value: 'UTC -2' },
  { value: 'UTC -3' },
  { value: 'UTC -4' },
  { value: 'UTC -5' },
  { value: 'UTC -6' },
  { value: 'UTC -7' },
  { value: 'UTC -8' },
  { value: 'UTC -9' },
  { value: 'UTC -10' },
  { value: 'UTC -11' },
]

export const childSubscription = [{ value: 'Guided' }, { value: 'Self-guided' }]

export const eventType = [{ value: 'Therapy call' }, { value: 'Initial call' }]

export const dayName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const availableFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/png', 'image/jpeg']

export const onboardingQuestions = [
  {
    id: 1,
    question: `Do you have any concerns about your child's health or about how he or she sees, hears, eats, or sleeps?`,
  },
  {
    id: 2,
    question: `Do you have any concerns about how your child is behind others or can't do what other kids can?`,
  },
  {
    id: 3,
    question: 'Do you have any concerns about how your child gets along with others?',
  },
  {
    id: 4,
    question: 'Do you have any concerns about how your child behaves?',
  },
  {
    id: 5,
    question: 'Do you have any concerns about how your child uses his or her arms and legs?',
  },
  {
    id: 6,
    question: 'Do you have any concerns about how your child understands what you say?',
  },
  {
    id: 7,
    question: 'Do you have any concerns about how your child talks and makes speech sounds?',
  },
  {
    id: 8,
    question: 'Please list any other concerns',
  },
]

export const childOnboardingQuestions = [
  {
    id: 1,
    question: `Do you have any concerns about your child's health or about how he or she sees, hears, eats, or sleeps?`,
  },
  {
    id: 2,
    question: `Do you have any concerns about how your child is behind others or can't do what other kids can?`,
  },
  {
    id: 3,
    question: 'Do you have any concerns about how your child gets along with others?',
  },
  {
    id: 4,
    question: 'Do you have any concerns about how your child behaves?',
  },
  {
    id: 5,
    question: 'Do you have any concerns about how your child uses his or her arms and legs?',
  },
  {
    id: 6,
    question: 'Do you have any concerns about how your child understands what you say?',
  },
  {
    id: 7,
    question: 'Do you have any concerns about how your child talks and makes speech sounds?',
  },
  {
    id: 8,
    question: 'Please list any other concerns',
  },
]

export const adultOnboardingQuestions = [
  {
    id: 0,
    main_statement: `On a scale of 1 to 10, how would you rate your current level of parental stress or anxiety?`,
  },
  {
    id: 1,
    main_statement: `Are there specific situations or behaviors in your child that contribute significantly to your stress?`,
  },
  {
    id: 2,
    main_statement: 'Have you noticed any changes in your own behavior or mental well-being since becoming a parent?',
  },
  {
    id: 3,
    main_statement: 'How would you describe your communication with your child? Are there challenges you face during interactions?',
  },
  {
    id: 4,
    main_statement: 'Do you feel equipped with effective parenting strategies to address your child’s needs and challenges?',
  },
  {
    id: 5,
    main_statement: 'Are there particular moments or situations that trigger stress or frustration for you as a parent?',
  },
  {
    id: 6,
    main_statement: 'How well do you think you manage your own self-care and well-being?',
  },
  {
    id: 7,
    main_statement: `Are there specific parenting techniques or strategies you've tried that have been particularly effective or ineffective for your family?`,
  },
  {
    id: 8,
    main_statement: `Do you feel comfortable seeking and receiving support from friends, family, or your community?`,
  },
  {
    id: 9,
    main_statement: `Have you noticed any impact on your relationships or daily life due to the stress associated with parenting?`,
  },
  {
    id: 10,
    main_statement: `Are there any specific goals or improvements you would like to see in your parenting approach or overall well-being?`,
  },
]

export const hintForPersonalDocuments = [
  { id: 1, value: 'Official identification (passport, ID)' },
  { id: 2, value: 'High definition photograph' },
  { id: 3, value: 'Good conduct or police clearance form' },
]

export const hintForProfessionalDocuments = [
  { id: 1, value: 'Professional board certifications' },
  { id: 2, value: 'Country specific licenses' },
  { id: 3, value: 'Curriculum vitae' },
]

export const hintForEducationalDocuments = [{ id: 1, value: 'Copies of diplomas and/or transcripts.' }]

export const uniquePrice = [
  { id: 1, value: 'Select an option' },
  { id: 2, value: 'Discount percentage' },
  { id: 3, value: 'Fixed discount amount' },
]
export const dataForParnetHintModalEnglish = [
  { id: 1, label: 'Complete Our Questionnaire', description: `Provide detailed information about your child's needs.` },
  { id: 2, label: 'Book Your Free Consultation', description: `Schedule a consultation with one of our advisors.` },
  { id: 3, label: 'Meet with an Advisor', description: `Have a detailed discussion with our advisor to assess your requirements and explore suitable options.` },
  {
    id: 4,
    label: 'Receive Specialist Matches',
    description: `Get matched with a specialist based on the screening and consultation, and arrange a discovery call to learn more about them.`,
  },
  { id: 5, label: 'Connect With Your Matched Specialist', description: `Note: If they are not the right fit, you can receive additional matches.` },
]

export const dataForParnetHintModalArabic = [
  { id: 1, label: 'استكمال الاستبيان', description: 'قدم معلومات تشمل الاحتياجات الخاصة لطفلك' },
  { id: 2, label: 'حجز موعد للاستشارة المجانية', description: 'حدد موعد للمقابلة المستشار للحصول على الاستشارة' },
  { id: 3, label: 'مقابلة المستشار', description: 'ناقش احتياجتك بالتفصيل للوصول إلى الخيارات المناسبة' },
  { id: 4, label: 'الحصول على خيارات المختصين', description: 'سيتم اختيار مختصين بناء على الاستبيان و التواصل معهم لتزويدك بالمعلومات' },
  { id: 5, label: 'اختيار المختص المناسب لبدء الرحلة العلاجية', description: 'ملاحظة: في حال عدم اختيار المختص المناسب- يمكنك الحصول على خيارات أخرى' },
]

export const SESSION_NOTE_STATUS = {
  0: 'An issue with saving',
  1: 'Saving...',
  2: 'Saved on',
}

export const parent_onboard_child_concerns = [
  { value: 'Emotions (e.g. anxiety and depression)', en: 'Emotions (e.g. anxiety and depression)', ar: 'العواطف (مثل القلق والاكتئاب)' },
  { value: 'Behavior (e.g. tantrum and defiance)', en: 'Behavior (e.g. tantrum and defiance)', ar: 'السلوك (مثل نوبات الغضب والتحدي)' },
  { value: 'Focus and attention', en: 'Focus and attention', ar: 'التركيز والانتباه' },
  { value: 'Social skills', en: 'Social skills', ar: 'المهارات الاجتماعية' },
  { value: 'Motor skills', en: 'Motor skills', ar: 'المهارات الحركية' },
  { value: 'Cognitive and learning', en: 'Cognitive and learning', ar: 'المهارات المعرفية والتعلم' },
  { value: 'Speech and language', en: 'Speech and language', ar: 'النطق واللغة' },
  { value: 'Eating or sleep-related issues', en: 'Eating or sleep-related issues', ar: 'مشاكل الأكل أو النوم' },
  { value: 'Other', en: 'Other', ar: 'أخرى' },
]

export const parent_onboard_child_behavior = [
  { value: 'Is relaxed and easygoing', en: 'Is relaxed and easygoing', ar: 'مرتاح ويسهل التعامل معه' },
  { value: 'Gets irritable frequently', en: 'Gets irritable frequently', ar: 'يتعرض للانزعاج بشكل متكرر' },
  { value: 'Is hard to calm down', en: 'Is hard to calm down', ar: 'يصعب تهدئته' },
  { value: 'Gets startled easily', en: 'Gets startled easily', ar: 'يخاف بسهولة' },
  { value: 'Engages well with others', en: 'Engages well with others', ar: 'يتفاعل جيداً مع الآخرين' },
  { value: 'Adapts easily to new situations', en: 'Adapts easily to new situations', ar: 'يتكيف بسهولة مع المواقف الجديدة' },
  { value: 'Is generally cheerful and positive', en: 'Is generally cheerful and positive', ar: 'مرح وإيجابي بشكل عام' },
  { value: 'Prefers to play alone', en: 'Prefers to play alone', ar: 'يفضل اللعب بمفرده' },
  { value: 'Enjoys being in social settings', en: 'Enjoys being in social settings', ar: 'يستمتع بالتواجد في البيئات الاجتماعية' },
  { value: 'Has a short temper', en: 'Has a short temper', ar: 'لديه مزاج حاد' },
  { value: 'Shows empathy towards others', en: 'Shows empathy towards others', ar: 'يبدي تعاطفاً تجاه الآخرين' },
  { value: 'Follows routines well', en: 'Follows routines well', ar: 'يتبع الروتين بشكل جيد' },
  { value: 'Struggles with changes in routine', en: 'Struggles with changes in routine', ar: 'يواجه صعوبة في التكيف مع التغييرات في الروتين' },
  { value: 'Is highly energetic', en: 'Is highly energetic', ar: 'نشيط جداً' },
  { value: 'Has a quiet demeanor', en: 'Has a quiet demeanor', ar: 'هادئ' },
  {
    value: 'Displays a balanced temperament (neither too energetic nor too quiet)',
    en: 'Displays a balanced temperament (neither too energetic nor too quiet)',
    ar: 'يظهر توازناً في المزاج (غير نشط جداً و غير هادئ جداً)',
  },
]

export const mandatory_questions_parent_onboarding = [
  {
    id: 0,
    category: 'Emotional Symptoms',
    main_statement: 'My child often seems sad, anxious, or complains of physical pains',
    sub_statements: [
      { id: 0, statement: 'Worries without clear reasons' },
      { id: 1, statement: 'Often looks sad or tearful' },
      { id: 2, statement: 'Complains of headaches or stomach pains without clear cause' },
      { id: 3, statement: 'Feels hopeless' },
      { id: 4, statement: 'Loses interest in previously enjoyed activities' },
      { id: 5, statement: 'Exhibits sudden and intense irritability or anger that is difficult to manage' },
      { id: 6, statement: 'Shows increased clinginess or distress when separated from their caregivers' },
    ],
  },
  {
    id: 1,
    category: 'Conduct Problems',
    main_statement: 'My child struggles with following rules and can be challenging',
    sub_statements: [
      { id: 0, statement: 'Resists following rules or instructions, manifested as arguing with adults or throwing temper tantrums' },
      { id: 1, statement: 'Shows anger or resentment with changes in routine or during transitions' },
      { id: 2, statement: 'Blames others for their mistakes' },
      { id: 3, statement: 'Has been noticed lying or stealing' },
      { id: 4, statement: 'Displays aggression (verbal or physical)' },
      { id: 5, statement: 'Acts defiantly, challenging authority figures' },
    ],
  },
  {
    id: 2,
    category: 'Hyperactivity/Inattention',
    main_statement: 'My child is very active and/or has trouble focusing',
    sub_statements: [
      { id: 0, statement: `Constantly fidgets or can't stay seated` },
      { id: 1, statement: 'Has difficulty waiting for their turn in games or group activities' },
      { id: 2, statement: `Interrupts or can't wait to speak in conversations` },
      { id: 3, statement: 'Is constantly in motion' },
      { id: 4, statement: 'Quickly loses focus on tasks and moves from one activity to another' },
      { id: 5, statement: 'Makes careless mistakes in schoolwork or other activities' },
      { id: 6, statement: 'Is easily distracted' },
      { id: 7, statement: 'Avoids or dislikes tasks that need sustained effort' },
      { id: 8, statement: 'Loses things' },
    ],
  },
  {
    id: 3,
    category: 'Combined Peer and Social Interactions',
    main_statement: 'My child has difficulties in social situations, often feels left out, or prefers to be alone',
    sub_statements: [
      { id: 0, statement: `Has trouble making and keeping friends` },
      { id: 1, statement: 'Seems to be left out or gets teased by other kids' },
      { id: 2, statement: `Struggles to understand social cues` },
      { id: 3, statement: 'May dominate conversations or not participate in dialogue' },
      { id: 4, statement: 'Does not start conversations or play activities' },
      { id: 5, statement: 'Shows limited interest in engaging with other children' },
      { id: 6, statement: 'Finds it challenging to follow social rules, like taking turns' },
      { id: 7, statement: `Seems indifferent to others' feelings` },
      { id: 8, statement: 'Does not notice when others are hurt or upset' },
    ],
  },
  {
    id: 4,
    category: 'Motor Skills Development',
    main_statement: 'My child has trouble with movement skills',
    sub_statements: [
      { id: 0, statement: `Has trouble with small movements, like cutting with scissors or drawing` },
      { id: 1, statement: 'Has trouble with large movements, like running, jumping, or balancing' },
    ],
  },
  {
    id: 5,
    category: 'Expanded Cognitive Development',
    main_statement: 'My child has trouble with various thinking or learning tasks',
    sub_statements: [
      { id: 0, statement: `Struggles with reasoning or solving problems` },
      { id: 1, statement: 'Finds abstract concepts difficult' },
      { id: 2, statement: `Has difficulty focusing attention, organizing tasks, or completing schoolwork` },
      { id: 3, statement: 'Forgets instructions or details quickly' },
      { id: 4, statement: 'Finds it challenging to solve problems independently, needing more assistance than peers' },
      { id: 5, statement: 'Has difficulty understanding and following multi-step instructions, often getting confused or needing repeated guidance' },
    ],
  },
  {
    id: 6,
    category: 'Speech and Language Development',
    main_statement: 'My child has trouble with talking or understanding language',
    sub_statements: [
      { id: 0, statement: `Struggles with forming sentences or using grammar` },
      { id: 1, statement: 'Mispronounces words or uses them incorrectly' },
      {
        id: 2,
        statement: `Difficulty following directions or understanding questions`,
      },
      { id: 3, statement: 'Limited vocabulary affects ability to express thoughts' },
      { id: 4, statement: 'Finds it hard to engage in or follow conversations' },
      { id: 5, statement: 'Trouble with speech fluency, such as stuttering' },
      { id: 6, statement: 'Difficulty with social aspects of communication, like using non-verbal cues (i.e. gestures, facial expressions, etc.)' },
    ],
  },
  {
    id: 7,
    category: 'Eating-related Issues',
    main_statement: 'My child has trouble with eating, mealtime behavior, or food preferences',
    sub_statements: [
      { id: 0, statement: `Has strong food aversions or a very limited diet, refusing certain textures or types of food` },
      { id: 1, statement: 'Has difficulty chewing or swallowing certain textures or foods' },
      {
        id: 2,
        statement: `Displays disruptive behaviors during meals, such as tantrums or refusal to sit`,
      },
      { id: 3, statement: 'Eats at a significantly faster or slower pace than other children' },
      { id: 4, statement: 'Demonstrates signs of being overly picky, limiting their diet variety' },
      { id: 5, statement: 'Shows inconsistent appetite, alternating between poor eating and overeating' },
    ],
  },
  {
    id: 8,
    category: 'Sleep-Related Issues',
    main_statement: 'My child struggles with sleep or has irregular sleep patterns',
    sub_statements: [
      { id: 0, statement: `Takes a long time to fall asleep` },
      { id: 1, statement: 'Wakes up frequently during the night' },
      {
        id: 2,
        statement: `Has difficulty waking up in the morning and feels sleepy throughout the day`,
      },
      { id: 3, statement: 'Resists bedtime, showing reluctance or refusing to go to bed' },
      { id: 4, statement: 'Experiences night terrors or nightmares, showing signs of fear or distress upon waking' },
      { id: 5, statement: 'Sleeps too little or too much compared to other children' },
      { id: 6, statement: 'Displays unusual sleep behaviors like sleepwalking or talking during sleep' },
      { id: 7, statement: 'Has inconsistent sleep schedules, leading to daytime fatigue' },
    ],
  },
]

export const parent_onboarding_family_dynamics = [
  {
    id: 0,
    question: 'Marital Status',
    options: [
      { id: 0, value: 'Married' },
      { id: 1, value: 'Separated/Divorced' },
      { id: 2, value: 'Other' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 1,
    question: 'How often do you and your partner get along well?',
    options: [
      { id: 0, value: 'Often' },
      { id: 1, value: 'Sometimes' },
      { id: 2, value: 'Rarely' },
      { id: 3, value: 'Not applicable' },
      { id: 4, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 2,
    question: 'How often do you and your partner discuss parenting decisions?',
    options: [
      { id: 0, value: 'Often' },
      { id: 1, value: 'Sometimes' },
      { id: 2, value: 'Rarely' },
      { id: 3, value: 'Not applicable' },
      { id: 4, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 3,
    question: `How often do you and your child's other parent collaborate on parenting decisions?`,
    options: [
      { id: 0, value: 'Often' },
      { id: 1, value: 'Sometimes' },
      { id: 2, value: 'Rarely' },
      { id: 3, value: 'Not applicable' },
      { id: 4, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 4,
    question: `How much do your child's needs or behaviors affect your daily family life?`,
    options: [
      { id: 0, value: 'A lot' },
      { id: 1, value: 'A little' },
      { id: 2, value: 'Not at all' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 5,
    question: `How much do your child's needs or behaviors limit your family's participation in everyday activities?`,
    options: [
      { id: 0, value: 'A lot' },
      { id: 1, value: 'A little' },
      { id: 2, value: 'Not at all' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
]

export const parent_onboarding_parental_wellbeing = [
  {
    id: 0,
    question: 'Feeling stressed or overwhelmed',
    header: 'Over the past two weeks, how often have you experienced the following?',
    options: [
      { id: 0, value: 'Often' },
      { id: 1, value: 'Sometimes' },
      { id: 2, value: 'Not at all' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 1,
    question: 'Feeling anxious or worried',
    header: 'Over the past two weeks, how often have you experienced the following?',
    options: [
      { id: 0, value: 'Often' },
      { id: 1, value: 'Sometimes' },
      { id: 2, value: 'Not at all' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 2,
    question: 'Low mood or sad',
    header: 'Over the past two weeks, how often have you experienced the following?',
    options: [
      { id: 0, value: 'Often' },
      { id: 1, value: 'Sometimes' },
      { id: 2, value: 'Not at all' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 3,
    question: `How much do your child's needs or behaviors impact your mental wellbeing?`,
    header: false,
    options: [
      { id: 0, value: 'Not at all' },
      { id: 1, value: 'A little' },
      { id: 2, value: 'A lot' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
  {
    id: 4,
    question: `How much do your child's needs or behaviors impact your relationship with your partner?`,
    header: false,
    options: [
      { id: 0, value: 'Not at all' },
      { id: 1, value: 'A little' },
      { id: 2, value: 'A lot' },
      { id: 3, value: 'Prefer not to answer' },
    ],
  },
]

export const parent_onboarding_report_questionnaire = (childName) => {
  return {
    'Emotional Symptoms': {
      id: 0,
      section_name: {
        en: 'Emotional',
        ar: 'الحالة العاطفية',
      },
      feedback: {
        en: `Based on your responses, it appears that ${childName ?? 'Your Child'} has been experiencing feelings of anxiety and/or sadness. With the right support, ${
          childName ?? 'Your Child'
        } can learn effective coping strategies to manage these emotions, leading to a more positive and balanced emotional state.`,
        ar: `بناءً على إجاباتك، يبدو أن طفلك يعاني من مشاعر القلق و/أو الحزن. مع الدعم المناسب، يمكن لـ طفلك تعلم استراتيجيات التأقلم الفعالة للتعامل مع هذه المشاعر، مما يؤدي إلى حالة عاطفية أكثر إيجابية وتوازناً`,
      },
    },
    'Conduct Problems': {
      id: 1,
      section_name: {
        en: 'Behavioral',
        ar: 'السلوكية',
      },
      feedback: {
        en: `${childName ?? 'Your Child'}'s difficulty in following rules and/or displaying aggressive behavior can be challenging for both you and them. Support can help ${
          childName ?? 'Your Child'
        } understand and manage their emotions better, leading to more positive interactions and improved adherence to rules.`,
        ar: ` صعوبة طفلك في اتباع القواعد و/أو إظهار السلوك العدواني يمكن أن يكون تحدياً لك وله. يمكن أن يساعد الدعم طفلك على فهم وإدارة مشاعره بشكل أفضل، مما يؤدي إلى تفاعلات أكثر إيجابية وتحسن في الامتثال للقواعد.`,
      },
    },
    'Hyperactivity/Inattention': {
      id: 2,
      section_name: {
        en: 'Hyperactivity/Inattention',
        ar: `فرط النشاط/نقص الانتباه`,
      },
      feedback: {
        en: `It seems that ${
          childName ?? 'Your Child'
        } exhibits signs of hyperactivity and/or inattention, which can affect their learning and daily activities. Support can help ${
          childName ?? 'Your Child'
        } channel their energy productively and improve their ability to stay on task, enhancing their learning and daily activities.`,
        ar: ` يبدو أن طفلك يظهر علامات فرط النشاط و/أو نقص الانتباه، مما يمكن أن يؤثر على تعلمه وأنشطته اليومية. يمكن أن يساعد الدعم طفلك على توجيه طاقته بشكل منتج وتحسين قدرته على البقاء مركزًا، مما يعزز تعلمه وأنشطته اليومية.`,
      },
    },
    'Combined Peer and Social Interactions': {
      id: 3,
      section_name: { en: 'Social', ar: 'الحالة الإجتماعية' },
      feedback: {
        en: `Social interactions are crucial for ${
          childName ?? 'Your Child'
        }'s development. Support can help your child develop social skills, understand social cues, and build meaningful relationships, improving their peer interactions and overall social experience.`,
        ar: `التفاعلات الاجتماعية مهمة لتطور طفلك. يمكن أن يساعد الدعم طفلك على تطوير المهارات الاجتماعية، وفهم الإشارات الاجتماعية، وبناء علاقات ذات مغزى، مما يحسن تفاعلاته مع الأقران وتجربته الاجتماعية العامة`,
      },
    },
    'Motor Skills Development': {
      id: 4,
      section_name: { en: 'Motor Development', ar: 'التطور الحركي' },
      feedback: {
        en: `We noted concerns about ${
          childName ?? 'Your Child'
        }'s motor development, such as challenges with fine and/or gross motor skills. Support in this area can improve their coordination and physical abilities, making tasks such as playing, dressing, and writing easier and more enjoyable.`,
        ar: `لاحظنا وجود مخاوف بشأن تطور المهارات الحركية لـ طفلك، مثل التحديات في المهارات الحركية الدقيقة و/أو الكبرى. يمكن أن يؤدي الدعم في هذا المجال إلى تحسين تنسيقه وقدراته البدنية، مما يجعل المهام مثل اللعب، وارتداء الملابس، والكتابة أسهل وأكثر متعة.`,
      },
    },
    'Expanded Cognitive Development': {
      id: 6,
      section_name: { en: 'Cognitive Development', ar: 'التطور الفكري' },
      feedback: {
        en: `${
          childName ?? 'Your Child'
        } may face challenges with learning and/or memory. Support can help your child improve these skills, fostering better problem-solving abilities and  helping them achieve their full potential in school and beyond.`,
        ar: `قد يواجه طفلك تحديات في التعلم و/أو الذاكرة. يمكن أن يساعد الدعم طفلك على تحسين هذه المهارات، مما يعزز قدرته على حل المشكلات ومساعدته في تحقيق إمكاناته الكاملة في المدرسة وخارجها`,
      },
    },
    'Speech and Language Development': {
      id: 5,
      section_name: { en: 'Speech Development', ar: 'تطور النطق' },
      feedback: {
        en: `We understand that ${childName ?? 'Your Child'} may have difficulties with speech and/or language skills. Speech therapy can assist ${
          childName ?? 'Your Child'
        } in developing clearer communication, enhancing their ability to express themselves and understand others.`,
        ar: `نفهم أن طفلك قد يواجه صعوبات في مهارات النطق و/أو اللغة. يمكن أن تساعد جلسات علاج النطق طفلك في تطوير التواصل بشكل أوضح، مما يعزز قدرته على التعبير عن نفسه وفهم الآخرين.`,
      },
    },
    'Eating-related Issues': {
      id: 7,
      section_name: { en: 'Eating Habits', ar: 'عادات الطعام' },
      feedback: {
        en: `Eating habits and mealtime behaviors are crucial for ${
          childName ?? 'Your Child'
        }'s health and well-being. Addressing issues in this area can promote healthier eating behaviors and reduce related stress.`,
        ar: `عادات الأكل وسلوكيات وقت الطعام مهمة لصحة طفلك ورفاهيته. يمكن أن يؤدي معالجة القضايا في هذا المجال إلى تعزيز عادات الأكل الصحية وتقليل التوتر المرتبط بها`,
      },
    },
    'Sleep-Related Issues': {
      id: 8,
      section_name: { en: 'Sleep Habits', ar: 'عادات النوم' },
      feedback: {
        en: `Sleep is vital for ${
          childName ?? 'Your Child'
        }'s development. Struggles with sleep can affect their mood, energy levels, and overall health. Support in establishing healthy sleep patterns can lead to better rest and improved daily functioning.`,
        ar: `النوم ضروري لتطور طفلك. يمكن أن تؤثر صعوبات النوم على مزاجه ومستويات طاقته وصحته العامة. يمكن أن يؤدي الدعم في تأسيس أنماط نوم صحية إلى راحة أفضل وتحسين الأداء اليومي`,
      },
    },
  }
}

export const parent_onboarding_report_family_dynamics = (childName, data) => {
  const relatedQuestions = [
    "How much do your child's needs or behaviors affect your daily family life?",
    "How much do your child's needs or behaviors limit your family's participation in everyday activities?",
    'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على مشاركتك العائلية في الأنشطة اليومية؟',
    'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على حياتك العائلية اليومية؟',
  ]

  const relatedAnswers = ['A lot', 'كثيراً']

  for (const item of data ?? []) {
    if (relatedQuestions?.includes(item?.question) && relatedAnswers?.includes(item?.answer)) {
      return {
        section_name: {
          en: 'Family Dynamics',
          ar: 'ديناميكيات الأسرة',
        },
        feedback: {
          en: `Your responses indicate that ${
            childName ?? 'Your Child'
          }'s needs or behaviors affect your daily family life and/or limit your participation in everyday activities. Finding balance and harmony within the family is important. We can offer practical advice and support to help manage these challenges, making family life smoother and more enjoyable for everyone.`,
          ar: `تشير إجاباتك إلى أن احتياجات طفلك أو سلوكياته تؤثر على حياتك العائلية اليومية و/أو تحد من مشاركتك في الأنشطة اليومية. إيجاد التوازن والوئام داخل الأسرة مهم. يمكننا تقديم نصائح عملية ودعم لمساعدتك في إدارة هذه التحديات، مما يجعل الحياة العائلية أكثر سلاسة ومتعة للجميع`,
        },
      }
    }
  }
}

export const parent_onboarding_report_parental_wellbeing = (childName, data) => {
  const relatedQuestions = [
    'Feeling stressed or overwhelmed',
    'الشعور بالتوتر أو الإرهاق',
    'Feeling anxious or worried',
    'الشعور بالقلق أو التوتر',
    'Low mood or sad',
    'المزاج السيء أو الحزن',
    "How much do your child's needs or behaviors impact your mental wellbeing?",
    'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على رفاهيتك النفسية؟',
    "How much do your child's needs or behaviors impact your relationship with your partner?",
    'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على علاقتك بشريكك؟',
  ]

  const relatedAnswers = ['A lot', 'كثيراً', 'Often', 'غالبًا']

  for (const item of data ?? []) {
    if (relatedQuestions?.includes(item?.question) && relatedAnswers?.includes(item?.answer)) {
      return {
        section_name: {
          en: 'Parental Wellbeing',
          ar: 'رفاهية الوالدين',
        },
        feedback: {
          en: `Parenting can be challenging sometimes, and it's understandable that you might feel stressed, anxious, or sad. Taking care of your own well-being is crucial. It's important to seek support and take time for self-care. Addressing your mental and emotional health ensures you can provide the best support for ${
            childName ?? 'Your Child'
          } while also looking after your own health.`,
          ar: `يمكن أن تكون التربية تحديًا في بعض الأحيان، ومن المفهوم أن تشعر بالتوتر أو القلق أو الحزن. العناية برفاهيتك الخاصة أمر بالغ الأهمية. من المهم البحث عن الدعم وأخذ وقت للرعاية الذاتية. الاهتمام بصحتك العقلية والعاطفية يضمن أنك تستطيع تقديم أفضل دعم لـ طفلك مع العناية بصحتك الخاصة`,
        },
      }
    }
  }
}

export const OPTIONAL_SECTIONS_NAMES = {
  family_dynamics: {
    en: 'family dynamics.',
    ar: 'ديناميكيات الأسرة.',
  },
  parental_wellbeing: {
    en: 'own wellbeing.',
    ar: 'رفاهيتك الخاصة.',
  },
}
