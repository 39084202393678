// Routing
import { Outlet } from 'react-router-dom'
// Styles
import Styles from './styles.module.scss'
// Components
import { StepperModal } from '../GeneralComponents'
import Catcher from '../Catcher'
import { Header } from '../Header'
import { Spinner } from '../ChatSystem/Common/Spinner'
//Core
import { Suspense, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//Actions
import { profileActions } from '../../actions'
import { setHintModalDetails } from '../../reducers/profileSlice'
//Data
import { dataForParnetHintModalEnglish, dataForParnetHintModalArabic } from '../../data'
//Errors
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const Main = () => {
  const dispatch = useDispatch()

  const { isOpenZoomModal } = useSelector((state) => state.zoom)
  const { isSelectedOpenZoomModal } = useSelector((state) => state.zoom)

  const hintModal = useSelector((state) => state.profile.hintModal)
  const { childInfo } = useSelector((state) => state.parent)
  const user = useSelector((state) => state.auth.user)

  const [isInitialized, setIsInitialized] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    if (hintModal?.onboarding_step === '0' && !isInitialized) {
      dispatch(setHintModalDetails({ isOpen: true }))
      setIsInitialized(true)
    }
  }, [hintModal?.onboarding_step, isInitialized])

  const handleCloseHintModal = () => {
    dispatch(setHintModalDetails({ isOpen: false }))
  }

  const returnDataForHintModal = () => {
    const dataForReqest = {
      id: childInfo?.id,
      token: user?.token,
      payload: {},
    }
    const dataForHintModal = {
      isFirstView: hintModal?.isFirstView,
      action: () => {
        dispatch(profileActions.updateHintModalStep(dataForReqest))
      },
    }

    switch (hintModal?.client_onboarding_step) {
      case '0':
        ;(dataForHintModal.data = selectedLanguage === 'en' ? dataForParnetHintModalEnglish : dataForParnetHintModalArabic), (dataForReqest.payload.onboarding_step = '1')
        break
      default:
        break
    }

    return dataForHintModal
  }

  return (
    <div className={Styles.container}>
      <Catcher>
        <Suspense fallback={<Spinner />}>
          <Header />
          <main className={Styles.main}>
            <Outlet />
            <ToastContainer position="top-right" autoClose={5000} pauseOnHover />
          </main>
        </Suspense>
      </Catcher>
    </div>
  )
}
