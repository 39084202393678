//Styles
import Styles from './styles.module.scss'
//Components
import { Table } from '../../Applies/Table'
import { Notifications, NoDataComponent, CustomPagination } from '../../GeneralComponents'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { TherapistInfoModal } from '../Therapists/TherapistsInfoModal'
//Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//Actions
import { advisorOnbordingActions } from '../../../actions'
//Icons
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
//MUI
import { InputBase } from '@mui/material'
//Utils
import { paginateArray } from '../../../utils'
import { Box } from 'components/Core'
import { useAuth } from 'hooks/useAuth'

/**
 * AdvisorParents component displays a list of parents with various statuses and functionalities to search, paginate, and view details.
 *
 * @component
 * @returns {JSX.Element} The AdvisorParents component.
 */
export const AdvisorParents = () => {
  const dispatch = useDispatch()

  // const user = useSelector((state) => state.auth.user)
  const { user: authUser } = useAuth()
  const user = { user: authUser }
  const allParents = useSelector((state) => state.advisorOnbording.allParentsForAssigning)
  const loading = useSelector((state) => state.general.loading)

  /**
   * State to store the list of parents.
   * @type {Array}
   */
  const [parents, setParents] = useState([])

  /**
   * State to store the selected tab.
   * @type {string}
   */
  const [selectedTab, setSelectedTab] = useState('all')

  /**
   * State to store the name of the submitted user.
   * @type {string}
   */
  const [submittedUser, setSubmittedUser] = useState('Emily Konstantantinopolska')

  /**
   * State to trigger refetching of data.
   * @type {boolean}
   */
  const [refetch, setRefetch] = useState(false)

  /**
   * State to display notification.
   * @type {boolean}
   */
  const [displayNotification, setDisplayNotification] = useState(false)

  /**
   * State to manage the visibility of the therapist info modal.
   * @type {boolean}
   */
  const [isOpenTherapistInfo, setIsOpenTherapistInfo] = useState(false)

  /**
   * State to manage the current page for pagination.
   * @type {number}
   */
  const [currentPage, setCurrentPage] = useState(1)

  /**
   * State to store the data for pagination.
   * @type {Array}
   */
  const [dataForPagination, setDataForPagination] = useState([])

  /**
   * State to store the selected therapist ID.
   * @type {string|undefined}
   */
  const [selectedTherapistId, setSelectedTherapistId] = useState(undefined)

  /**
   * Fetch all parents for assigning when the component mounts or refetch/user changes.
   */
  useEffect(() => {
    dispatch(advisorOnbordingActions.getAllParentsForAssignings())
  }, [refetch])

  // useEffect(() => {
  //   const data = {
  //     token: user.token,
  //   }
  //   dispatch(advisorOnbordingActions.getAllParentsForAssignings(data))
  // }, [refetch, user])

  /**
   * Paginate the allParents data and set the parents state accordingly when allParents or currentPage changes.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allParents)
    if (paginatedData.length) {
      setParents([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setParents([])
    }
  }, [allParents])

  /**
   * Filter parents by selected tab and paginate the results when selectedTab or currentPage changes.
   */
  useEffect(() => {
    if (selectedTab === 'all') {
      const paginatedData = paginateArray(allParents)
      setDataForPagination(allParents)
      if (paginatedData.length) {
        setParents([...(paginatedData[currentPage - 1] || 0)])
      } else {
        setParents([])
      }
      return
    }

    const sortedData = allParents.filter((parent) => parent.status === selectedTab)
    const paginatedData = paginateArray(sortedData)
    if (paginatedData.length) {
      setDataForPagination(sortedData)
      setParents([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setDataForPagination(sortedData)
      setParents([])
    }
  }, [selectedTab, currentPage])

  /**
   * Closes the notification.
   */
  const closeNotification = () => {
    setDisplayNotification(false)
  }

  /**
   * Opens the notification.
   *
   * @param {string} userName - The name of the user.
   */
  const openNotification = (userName) => {
    setSubmittedUser(userName)
    setDisplayNotification(true)
  }

  /**
   * Opens the therapist info modal.
   *
   * @param {string} therapistId - The ID of the therapist.
   */
  const handleOpenTherapistInfo = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenTherapistInfo(true)
  }

  /**
   * Closes the therapist info modal.
   */
  const handleCloseTherapistInfo = () => setIsOpenTherapistInfo(false)

  const onClickEvents = {
    openUserDetails: handleOpenTherapistInfo,
  }

  const headers = [
    { text: 'DATE CREATED', sorting: true },
    { text: 'NAME OF PROFILE', sorting: true },
    { text: 'PHONE', sorting: false },
    { text: 'STATUS', sorting: true },
    { text: 'ASSIGNED THERAPISTS', sorting: false },
    { text: 'THERAPIST APPROVALS', sorting: false },
    { text: 'CLIENT CONFIRMATION', sorting: false },
    { text: 'ACTION', sorting: false },
  ]

  /**
   * Selects the tab.
   *
   * @param {string} tabName - The name of the tab.
   */
  const handleSelectTab = (tabName) => {
    setSelectedTab(tabName)
  }

  /**
   * Handles the input change for search.
   *
   * @param {Object} e - The event object.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allParents.filter((parent) => parent.profile_name.toLowerCase().includes(inputValue) || parent.email.toLowerCase().includes(inputValue))
    setParents(filteredArray)
  }

  return (
    <Box px={[3, 3, 20, 20, 40]} py={[24]} className={Styles.main_container}>
      {displayNotification && (
        <Notifications icon={<TickIcon />} title={'Submitted'} secondaryText={'Therapists were submitted to'} userName={`${submittedUser}.`} onClose={closeNotification} />
      )}
      <div className={Styles.header}>
        <Box gap="10px" flexWrap={['wrap']} className={Styles.header__first_block}>
          <h6>Profiles</h6>
          <div className={Styles.header__search}>
            <div className={Styles.header__search__container}>
              <SearchIcon />
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
            </div>
          </div>
        </Box>
        <div className={Styles.tabs}>
          <p className={selectedTab === 'all' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('all')}>
            All
          </p>
          <p className={selectedTab === 'waiting' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('waiting')}>
            Waiting for assignment
          </p>
          <p className={selectedTab === 'active' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('active')}>
            Active
          </p>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : allParents.length ? (
        <div className={Styles.data_container}>
          <Table
            headers={headers}
            data={parents}
            refetch={setRefetch}
            userType={user.user.user_type}
            userDataType={'parent'}
            showNotification={openNotification}
            onClickEvents={onClickEvents}
          />
        </div>
      ) : (
        <NoDataComponent text={'There are no parents yet.'} />
      )}
      {isOpenTherapistInfo && <TherapistInfoModal open={isOpenTherapistInfo} onClose={handleCloseTherapistInfo} therapistId={selectedTherapistId} />}
      <CustomPagination dataArray={allParents} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </Box>
  )
}
