import { apiPath } from '../constants'
import { request } from './axios'

export const therapistApi = {
  getAllScreenings: async () => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/screenings/`,
    })
  },
  getOneScreening: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/profiles/${data.id}/`,
    })
  },
  acceptApplication: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/screenings/${data.id}/accept/`,
    })
  },
  declineApplication: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/screenings/${data.id}/decline/`,

      data: JSON.stringify(data?.payload),
    })
  },
  getAllPatients: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/profiles/`,
    })
  },
  getHistory: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/profiles/${data.id}/history/`,
    })
  },
  getDocuments: async () => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/documents/`,
    })
  },
  getDocumentsForAdmins: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/therapists/${data.id}/documents/`,
    })
  },
  getNotes: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/parents/profiles/${data.id}/notes/`,
    })
  },
  createNote: async (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/api/parents/profiles/${data.id}/notes/create/`,

      data: JSON.stringify(data.payload),
    })
  },
}
