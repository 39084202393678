import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { useGetUserByUidMutation, useLinkExistingUserWithPromoCodeMutation } from 'defaultLayout/slice/service'
import { selectUserDetail } from 'defaultLayout/slice/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { authInstance } from 'utils/utils'

export const useAuthCheck = () => {
  const [linkPromoCode, { isSuccess }] = useLinkExistingUserWithPromoCodeMutation()

  const { actions, dispatch } = useDefaultLayoutSlice()
  const storedUser = useSelector(selectUserDetail)
  const [isAuthenticating, setIsAuthenticated] = useState(true)
  const [getUserByUid, { data, isLoading: isFetchingUser }] = useGetUserByUidMutation()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  useEffect(() => {
    if (data) {
      dispatch(actions.setUserData(data))
      localStorage.setItem('user_type', String(data.user_type))
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem('promo_code')
    }
  }, [isSuccess])
  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged(async (user) => {
      dispatch(actions.setIsAuthenticating(false))
      setIsAuthenticated(false)
      if (user) {
        const firebaseToken = await user.getIdToken()
        if (user.uid && firebaseToken) {
          // if (!storedUser?.id) {
          // }
          await getUserByUid(user.uid)
          const promo_code = localStorage.getItem('promo_code')
          if (promo_code) {
            await linkPromoCode({
              promo_code,
            })
          }
          // Only if it is inside a public routes
          if (
            (pathname.includes('/login') ||
              pathname.includes('/signup') ||
              pathname === '/' ||
              pathname.includes('/pass-reset') ||
              pathname.includes('/auth') ||
              pathname.includes('/pass-recovery') ||
              pathname.includes('/verify')) &&
            data?.id
          ) {
            navigate('/')
          }
        }
        dispatch(actions.setFirebaseToken(firebaseToken))
      }
    })

    return () => unsubscribe()
  }, [])

  return {
    user: data,
    isFetchingUser,
    isAuthenticating,
  }
}
