import { Alert, CircularProgress, IconButton, Modal } from '@mui/material'
import { Box, Button, CenterRow, Container, Text, TextArea } from 'components/Core'
import { OutlinedCard } from 'components/OutlinedCard/OulinedCard'
import type { Session } from 'components/ParentFlow/Therapy/HistoryRecord/types'
import type { UseDisclosure } from 'hooks/useDisclosure'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon, ThumbsDown, ThumbsUp } from 'theme/assets/icons'
import { useFeedBackMutation } from './service'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'shardSelectors/seletors'
import { SuccessLottie } from 'components/SuccessLottie/SuccessLottie'
import { parentActions } from 'actions'
import { userTypes } from 'constants/index'

interface Props extends UseDisclosure {
  record: Session
  userRole: keyof typeof userTypes
}
type ErrorType = { data: { message: string } }
type Feedback = { feedback: 'good' | 'bad' | undefined; tell_us_more?: string }
export const FeedbackModal = ({ record, onClose, open, userRole }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [uiError, setUiError] = useState<string>('')
  const [feedback, setFeedBack] = useState<Feedback>({ feedback: undefined, tell_us_more: '' })
  const [submitFeedback, { isLoading, error, isSuccess, reset }] = useFeedBackMutation()
  const user = useSelector(selectUser)
  const handleClose = () => {
    onClose()
    setUiError('')
    setFeedBack({ feedback: undefined, tell_us_more: '' })
    reset()
  }

  useEffect(() => {
    if (isSuccess) {
      if (userRole === userTypes.parent) {
        dispatch(parentActions.getTherapyInfo() as any)
      }
    }
  }, [isSuccess])
  return (
    <Modal open={open} onClose={handleClose}>
      <Box borderRadius={['15px']} maxWidth={500} variant="modal-container">
        <Box alignSelf="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {isSuccess ? (
          <CenterRow pb={[30]} flexDirection="column" gap={20}>
            <Box width={100} height={100}>
              <SuccessLottie />
            </Box>
            <Text>{t('history_record:feedback:success')}</Text>
          </CenterRow>
        ) : (
          <>
            <Box alignSelf="center" width={['298px']}>
              {error || (uiError && !feedback.feedback) ? (
                <Alert severity="error">{t(`history_record:feedback:errors:${(error as ErrorType)?.data?.message ?? uiError}`)}</Alert>
              ) : null}
            </Box>
            <Container gap={42} pb={30} flexDirection="column">
              <Text textAlign="center" variant="heading4">
                {t('history_record:feedback:title')}
              </Text>
              <CenterRow width="100%" justifyContent="center" gap={30}>
                <OutlinedCard
                  onClick={() => setFeedBack((prev) => ({ ...prev, feedback: 'good' }))}
                  selected={feedback.feedback === 'good'}
                  boxShadow="base"
                  width={90}
                  height={75}
                >
                  <Container flexDirection="column" gap={'5px'}>
                    <ThumbsUp width={30} />
                    <Text>{t('history_record:feedback:good')}</Text>
                  </Container>
                </OutlinedCard>
                <OutlinedCard onClick={() => setFeedBack((prev) => ({ ...prev, feedback: 'bad' }))} selected={feedback.feedback === 'bad'} width={90} height={75}>
                  <Container flexDirection="column" gap={'5px'}>
                    <ThumbsDown width={30} />
                    <Text>{t('history_record:feedback:bad')}</Text>
                  </Container>
                </OutlinedCard>
              </CenterRow>
              {feedback.feedback === 'bad' && (
                <Box>
                  <CenterRow mb={10} gap={1}>
                    <Text>Tell us more</Text>
                    <Text color="black.500">(optional)</Text>
                  </CenterRow>
                  <TextArea
                    style={{ resize: 'none' }}
                    height={119}
                    maxHeight={119}
                    width={['298px']}
                    maxWidth={['298px']}
                    placeholder="Tell us more"
                    value={feedback.tell_us_more}
                    onChange={(e) => setFeedBack((prev) => ({ ...prev, tell_us_more: e.target.value }))}
                  />
                </Box>
              )}
              <Button
                onClick={() => {
                  setUiError('')
                  if (['good', 'bad'].includes(feedback.feedback as string)) {
                    submitFeedback({
                      body: {
                        feedback: feedback.feedback,
                        tell_us_more: feedback.tell_us_more,
                        meeting_id: record?.calcom_session?.zoom_meeting_id,
                      },
                    })
                  } else {
                    setUiError('selectFeedback')
                  }
                }}
                width={['298px']}
                borderRadius={['10px']}
                fontWeight="bold"
                variant="primary"
              >
                {isLoading ? <CircularProgress color="inherit" size={25} /> : t('history_record:feedback:submit')}
              </Button>
            </Container>
          </>
        )}
      </Box>
    </Modal>
  )
}
