import { Button, CenterColumn, CenterRow, Chip, Container, Flex, Text } from 'components/Core'
import { Card, CardActions, CardContent, CardHeader, List, ListItem } from '@mui/material'
import { dateFormatter } from 'utils/utils'
import { Screening } from 'utils/types/types'
import { checkIfDateIsTomorrowOrAfter } from 'utils'
import { CustomTooltip } from 'components/GeneralComponents'
import { sessionStatus } from 'constants/index'
import { useNavigate } from 'react-router-dom'
import { Visibility } from '@mui/icons-material'

type CommonProps = {
  showNotification: (profileName: string, status: 'accept' | 'decline') => void
  onClickEvents: {
    acceptApplication: (screeningId: number | undefined, profileName: string) => void
    declineApplication: (screeningId: number | undefined, profileName: string) => void
    joinMeeting: (meetingId: number | undefined) => void
    openUserDetails: (profileId: number, isAdult: boolean) => void
  }
}
interface Props extends CommonProps {
  screenings: Screening[] | undefined
}
export const ScreeningsCardView = ({ screenings, onClickEvents, showNotification }: Props) => {
  return (
    <Container flexDirection={['column']} gap={[16]}>
      {screenings?.map((screening) => (
        <Card sx={{ width: '100%', py: 1 }} key={screening?.id}>
          <CardHeader
            title={screening?.profile_name}
            action={<Chip textTransform="uppercase" type={screening?.therapist_status === 'accepted' ? 'success' : 'warning'} value={screening?.therapist_status} />}
          />
          <CardContent>
            <CenterColumn gap={16}>
              <Flex gap={'5px'}>
                <Text fontWeight="bold">Birthday:</Text>
                <Text>{screening?.birth_date ?? '--'}</Text>
              </Flex>
              <Flex alignItems="center" flexWrap={['wrap']} gap={'5px'}>
                <Text fontWeight="bold">Problems:</Text>
                <CenterRow gap={1} flexWrap="wrap">
                  {screening?.concerns?.length ? screening?.concerns?.map((concern, index) => <Chip fontSize={15} key={concern + index} value={concern} type="info" />) : '--'}
                  {/* {Array.isArray(patient?.concerns) && patient?.concerns?.length ? patient?.concerns?.join(', ') : '--'} */}
                </CenterRow>
                {/* <Text fontWeight="bold">Problems:</Text>
                <Text>{Array.isArray(screening?.concerns) && screening?.concerns.length ? screening?.concerns?.join(', ') : '--'}</Text> */}
              </Flex>
              <Flex gap={'5px'}>
                <Text fontWeight="bold">Meeting Time:</Text>
                <Text>
                  {dateFormatter({
                    date: screening?.calcom_session.start_time,
                    formatType: 'Pp',
                  }) ?? '--'}
                </Text>
              </Flex>
            </CenterColumn>
          </CardContent>
          <CardActions>
            <CenterRow gap="10px" width="100%" justifyContent="space-between">
              <Button
                borderBottom={1}
                variant="ghost"
                onClick={() => {
                  onClickEvents.openUserDetails(screening?.profile_id, screening?.is_adult)
                }}
                gap="5px"
              >
                <Visibility />
                <Text variant="light">Questionnaire</Text>
              </Button>
              <Actions onClickEvents={onClickEvents} screening={screening} showNotification={showNotification} />
            </CenterRow>
          </CardActions>
        </Card>
      ))}
    </Container>
  )
}

interface ActionProps extends CommonProps {
  screening: Screening
}
const Actions = ({ screening, onClickEvents, showNotification }: ActionProps) => {
  const isDateTomorrowOrAfter = checkIfDateIsTomorrowOrAfter(screening?.calcom_session?.start_time)
  const navigate = useNavigate()
  return (
    <CenterRow>
      {screening?.therapist_status === 'waiting' ? (
        <>
          <Button
            variant="ghost"
            onClick={() => {
              onClickEvents.declineApplication(screening?.id, screening?.profile_name)
              showNotification(screening?.profile_name, 'decline')
            }}
          >
            Decline
          </Button>
          <Button
            onClick={() => {
              onClickEvents.acceptApplication(screening?.id, screening.profile_name)
              showNotification(screening?.profile_name, 'accept')
            }}
          >
            Accept
          </Button>
        </>
      ) : (
        <>
          {screening.meeting_id &&
            ([sessionStatus.Planned, sessionStatus['In progress']].includes(screening?.calcom_session?.get_status) ? (
              // @ts-ignore
              <CustomTooltip title={isDateTomorrowOrAfter ? 'Session is not available' : ''} placement="top">
                <Button
                  disabled={isDateTomorrowOrAfter}
                  onClick={() => {
                    onClickEvents.joinMeeting(screening?.calcom_session?.zoom_meeting_id)
                  }}
                >
                  Join meeting
                </Button>
              </CustomTooltip>
            ) : (
              <Button
                onClick={() => {
                  navigate(`/profile/therapist/patients/child/${screening.profile_id}?type=${screening.is_adult ? 'adult' : 'child'}`, {
                    state: {
                      profile_id: screening?.profile_id,
                    },
                  })
                }}
                variant="secondary"
              >
                View Profile
              </Button>
            ))}
        </>
      )}
    </CenterRow>
  )
}
