import type { OnboardingStep } from 'defaultLayout/types'
import { Box, Button, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { mainStatementAnswers } from 'constants/index'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { LinearProgress, TextField } from '@mui/material'
import { useQuestionnaire } from './useQuestionnaire'
import { RFor } from 'components/RFor/RFor'
import { SubStatement } from './SubStatement'
import { useGetLanguage } from 'hooks/useGetLanguage'

import { useTranslation } from 'react-i18next'
import ar from '../AddChild/locale.js/ar.json'
import en from '../AddChild/locale.js/en.json'
import { type Dispatch, type SetStateAction, useEffect } from 'react'
import { mainStatementAnswer, translateMainAnswersQuestionnaire, translateQuestionnaire } from 'data/locale.data'
import { MainStatementAnswerKet } from 'utils/types/types'

type Props = {
  setOnboardingStep: (step: OnboardingStep) => void
  getSteps: (value: number | string) => void
  setAddChildStep: (step: number) => void
  setChildInfoCurrentStep: Dispatch<SetStateAction<number>>
  decreaseQuestionIndex: () => void
  increaseQuestionIndex: () => void
}

export const Mandatory = ({ setOnboardingStep, getSteps, setAddChildStep, setChildInfoCurrentStep, decreaseQuestionIndex, increaseQuestionIndex }: Props) => {
  const { currentMainStatementIndex, questionnaireData, disableNextButton, subStatementsOpened, onAnswerNo, onAnswerYes, goBack, next, isLastMainStatementIndex } =
    useQuestionnaire({
      getSteps,
      setOnboardingStep,
      setAddChildStep,
    })

  const { language, direction, isArabic } = useGetLanguage({
    userType: 'parent',
  })
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(language ?? 'en')
  }, [language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
  }, [])

  return (
    <Container
      justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']}
      alignItems={['center']}
      bg="white"
      gap={40}
      flexDirection="column"
      direction={direction}
      // minHeight={'100%'}
      py={16}
      height="auto"
      width="100%"
    >
      <CenterRow flexDirection={isArabic ? 'row-reverse' : 'row'} width={['95%', '95%', '90%', '50%']} justifyContent="space-between">
        {/* <Button
          flexDirection={isArabic ? 'row-reverse' : 'row'}
          onClick={() => {
            decreaseQuestionIndex()
            if (currentMainStatementIndex === 0) {
              setChildInfoCurrentStep(4)
              setOnboardingStep('add_child')
            } else {
              goBack()
            }
          }}
          variant="ghost"
        >
          {isArabic ? <ChevronRight /> : <ChevronLeft />}
          <Text fontSize={17}>{t('add_child:footer:back')}</Text>
        </Button> */}
        {/* <Button
          flexDirection={isArabic ? 'row-reverse' : 'row'}
          disabled={disableNextButton}
          onClick={() => {
            increaseQuestionIndex()
            if (isLastMainStatementIndex) {
              setTimeout(() => {
                next()
              }, 500)
            } else {
              next()
            }
          }}
          variant="ghost"
        >
          <Text fontSize={17}>{t('add_child:footer:next')}</Text>
          {isArabic ? <ChevronLeft /> : <ChevronRight />}
        </Button> */}
      </CenterRow>
      {/* <CenterRow width={['95%', '95%', '90%', '50%']}>
        <LinearProgress sx={{ width: '100%' }} color="secondary" variant="determinate" value={progress} />
      </CenterRow> */}
      <Container direction={direction} width={['100%', '100%', '90%', '600px']} flexDirection="column" gap={40}>
        {questionnaireData?.map((item, index) => (
          <>
            {currentMainStatementIndex === index && !subStatementsOpened && (
              <Container width="100%" justifyContent={'flex-start'} gap={[40]} key={index} flexDirection="column">
                <CenterRow justifyContent={'center'} height={100} flexDirection={'column'}>
                  {/* <Text variant="numberHolder">{index + 1}</Text> */}
                  <Text variant="heading4" textAlign="center" fontWeight="normal">
                    {/* @ts-ignore */}
                    {translateQuestionnaire?.[index as keyof typeof translateQuestionnaire]?.[item.main_statement]?.[language]}
                  </Text>
                </CenterRow>
                <CenterColumn width="100%" gap="8px">
                  <CenterRow justifyContent="center" height={100} px={2} gap={10} width={['100%']} flexDirection={['column', 'column', 'row']}>
                    <Button
                      onClick={() => {
                        increaseQuestionIndex()
                        onAnswerYes(index)
                      }}
                      width={['100%', '100%', 'auto']}
                      flexGrow={1}
                      variant={item.main_statement_answer === mainStatementAnswers.yes ? 'primary' : 'secondary'}
                    >
                      {mainStatementAnswer?.['Yes']?.[language as MainStatementAnswerKet]}
                    </Button>
                    <Button
                      width={['100%', '100%', 'auto']}
                      onClick={() => {
                        increaseQuestionIndex()
                        onAnswerNo(index)
                      }}
                      flexGrow={1}
                      variant={item.main_statement_answer === mainStatementAnswers.no ? 'primary' : 'secondary'}
                    >
                      {mainStatementAnswer?.['No']?.[language as keyof (typeof mainStatementAnswer)['No']]}
                    </Button>
                  </CenterRow>
                  <CenterRow width="100%">
                    <Button
                      direction={'ltr'}
                      flexDirection={isArabic ? 'row-reverse' : 'row'}
                      onClick={() => {
                        decreaseQuestionIndex()
                        if (currentMainStatementIndex === 0) {
                          setChildInfoCurrentStep(4)
                          setOnboardingStep('add_child')
                        } else {
                          goBack()
                        }
                      }}
                      variant="ghost"
                      color="textLightGray"
                    >
                      {isArabic ? <ChevronRight /> : <ChevronLeft />}
                      <Text fontSize={17}>{t('add_child:footer:back')}</Text>
                    </Button>
                  </CenterRow>
                </CenterColumn>
              </Container>
            )}

            {/* {subStatementsOpened && currentMainStatementIndex === index && (
              <Container width={'100%'} gap={[40]} flexDirection="column">
                <RFor
                  data={item.sub_statements}
                  renderItem={(sub_statement, subStatementIndex) =>
                    currentSubStatementIndex === subStatementIndex && (
                      <SubStatement
                        mainStatementIndex={index}
                        setSubStatementAnswer={setSubStatementAnswer}
                        increaseSubStatementIndex={increaseSubStatementIndex}
                        language={language}
                        subStatementIndex={subStatementIndex}
                        {...sub_statement}
                        key={subStatementIndex}
                      />
                    )
                  }
                />
              </Container>
            )} */}
          </>
        ))}
      </Container>

      {/* {unansweredQuestions.length ? (
        <Container width="100%" justifyContent="center" mt={10} gap={20} flexDirection="column">
          <Text variant="heading3" textAlign="center" color="black.600">
            Unfinished Questions Under
          </Text>
          {unansweredQuestions.map((item, index) => (
            <Flex paddingBottom={10} key={index} gap={20}>
              <CenterRow borderRight={2} paddingRight={20} gap={10}>
                <Text width="200px">Question Number: </Text>
                <Text variant="numberHolder">{item?.questionNumber + 1}</Text>
              </CenterRow>
              <CenterRow gap={10}>
                <Text width="200px">unanswered count: </Text>
                <Text variant="numberHolder">{item?.subStatement?.length}</Text>
              </CenterRow>
            </Flex>
          ))}
        </Container>
      ) : null} */}
    </Container>
  )
}
