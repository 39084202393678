// Constants
import { apiPath } from '../constants'
import { fetchRequest } from './fetch'
import { request } from './axios'
import { authInstance } from 'utils/utils'

export const authApi = {
  signupTherapist: (data) => {
    return fetchRequest(`${apiPath}/api/therapists/create/`, {
      method: 'POST',
      body: JSON.stringify(data.data),
    })
  },
  signupUpdateAdvisor: (data) => {
    return fetchRequest(`${apiPath}/api/advisors/finish-registration/${data.token}/`, {
      method: 'PUT',
      body: JSON.stringify(data.update),
    })
  },
  verifyEmail: (data) => {
    return fetchRequest(`${apiPath}/api/api-token-auth/resend-verify-email/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  confirmEmail: (data) => {
    return fetchRequest(`${apiPath}/api/api-token-auth/verify-email/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  checkUserEmail: (data) => {
    return fetchRequest(`${apiPath}/api/users/check-mail/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  loginUser: (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/api/api-token-auth/login/`,
      data: JSON.stringify(data),
    })
  },
  recoveryPassword: (data) => {
    return fetchRequest(`${apiPath}/api/api-token-auth/send-reset-password-email/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  resetPasswordValidToken: (data) => {
    return fetchRequest(`${apiPath}/api/api-token-auth/reset-password/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  resetPassword: (data) => {
    return fetchRequest(`${apiPath}/api/api-token-auth/reset-password/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  logoutUser: (data) => {
    return fetchRequest(`${apiPath}/api/api-token-auth/logout/`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  getUser: (data) => {
    const firebase_uid = authInstance.currentUser.uid
    return fetchRequest(`${apiPath}/api/users/${data?.id}/`, {
      method: 'GET',
      // body: JSON.stringify({
      //   firebase_uid,
      //   id: data.id,
      // }),
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  signupParent: (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/api/landing/submit-questionnaire/`,
      data: JSON.stringify(data),
    })
  },
}
