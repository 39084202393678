import { Container, Flex, Text, Button } from 'components/Core'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { useNavigate } from 'react-router-dom'
import { SuccessLottie } from 'components/SuccessLottie/SuccessLottie'
import { useEffect, useMemo } from 'react'
import { PaymentResponse } from 'utils/types/tapPaymentTypes'
import { tapPaymentStatusCode, PaymentStatuses } from 'constants/index'
import { useContentBundle } from 'hooks/useContentBundle'
// import { ErrorLottie } from 'components/ErrorLottie/ErrorLottie'
import locale from './locale.json'
import { Close, X } from '@mui/icons-material'
import { IconWrapper, NoAccessCard } from 'pages/Courses/CourseSharedComponents'

const StatusCard = styled(motion.div)`
  background: ${theme.colors.white};
  padding: 40px;
  border-radius: 20px;
  box-shadow: ${theme.shadows.lg};
  text-align: center;
  max-width: 500px;
  width: 100%;
`

const StatusCircle = styled(motion.div)<{ status: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${(props) => (props.status === PaymentStatuses.CAPTURED ? theme.colors.success[100] : theme.colors.error[100])};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
`

export const CoursePaymentSuccess = () => {
  const navigate = useNavigate()
  const paymentResponse = localStorage.getItem('paymentResponse')
  const responseValue = useMemo<PaymentResponse>(() => {
    return JSON.parse(paymentResponse || '{}')
  }, [paymentResponse])

  const paymentStatus = tapPaymentStatusCode[responseValue?.response?.code as keyof typeof tapPaymentStatusCode]
  const isSuccess = paymentStatus === PaymentStatuses.CAPTURED

  const getStatusMessage = () => {
    if (isSuccess) {
      return t('coursePayment:payment:success:message')
    }
    return t('coursePayment:payment:failed:message')
  }

  const courseId = responseValue?.reference?.order
  const { t } = useContentBundle({
    arabicContent: locale.ar,
    englishContent: locale.en,
    key: 'coursePayment',
  })

  useEffect(() => {
    return () => {
      localStorage.removeItem('paymentResponse')
    }
  }, [])
  return (
    <Container height="100vh">
      <Flex p={[3, 3, 3, 4]} alignItems="center" justifyContent="center" height="100%" minWidth={['100%', '100%', '500px']}>
        <NoAccessCard initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
          <StatusCircle status={paymentStatus} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2, type: 'spring' }}>
            {isSuccess ? (
              <SuccessLottie />
            ) : (
              <IconWrapper initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2, type: 'spring' }}>
                <Close fontSize="large" color="error" />
              </IconWrapper>
            )}
          </StatusCircle>

          <Text variant="heading2" mb={3}>
            {isSuccess ? t('coursePayment:payment:success:title') : t('coursePayment:payment:failed:title')}
          </Text>

          <Text variant="body1" color="textSecondary">
            {getStatusMessage()}
          </Text>

          <Flex mt={16} gap={3} justifyContent="center">
            {isSuccess ? (
              <>
                <Button variant="primary" onClick={() => navigate(`/profile/parent/course/${courseId}`)}>
                  {t('coursePayment:buttons:courses')}
                </Button>
                {/* <Button variant="secondary" onClick={() => navigate('/')}>
                  {t('coursePayment:buttons:home')}
                </Button> */}
              </>
            ) : (
              <>
                {/* <Button variant="primary" onClick={() => window.history.back()}>
                  {t('coursePayment:buttons:tryAgain')}
                </Button> */}
                <Button variant="secondary" onClick={() => navigate('/profile/parent/courses')}>
                  {t('coursePayment:buttons:coursePage')}
                </Button>
              </>
            )}
          </Flex>
        </NoAccessCard>
      </Flex>
    </Container>
  )
}
