import { apiRoutes } from 'apiRoutes'
import { TherapistSessionLength } from 'constants/index'
import { rootServiceApi } from 'store/service'

type ResultType = { data: Array<{ duration: number; price: number }> }
type QueryArgs = { therapist_id: any }

export const checkEmailExistenceServiceForAuthenticatedUser = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getSessionLengthPrice: build.mutation<ResultType, QueryArgs>({
      query: ({ therapist_id }) => {
        return {
          url: apiRoutes.getSessionLengthPrice,
          method: 'POST',
          data: {
            therapist_id,
            profile_id: Number(localStorage.getItem('lastSelectedProfile')) || 0,
          },
        }
      },
      transformResponse: (response: ResultType, meta, arg) => {
        // const data = response.data.filter((item) => item.duration !== 60)

        const therapistSessionConfig = JSON.parse(TherapistSessionLength || '{}')
        const allowedDurations = therapistSessionConfig[arg.therapist_id] || []

        const newData = allowedDurations?.length ? response.data.filter((item) => allowedDurations.includes(item.duration)) : response.data
        return { data: newData }
      },
    }),
  }),
})

export const { useGetSessionLengthPriceMutation } = checkEmailExistenceServiceForAuthenticatedUser
