import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type QueryArgs = {
  therapist_id: string
  // This is not the user id, but current profile selected (lastSelectedProfile) of the user
  profile_id: string
}

const zoomService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    afterDiscoveryCall: build.mutation<any, QueryArgs>({
      query: (data) => ({
        url: apiRoutes.parent.afterDiscovery,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useAfterDiscoveryCallMutation } = zoomService
