// Core
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Actions
import { advisorOnbordingActions } from '../../actions/advisorOnbordingActions'
// Components
import { GeneralProfileInformation } from '../../components/ClientProfileComponents/GeneralInformation'
import { ParentProfileQuestionnairePartWithTranslation } from '../../components/ClientProfileComponents/QuestionnairePartWithTranslation'
// Styles
import Styles from './Styles.module.scss'
import { CustomTabs } from 'components/shared/CustomTab/Tabs'
import { TreatmentPlan } from 'pages/ShardPage/TreatmentPlan/TreatmentPlan'
import { childOnboardingQuestions } from 'data'
import { ParentTask } from 'pages/ShardPage/ParentTask/ParentTask'
import { CustomAccordion } from 'components/shared/CustomAccordion'
import { theme } from 'utils/theme'

export const AdvisorParentProfilePage = () => {
  const dispatch = useDispatch()

  const currentProfile = useSelector((state) => state.advisorOnbording.currentParent)
  // console.log(currentProfile)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [meetingInfo, setMeetingInfo] = useState(null)
  useEffect(() => {
    if (profileId) {
      const dataForRequest = {
        profileId,
      }

      dispatch(advisorOnbordingActions.getProfile(dataForRequest))
    }
  }, [profileId])
  const onboardingQuestions = useMemo(() => {
    return Array.isArray(currentProfile?.onboarding) ? currentProfile?.onboarding : Object.values(currentProfile?.onboarding || {})
  }, [currentProfile?.onboarding])
  const returnNewOnboarding = () => {
    if (currentProfile?.questionnaire?.questionnaire) {
      let finalOnboarding = []

      try {
        if (onboardingQuestions?.length) {
          for (const sections of currentProfile?.questionnaire?.questionnaire) {
            for (const onboardSection of onboardingQuestions) {
              if (sections?.id === onboardSection?.id) finalOnboarding?.push({ ...sections, advisor_comment: onboardSection?.advisor_comment })
            }
          }
        } else {
          finalOnboarding = currentProfile?.questionnaire?.questionnaire
        }
      } catch (error) {
        console.log('Error', error)
      }

      return finalOnboarding
    } else {
      return null
    }
  }

  const returnPreviousOnboarding = () => {
    if (currentProfile?.onboarding) {
      const finalOnboarding = []

      for (const sections of childOnboardingQuestions) {
        for (const onboardSection of currentProfile?.onboarding) {
          if (sections?.id === onboardSection?.id)
            finalOnboarding?.push({ id: sections?.id - 1, main_statement: sections?.question, main_statement_answer: onboardSection?.answer })
        }
      }

      return finalOnboarding
    } else {
      return null
    }
  }

  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.full_name,
        age: currentProfile?.age,
        birth_date: currentProfile?.birth_date,
        languages: currentProfile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
        parent_preferred_language: currentProfile?.parent?.preferred_language,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const questionnaire = {
        familyDynamics: currentProfile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.questionnaire?.parental_wellbeing,
        questionnaire: returnNewOnboarding() || returnPreviousOnboarding(),
      }

      const meetingInfo = {
        calcom_session: {
          start_time: currentProfile?.start_time,
          get_status: currentProfile?.status,
          meeting_id: currentProfile?.meeting_id,
        },
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setQuestionnaire(questionnaire)
      setMeetingInfo(meetingInfo)
      // console.log(meetingInfo)
    }
  }, [currentProfile])

  return (
    <div className={Styles.main_container}>
      <div className={Styles.general_block}>
        <CustomTabs
          tabs={[
            {
              label: 'User profile',
              value: 0,
              component: <GeneralProfileInformation profile={profileInfo} client={clientInfo} />,
            },
            {
              label: 'Treatment plan',
              value: 1,
              component: <TreatmentPlan allowEdit={false} name={profileInfo?.full_name} profile_id={profileId} />,
            },
            // {
            //   label: 'Parent Tasks',
            //   value: 1,
            //   component: <ParentTask readOnly allowEdit={false} profile_name={profileInfo?.full_name} profile_id={profileId} />,
            // },
          ]}
        />
        {/* <div className={Styles.general_block__meeting_block}>
          <h6>Onboarding session</h6>
          <HistoryRecord record={meetingInfo} />
        </div> */}
      </div>
      <div className={Styles.general_block}>
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
          <CustomAccordion
            accordionSummaryProps={{
              sx: {
                backgroundColor: 'white',
                borderRadius: '10px',
                boxShadow: theme.shadows.normal,
                px: 1,
              },
            }}
            titleAlignment="left"
            title={'Questionnaire and Reports'}
          >
            <ParentProfileQuestionnairePartWithTranslation
              advisor_additional_comment={onboardingQuestions?.[onboardingQuestions?.length - 1]?.additional_advisor_comments}
              questionnaire={questionnaire}
              profile={profileInfo}
              style={{ width: '100%' }}
            />
          </CustomAccordion>
        </div>
      </div>
    </div>
  )
}
