// Core
import { useSelector } from 'react-redux'
// Navigation
import { Private, Public } from './navigations'
//Components
import { selectIsAuthenticating } from 'defaultLayout/slice/selectors'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Container } from 'components/Core'
import { useAuthCheck } from 'hooks/useAuthCheck'
import { useAuth } from 'hooks/useAuth'

const App = () => {
  const { userAuthenticated } = useAuth()

  const { user, isFetchingUser, isAuthenticating } = useAuthCheck()

  if (isAuthenticating || isFetchingUser) {
    return (
      <Container height="100vh">
        <Spinner />
      </Container>
    )
  }
  // console.log(userAuthenticated, isFetchingUser)

  return <>{userAuthenticated && user?.id ? <Private /> : <Public />}</>
}

export default App
