import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { ChildInfoType, ParentOnboardingState } from './type'
import { useDispatch, useSelector } from 'react-redux'
import { selectChildInfo } from './selectors'
const childInfo = localStorage.getItem('childInfo')
const initialState: ParentOnboardingState = {
  childInfo: {
    birth_date: '',
    child_name: '',
    concerns: [],
    languages: [],
    ...JSON.parse(childInfo ?? '{}'),
  },
}
export const parentOnboarding = createSlice({
  name: 'parentOnboarding',
  initialState,
  reducers: {
    updateChildInfo: (state, action: PayloadAction<Partial<ChildInfoType>>) => {
      state.childInfo = {
        ...state.childInfo,
        ...action.payload,
      }
    },
  },
})

export const parentOnboardingReduce = parentOnboarding.reducer
export const useParentOnboardingSlice = () => {
  const { actions } = parentOnboarding
  const childInfo = useSelector(selectChildInfo)
  const dispatch = useDispatch()
  const updateChildInfo = (payload: Partial<ChildInfoType>) => {
    dispatch(actions.updateChildInfo(payload))
  }
  return { actions, childInfo, updateChildInfo }
}
