//Core
import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//Styles
import Styles from './Styles.module.scss'
//Components
import { Table } from '../../Applies/Table'
import { NoDataComponent, CustomPagination } from '../../GeneralComponents'
//Actions
import { adminActions } from '../../../actions'
//Helpers
import { paginateArray } from '../../../utils'
import { selectFirebaseToken } from 'defaultLayout/slice/selectors'
import { useAuth } from 'hooks/useAuth'

/**
 * AdminMeetingsTab component manages and displays Zoom meetings for administrators.
 * @returns {JSX.Element} AdminMeetingsTab component.
 */
export const AdminMeetingsTab = () => {
  const dispatch = useDispatch()

  const allMeetings = useSelector((state) => state.admin.allZoomMeetings)
  // const user = useSelector((state) => state.auth.user)
  const { user, userAuthenticated } = useAuth()

  const [refetch, setRefetch] = useState(false)

  const [meetings, setMeetings] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  /**
   * Fetches all meetings on initial load and whenever `refetch` or `user` changes.
   */
  const fetchMeetings = useCallback(() => {
    if (userAuthenticated) dispatch(adminActions.getAllMeetings())
  }, [userAuthenticated])
  useEffect(() => {
    fetchMeetings()
  }, [fetchMeetings])

  /**
   * Paginates the `allMeetings` array and updates `meetings` state based on `currentPage`.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allMeetings)
    if (paginatedData.length) {
      setMeetings([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setMeetings([])
    }
  }, [allMeetings, currentPage])

  /**
   * Dispatches an action to retry a Zoom meeting with specified `zoomId`.
   * @param {string} zoomId - The ID of the Zoom meeting to retry.
   */
  const handleRetryZoomMeeting = (zoomId) => {
    const dataForRequest = {
      id: zoomId,
      token: firebaseToken,
      fetchMeetings,
    }

    dispatch(adminActions.retryZoomMeeting(dataForRequest))
  }

  const onClickEvents = {
    retryZoomMeeting: handleRetryZoomMeeting,
  }

  const headers = [
    { text: 'ORGANIZER', sorting: false },
    { text: 'CLIENT', sorting: false },
    { text: 'MEETING START', sorting: false },
    { text: 'MEETING END', sorting: false },
    { text: 'ACTION', sorting: false },
  ]

  return (
    <div className={Styles.main_container}>
      <h6>Zoom meetings</h6>
      {meetings.length ? (
        <div className={Styles.data_container}>
          <Table headers={headers} data={meetings} userType={user?.user_type} userDataType={'meetings'} onClickEvents={onClickEvents} />
        </div>
      ) : (
        <NoDataComponent text={'There are no meetings yet.'} />
      )}
      <CustomPagination dataArray={allMeetings} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
  )
}
