//Core
import { useEffect } from 'react'
// Components
import {
  ParentOnboardingAddChild,
  ParentOnboardingFamilyDynamics,
  ParentOnboardingOptionalDisclaimer,
  ParentOnboardingParentalWellbeing,
  ParentOnboardingReport,
} from '../../components/Auth'
// Enums
import { ONBOARDING_STEP } from '../../data/enums'
// Utils
import { getCookie, setCookie } from '../../utils'
import { useQuestionnaireForm } from 'hooks/useQuestionnaireForm'
import { Mandatory } from 'components/Auth/ParentOnboarding/QuestionsPartMandatory/Mandatory'

export const ParentQuestionnaireForExistingAccount = ({ isAccountExists }) => {
  useEffect(() => {
    const onboardingStateFromCookies = JSON.parse(getCookie('parent_onboarding'))

    if (onboardingStateFromCookies) {
      setOnboardingStep(onboardingStateFromCookies?.state)
    } else {
      const parentOnboardingObject = {
        state: ONBOARDING_STEP.ADD_CHILD,
        report_loading: true,
      }

      setCookie('parent_onboarding', JSON.stringify(parentOnboardingObject))
    }
  }, [])
  const { abTestingState, getSteps, navigateToParentForm, setOnboardingStep, setAddChildStep } = useQuestionnaireForm()

  useEffect(() => {
    if (!abTestingState.onboardingStep) setOnboardingStep(ONBOARDING_STEP.ADD_CHILD)
  }, [!abTestingState.onboardingStep])
  useEffect(() => {
    setAddChildStep(1)
  }, [])
  return (
    <>
      {abTestingState.onboardingStep === ONBOARDING_STEP.ADD_CHILD && (
        <ParentOnboardingAddChild
          isAccountExists={isAccountExists}
          navigateToParentForm={navigateToParentForm}
          isVariantB={true}
          setAddChildStep={setAddChildStep}
          addChildStep={abTestingState.addChildStep}
          setOnboardingStep={setOnboardingStep}
        />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.QUESTIONNAIRE && (
        <Mandatory setAddChildStep={setAddChildStep} isVariantB={true} getSteps={getSteps} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.FAMILY_DYNAMICS} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS && <ParentOnboardingFamilyDynamics setOnboardingStep={setOnboardingStep} />}
      {abTestingState.onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.PARENTAL_WELLBEING} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING && <ParentOnboardingParentalWellbeing setOnboardingStep={setOnboardingStep} />}
      {abTestingState.onboardingStep === ONBOARDING_STEP.REPORT && (
        <ParentOnboardingReport setAddChildStep={setAddChildStep} setOnboardingStep={setOnboardingStep} isAccountExists={isAccountExists} />
      )}
    </>
  )
}
