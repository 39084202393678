// Core
import { shape, string, bool, number } from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'

// Styles
import Styles from './styles.module.scss'
import StylesNewMessage from '../../MessagesHistory/NewMessageBar/styles.module.scss'
// Components
import { ReactComponent as CheckIcon } from '../../../../../theme/assets/icons/check_updated.svg'
import { Spinner } from '../../Spinner'
//Helpers
import { getFormatedLocalTime } from '../../../../../helpers'

//Utils
import { convertBytes, returnContent } from '../../../../../utils'
//Icons
import { ReactComponent as FileIcon } from '../../../../../theme/assets/icons/file_new.svg'
import { IconButton } from '@mui/material'
import { Download } from '@mui/icons-material'
import axios from 'axios'
import { Flex, Text } from 'components/Core'

export const SentMessageInstance = ({ messageInfo }) => {
  const { isViewed, content, timestamp, attachment } = messageInfo
  const [isLoading, setIsLoading] = useState(false)

  const user = useSelector((state) => state.auth.user)

  const handleOpenFile = async (fileUrl, filename) => {
    try {
      setIsLoading(true)
      await axios(fileUrl)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
          URL.revokeObjectURL(url)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } catch (error) {
      console.error('Error downloading file:', error)
      setIsLoading(false)
    }
  }

  const getPreviewImg = (fileType, filePreview) => {
    switch (fileType) {
      case 'png':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      case 'jpg':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      default:
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <FileIcon />
          </div>
        )
    }
  }

  const thumbs = () => {
    return (
      <div className={StylesNewMessage.preview__item} key={attachment.name}>
        <div className={StylesNewMessage.preview__item__preview}>
          <>
            <>{getPreviewImg(attachment?.extension, attachment?.url)}</>
            <div className={StylesNewMessage.preview__item__preview__description}>
              <p className={StylesNewMessage.preview__item__preview__description__name}>{attachment.filename}</p>
              <p className={StylesNewMessage.preview__item__preview__description__size}>{convertBytes(attachment.size)}</p>
            </div>
          </>
        </div>
        <IconButton onClick={() => handleOpenFile(attachment.url, attachment.filename)} size="small">
          {isLoading ? <Spinner width="30px" /> : <Download fontSize="medium" />}
        </IconButton>
      </div>
    )
  }

  const formattedTime = timestamp.toLocaleString('en-US', { timeZone: user.timeZone })

  return (
    <div className={Styles.sentMessageInstance}>
      <Flex maxWidth={['95%', '95%', '80%', '75%', '65%', '50%']} className={Styles.sentMessageInstance__content}>
        {/* {isViewed && (
          <div className={Styles.sentMessageInstance__content__icon}>
            <CheckIcon />
          </div>
        )} */}
        {attachment ? thumbs() : <Text className={Styles.sentMessageInstance__content__message}>{returnContent(content)}</Text>}
      </Flex>
      <p className={Styles.sentMessageInstance__time}>{getFormatedLocalTime(formattedTime)}</p>
    </div>
  )
}
