import { BoxProps, Flex } from 'components/Core'
import type { ReactNode } from 'react'

interface Props extends BoxProps {
  children: ReactNode
}
export const FlowContainer = ({ children, ...rest }: Props) => {
  return (
    <Flex flexDirection={['column']} borderRadius={10} bg="white" width={['100%', '100%', '90%', '804px']} px={[2, 2, 24]} overflowY="scroll" gap="25px" {...rest}>
      {children}
    </Flex>
  )
}
