import { Box, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { theme } from 'utils/theme'
import { MCQ } from './MCQ'
import { DonNotHaveAccessToCourse } from './DonNotHaveAccessToCourse'
import { IconButton, LinearProgress, useMediaQuery } from '@mui/material'
import { Check, Close, DocumentScanner, List, Quiz, Star, Videocam } from '@mui/icons-material'
import styled from 'styled-components'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { DownloadableSection } from './DownloadbaleSection'
import { CourseFeedback } from './CourseFeedback'

import { useCoursePage } from './hook/useCoursePage'
import { useCourseContent } from './hook/useCourseContent'
import { CourseVideo } from './CourseVideo'
import { CourseContentType } from 'navigations/locale/course_english'
import { Course, CourseJourneyItem } from 'utils/types/types'
import { Direction } from 'components/Core/common/types'
import { DrawerComponent } from 'components/shared/DrawerComponent'
import { useDisclosure } from 'hooks/useDisclosure'
import { useEffect, useRef, useState } from 'react'

export const CoursePage = () => {
  const {
    courseId,
    isLoading,
    youDonNotHaveAccess,
    data,
    feedbackOpened,
    currentStep,
    fetchingSignedUrl,
    progress,
    selectedJourney,
    signedUrl,
    isLastLesson,
    direction,
    refetchSignedUrl,
    refetch,
    completeLesson,
    handleNext,
    closeFeedback,
    selectCourse,
    updateVideoTime,
    handleOpenFeedback,
    setCurrentStep,
  } = useCoursePage()
  const { content } = useCourseContent(data?.language)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const { open, toggle } = useDisclosure()
  const belowLg = useMediaQuery('(max-width:1024px)')
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    ref.current?.addEventListener('contextmenu', (e) => e.preventDefault())
  }, [])

  if (youDonNotHaveAccess && !isLoading) {
    return <DonNotHaveAccessToCourse />
  }

  return (
    <Flex ref={ref} flexDirection={['column', 'column', 'column', 'row']} height={'100%'} maxHeight="100%" bg="white">
      {belowLg && (
        <>
          <DrawerComponent open={open} toggleDrawer={toggle}>
            <Sidebar
              content={content}
              progress={progress}
              currentStep={currentStep}
              data={data}
              direction={direction}
              onSelect={(step) => {
                selectCourse(step)
                setCurrentQuestion(0)
                closeFeedback()
                toggle()
              }}
              showCloseIcon={true}
              toggle={toggle}
              handleOpenFeedback={() => {
                handleOpenFeedback()
                setCurrentStep(-1)
                toggle()
              }}
              feedbackOpened={feedbackOpened}
            />
          </DrawerComponent>
          <IconButton
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.7) !important',
              color: 'white',
              '&:hover': { background: 'rgba(0, 0, 0, 0.8)' },
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 999,
            }}
            onClick={toggle}
          >
            <List />
          </IconButton>
        </>
      )}
      {feedbackOpened ? (
        <Container height="100%" p={[3, 3, 3]} flexDirection={['column']} width="100%" maxWidth={'1400px'} style={{ background: theme.colors.linearGradient.lightBottomTop }}>
          <CourseFeedback direction={direction} content={content} courseId={courseId} />
        </Container>
      ) : (
        <Flex gap={8} p={[3, 3, 3]} flexDirection={['column']} height="100%" width="100%" maxWidth={'1400px'} style={{ background: theme.colors.linearGradient.lightBottomTop }}>
          {selectedJourney?.type === 'video' && (
            <CenterColumn width="100%" height={fetchingSignedUrl ? '100%' : 'auto'} justifyContent="center" alignItems="center">
              {fetchingSignedUrl && <Spinner />}
              {signedUrl && (
                <>
                  <CourseVideo
                    getSignedUrl={() => {
                      refetchSignedUrl()
                    }}
                    content={content}
                    currentStep={currentStep}
                    link={signedUrl?.url}
                    poster={data?.thumbnail as string}
                    onNext={handleNext}
                    journey={data?.journey}
                    isLastCourse={currentStep === Number(data?.journey.length ?? 0) - 1}
                    onSelectCourse={selectCourse}
                    selectedJourney={selectedJourney}
                    updateVideoTime={updateVideoTime}
                    completeLesson={() => {
                      completeLesson({
                        course_id: Number(data?.id),
                        journey_uid: selectedJourney?.uid,
                      })
                    }}
                    disableRightClick
                    direction={direction}
                  />
                  {belowLg && (
                    <CenterColumn mt={16} gap={8} direction={direction} width="100%" height="auto">
                      <Text variant="heading2">{selectedJourney?.title}</Text>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: selectedJourney?.description?.replaceAll('\n', '<br/>') ?? '',
                        }}
                        variant="body1"
                        color="textSecondary"
                      />
                    </CenterColumn>
                  )}
                </>
              )}
            </CenterColumn>
          )}
          {selectedJourney?.type === 'mcq' && (
            <CenterColumn position="relative">
              <MCQ
                currentQuestion={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
                completeLesson={() => {
                  completeLesson({
                    course_id: Number(data?.id),
                    journey_uid: selectedJourney?.uid,
                  })
                }}
                course_id={Number(data?.id)}
                journey_index={currentStep}
                mcqJourney={selectedJourney}
                onNext={handleNext}
                content={content}
                direction={direction}
              />
            </CenterColumn>
          )}
          {selectedJourney?.type === 'document' && (
            <DownloadableSection
              direction={direction}
              completeLesson={() => {
                completeLesson({
                  course_id: Number(data?.id),
                  journey_uid: selectedJourney?.uid,
                })
              }}
              course_id={data?.id}
              journey={selectedJourney}
              onNext={handleNext}
              isLastLesson={isLastLesson}
              content={content}
            />
          )}
        </Flex>
      )}

      {!belowLg && (
        <Sidebar
          content={content}
          progress={progress}
          currentStep={currentStep}
          data={data}
          direction={direction}
          onSelect={(step) => {
            selectCourse(step)
            setCurrentQuestion(0)
            closeFeedback()
          }}
          handleOpenFeedback={() => {
            handleOpenFeedback()
            setCurrentStep(-1)
          }}
          feedbackOpened={feedbackOpened}
        />
      )}
    </Flex>
  )
}

const JourneyStep = styled(Flex)<{ active: boolean; completed: boolean }>`
  padding: 12px;
  width: 100%;
  // min-width: 400px;
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) => (props.active ? theme.colors.purple[100] : 'transparent')};
  border: 1px solid ${(props) => (props.active ? theme.colors.purple[200] : 'transparent')};
  gap: 12px;
  // align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;

  &:hover {
    background: ${(props) => !props.active && theme.colors.gray[100]};
  }
`

const StepIcon = ({ type, completed, active }: any) => {
  const iconMap = {
    video: <Videocam />,
    mcq: <Quiz />,
    document: <DocumentScanner />,
  }

  return (
    <IconButton sx={{ p: 0, height: 'max-content' }} size="medium" color={completed ? 'success' : active ? 'info' : 'default'}>
      {iconMap[type as keyof typeof iconMap]}
    </IconButton>
  )
}

type SidebarProps = {
  progress: number
  direction: Direction
  content: CourseContentType | undefined
  data: Course | undefined
  onSelect: (step: CourseJourneyItem) => void
  currentStep: number
  toggle?: () => void
  handleOpenFeedback: () => void
  showCloseIcon?: boolean
  feedbackOpened: boolean
}
const Sidebar = ({ progress, content, data, direction, onSelect, currentStep, toggle, handleOpenFeedback, showCloseIcon, feedbackOpened }: SidebarProps) => {
  return (
    <Container
      justifyContent={['flex-end']}
      alignItems="flex-start"
      px={[2, 2, 3, 4]}
      py={[2, 2, 3]}
      height="auto"
      maxHeight={'100%'}
      flexGrow={1}
      minWidth={['100%', '320px', '100%', '400px']}
      bg="white"
      borderLeft="1px solid"
      borderColor="gray.100"
      overflowY="scroll"
    >
      <CenterColumn direction={direction} gap={16} width="100%">
        <CenterRow direction="ltr" justifyContent="flex-end" width="100%">
          {showCloseIcon && (
            <IconButton
              sx={{
                width: '30px',
                height: '30px',
                p: '5px',
              }}
              color="secondary"
              onClick={toggle}
            >
              <Close />
            </IconButton>
          )}
        </CenterRow>
        <Text variant="heading4" mb={2}>
          {content?.courseLessons}
        </Text>

        {/* Progress Overview */}
        <CenterColumn gap={8} width="100%">
          <CenterRow width="100%" justifyContent="space-between">
            <Text>{content?.progress}</Text>
            <Text variant="body2" color="gray.600">
              {Math.floor(progress)}% {content?.completed}
            </Text>
          </CenterRow>
          <LinearProgress
            value={progress}
            color="primary"
            sx={{
              borderRadius: '20px',
              height: '8px',
              transform: direction === 'rtl' ? 'scaleX(-1)' : 'none',
            }}
            variant="determinate"
          />
        </CenterColumn>

        {/* Journey Steps List */}
        <CenterColumn gap={8} width="100%">
          {data?.journey?.map((step, index) => (
            <JourneyStep key={step.uid} onClick={() => onSelect(step)} active={index === currentStep} completed={step.completed}>
              <CenterRow gap={8}>
                <StepIcon type={step.type} completed={step.completed} active={index === currentStep} />
                <Box>
                  <Text variant="body2" fontWeight={index === currentStep ? 'bold' : 'normal'}>
                    {step.title}
                  </Text>
                  <Text variant="body3" color="black.500">
                    {content?.[step.type as keyof typeof content]?.toUpperCase()}
                  </Text>
                </Box>
              </CenterRow>
              {step.completed && <Check color="success" />}
            </JourneyStep>
          ))}

          <JourneyStep onClick={handleOpenFeedback} active={feedbackOpened} completed={false}>
            <CenterRow gap={8}>
              <Star color="warning" />
              <Box>
                <Text variant="body2" fontWeight={'normal'}>
                  {content?.courseFeedback}
                </Text>
                <Text variant="body3" color="black.500">
                  {content?.shareYourThoughts}
                </Text>
              </Box>
            </CenterRow>
          </JourneyStep>
        </CenterColumn>
      </CenterColumn>
    </Container>
  )
}
