// Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import queryString from 'query-string'
// Routing
import { Link, useLocation } from 'react-router-dom'
// Actions
import { authActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextFieldPassword, Loader, SaveButton } from '../../GeneralComponents'
// Constants
import { PASSWORD_REGEX } from '../../../constants'
import { authInstance, handleResetPassword } from 'utils/utils'
import { Button, CenterColumn } from 'components/Core'
import { Spinner } from 'components/ChatSystem/Common/Spinner'

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter password')
    .matches(PASSWORD_REGEX, 'Your password should be minimum eight characters, contain at least one letter, one number and special character'),
  confirm_password: Yup.string()
    .required('Please repeat password')
    .matches(PASSWORD_REGEX, 'Your password should be minimum eight characters, contain at least one letter, one number and special character')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const PasswordReset = () => {
  const dispatch = useDispatch()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  // const message = useSelector((state) => state.general.message)
  const user = useSelector((state) => state.auth.user)

  const { search } = useLocation()

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  })

  // useEffect(() => {
  //   const { token } = queryString.parse(search)

  //   if (!user.is_password_changed && token) {
  //     const data = {
  //       token,
  //     }
  //     dispatch(authActions.sendResetPasswordValidToken(data))
  //   }
  // }, [])

  const onFormSubmit = (values) => {
    const { oobCode, apiKey, continueUrl, lang } = queryString.parse(search)
    setLoading(true)
    handleResetPassword({
      actionCode: oobCode,
      continueUrl,
      lang,
      newPassword: values.password,
      onSuccess: async () => {
        setSuccess(true)
        const token = await authInstance.currentUser.getIdToken()
        const data = {
          token,
          new_password: values.password,
        }
        dispatch(authActions.sendResetPassword(data))
      },
      setLoading,
    })
  }

  return (
    <section className={Styles.password}>
      {success ? (
        <div className={Styles.password_container}>
          <div className={Styles.success_message}>
            <span />
            <span>Your password has been reset successfully!</span>
          </div>
          <div className={Styles.clarification}>
            <span>{'Login to your account'}</span>
            <Link to="/login" className={Styles.login_btn}>
              {'Log in'}
            </Link>
          </div>
        </div>
      ) : (
        <div className={Styles.password_container}>
          <h3 className={Styles.password_caption}>{'Reset password'}</h3>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <CenterColumn minWidth={['100%', '100%', '440px']} gap={10}>
              <TextFieldPassword
                label={'Create new password'}
                class={Styles.form_item}
                placeholder={'Enter new password'}
                error={errors?.password}
                register={register('password')}
              />
              <TextFieldPassword label={'Confirm password'} placeholder={'Repeat your password'} error={errors?.confirm_password} register={register('confirm_password')} />
              <Button width="100%">{loading ? <Spinner width="25px" /> : 'Submit'}</Button>
            </CenterColumn>
          </form>
        </div>
      )}
    </section>
  )
}
