import { SwipeableDrawer } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  open: boolean
  toggleDrawer: () => void
}
export function DrawerComponent({ children, toggleDrawer, open }: Props) {
  return (
    <SwipeableDrawer onOpen={toggleDrawer} anchor="right" open={open} onClose={toggleDrawer}>
      {children}
    </SwipeableDrawer>
  )
}
