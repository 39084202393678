import { EMAIL_REGEX, PASSWORD_REGEX } from 'constants/index'
import { TFN } from 'utils/types/types'
import * as yup from 'yup'

export const signupValidation = (t: TFN) =>
  yup.object().shape({
    full_name: yup
      .string()
      .required(t('validation:required'))
      .min(3, t('validation:atLeastThreeCharLong'))
      .max(30, t('validation:noMoreThan30CharLong'))
      .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, t('validation:nameShouldNotContainNumber')),
    email: yup.string().required(t('validation:required')).matches(EMAIL_REGEX, t('validation:invalidEmail')),
    password: yup.string().required('Password is required!').matches(PASSWORD_REGEX, t('validation:passwordValidation')),
    confirm_password: yup
      .string()
      .required('Confirm Password is required!')
      .oneOf([yup.ref('password')], t('validation:passwordsMustMatch')),
  })
