// Core
import { useState } from 'react'
import { string, bool, func } from 'prop-types'
// Styles
import Styles from './styles.module.scss'
// Mui Components
import Switch from '@mui/material/Switch'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user_updated.svg'
import { ReactComponent as ClockIcon } from '../../../../theme/assets/icons/clock.svg'
import { ReactComponent as FileIcon } from '../../../../theme/assets/icons/file_new.svg'
// Helpers
import { capitalizeWord, getFormatedLocalTime } from '../../../../helpers'

const CustomeMUITheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#8450A0',
            '+ .MuiSwitch-track': {
              backgroundColor: '#8450A0',
            },
          },
        },
      },
    },
  },
})

export const ContactDetails = ({ profile_pic, full_name, user_type, online, local_time, is_notifications_on, onClose, attachments, openDocumentPopup }) => {
  const [checked, setChecked] = useState(is_notifications_on || false)
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false)

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked)
  }

  return (
    <ThemeProvider theme={CustomeMUITheme}>
      <div className={Styles.contactDetails}>
        <div className={Styles.contactDetails__details}>
          <div className={Styles.contactDetails__details__avatar}>
            {profile_pic ? (
              <div className={Styles.contactDetails__details__avatar__placeholder}>
                <img src={profile_pic} alt="profile_pic" />
              </div>
            ) : (
              <div className={Styles.contactDetails__details__avatar__placeholder}>
                <UserIcon />
              </div>
            )}
            <div className={Styles.contactDetails__details__avatar__closeBtn} onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <p className={Styles.contactDetails__details__name}>{full_name}</p>
          <p className={Styles.contactDetails__details__position}>{capitalizeWord(user_type)}</p>
          <div className={Styles.contactDetails__details__status}>
            <div className={Styles.contactDetails__details__status__icon}>
              <div className={online ? Styles.contactDetails__details__status__icon__online : Styles.contactDetails__details__status__icon__offline}></div>
            </div>
            <p>{online ? 'Online' : 'Away'}</p>
          </div>
          <div className={Styles.contactDetails__details__localTime}>
            <div className={Styles.contactDetails__details__status__icon}>
              <ClockIcon />
            </div>
            <p>{getFormatedLocalTime(local_time)} local time</p>
          </div>
        </div>
        <div className={Styles.contactDetails__notification}>
          <p>Notifications</p>
          <Switch checked={checked} onChange={handleChangeSwitch} color="secondary" inputProps={{ 'aria-label': 'controlled' }} />
        </div>

        <div className={Styles.contactDetails__attachments}>
          {attachments?.map((attachment) => {
            return (
              <div key={attachment.id}>
                {attachment?.extension === 'png' ? (
                  <img
                    src={attachment.file}
                    className={Styles.contactDetails__attachments__element_preview}
                    alt="chat_attacment"
                    onClick={() => openDocumentPopup(attachment.file)}
                  />
                ) : (
                  <div className={Styles.contactDetails__attachments__element_preview__default}>
                    <FileIcon onClick={() => openDocumentPopup(attachment.file)} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </ThemeProvider>
  )
}

ContactDetails.propTypes = {
  profile_pic: string,
  full_name: string,
  user_type: string,
  online: bool,
  local_time: string,
  is_notifications_on: bool,
  onClose: func,
  currentUserType: string,
}
