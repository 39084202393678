import { Modal } from '@mui/material'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Box, Flex, Text, Button } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'

interface ConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
  confirmText?: string
  cancelText?: string
  isLoading?: boolean
  hideConfirmationButton?: boolean
  hideCancelButton?: boolean
}

export const ConfirmationModal = ({
  hideCancelButton,
  hideConfirmationButton,
  open,
  onClose,
  onConfirm,
  isLoading,
  title,
  message,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: ConfirmationModalProps) => {
  const { direction } = useGetLanguage()
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        direction={direction}
        position={'absolute'}
        top={'50%'}
        left={'50%'}
        backgroundColor={'white'}
        borderRadius={'8px'}
        boxShadow={'normal'}
        p={4}
        minWidth={300}
        maxWidth={500}
        style={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Flex flexDirection="column" gap={3}>
          <Text variant="heading4">{title}</Text>
          <Text>{message}</Text>

          <Flex justifyContent="flex-end" gap={2} mt={2}>
            {!hideCancelButton && (
              <Button variant="primary" onClick={onClose}>
                {cancelText}
              </Button>
            )}
            {!hideConfirmationButton && (
              <Button
                variant="secondary"
                onClick={() => {
                  onConfirm()
                  onClose()
                }}
              >
                {isLoading ? <Spinner width="25px" /> : confirmText}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </Modal>
  )
}
