import styled from 'styled-components'
import { variant } from 'styled-system'
import { commonComposes } from '../common'
import { ButtonProps } from './types'
import { theme } from 'utils/theme'

export const Button = styled('button')<ButtonProps>(
  (props) => ({
    fontFamily: 'Poppins_Medium_500, sans-serif;',
    cursor: 'pointer',
    '&:hover': props._hover ?? {
      opacity: 0.8,
      transition: 'all 0.2s ease-in-out',
    },
    '&:disabled': {
      opacity: 0.3,
      cursor: 'not-allowed',
    },
  }),
  variant({
    variants: {
      primary: {
        width: 'max-content',
        fontWeight: 500,
        fontSize: '16px',
        fontFamily: 'Poppins_Medium_500, sans-serif',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        backgroundColor: theme.colors.typePurpure[200],
        borderRadius: '50px',
        padding: '10px 20px',
        border: 'none',
      },
      ghost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.typePurpure[200],
        backgroundColor: 'transparent',
        borderRadius: '50px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
      },
      secondary: {
        width: 'max-content',
        fontWeight: 500,
        fontSize: '16px',
        fontFamily: 'Poppins_Medium_500, sans-serif',
        color: theme.colors.typePurpure[200],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        backgroundColor: 'white',
        borderRadius: '50px',
        padding: '10px 20px',
        border: `1px solid ${theme.colors.typePurpure[200]}`,
        // '&:hover': {
        //   backgroundColor: theme.colors.typePurpure[100],
        //   color: 'white',
        // },
      },
      danger: {
        width: 'max-content',
        fontWeight: 500,
        fontSize: '16px',
        fontFamily: 'Poppins_Medium_500, sans-serif',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        borderRadius: '50px',
        padding: '10px 20px',
        border: 'none',
        backgroundColor: '#FFE4E4',
        color: '#E51616',
      },
    },
  }),
  commonComposes(),
)

Button.defaultProps = {
  variant: 'primary',
}
