import { Box, Button, CenterColumn, CenterRow, Flex, Text } from 'components/Core'
// import { pdfjs } from 'react-pdf'
import { Download, Description } from '@mui/icons-material'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { useEffect, useState } from 'react'
import { CourseJourneyItem } from 'utils/types/types'
import { useGetDocumentsForDocumentSectionQuery } from './service'
import { useDownload } from 'hooks/useDownload'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { AnimatePresence, motion } from 'framer-motion'
import { Direction } from 'components/Core/common/types'
import type { CourseContentType } from 'navigations/locale/course_english'
// At the top of the file, update the worker configuration
// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

interface DocumentViewerProps {
  journey: CourseJourneyItem | undefined
  course_id: number | undefined
  completeLesson: () => void
  onNext: () => void
  isLastLesson: boolean
  content: CourseContentType
  direction: Direction
}

export const DownloadableSection = ({ journey, content, course_id, onNext, isLastLesson, completeLesson, direction }: DocumentViewerProps) => {
  const [downloadingIndex, setDownloadingIndex] = useState<number | null>(null)
  const { data, isLoading } = useGetDocumentsForDocumentSectionQuery({
    course_id,
    journey_uid: journey?.uid,
  })
  const { download, downloading } = useDownload()

  useEffect(() => {
    return () => {
      completeLesson()
    }
  }, [])

  return (
    <DocumentContainer direction={direction}>
      <CenterColumn gap={4} width="100%">
        <Text variant="heading4">{journey?.title}</Text>
        <Text variant="body2" color="gray.600">
          {journey?.description}
        </Text>

        {isLoading ? (
          <Spinner />
        ) : (
          <DocumentList>
            {data?.map((doc, index) => (
              <AnimatePresence key={index}>
                <DocumentCard initial={{ opacity: 0, y: 20 + index * 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 - index * 10 }}>
                  <Flex alignItems="center" gap={2}>
                    <CenterColumn gap={1}>
                      <CenterRow gap={1}>
                        <Description sx={{ color: theme.colors.black[500] }} />
                        <Text variant="heading4">{doc.title}</Text>
                      </CenterRow>
                      {doc.description && (
                        <Text maxWidth={['700px']} variant="body3" color="gray.600" dangerouslySetInnerHTML={{ __html: doc.description.replaceAll('\n', '<br />') }} />
                      )}
                    </CenterColumn>
                  </Flex>

                  <Flex gap={2}>
                    <ActionButton
                      onClick={() => {
                        setDownloadingIndex(index)
                        download(doc?.url, doc?.file_name)
                      }}
                    >
                      <>
                        {downloading && index === downloadingIndex ? <Spinner width="25px" /> : <Download />}
                        <Text>{content.download}</Text>
                      </>
                    </ActionButton>
                  </Flex>
                </DocumentCard>
              </AnimatePresence>
            ))}
          </DocumentList>
        )}

        {/* {selectedDoc && (
          <PreviewContainer px={[3, 3, 24]}>
            <Text variant="heading4" mb={3}>
              {selectedDoc.title}
            </Text>

            <Document file={selectedDoc?.url} onLoadSuccess={({ numPages }: any) => setNumPages(numPages)} loading={<Text>Loading document...</Text>}>
              {Array.from(new Array(Math.min(numPages, 3)), (_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={600} renderTextLayer={false} renderAnnotationLayer={false} />
              ))}
              {numPages > 3 && (
                <Text variant="body2" color="gray.600" mt={3}>
                  Download to view all {numPages} pages
                </Text>
              )}
            </Document>
          </PreviewContainer>
        )} */}
        {!isLoading! && (
          <CenterRow justifyContent="flex-end">
            <Button onClick={onNext}>{isLastLesson ? content.finish : content.nextLesson}</Button>
          </CenterRow>
        )}
      </CenterColumn>
    </DocumentContainer>
  )
}

const DocumentContainer = styled(Box)`
  padding: 16px;
  border-radius: 12px;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
`

const DocumentList = styled(CenterColumn)`
  gap: 16px;
  width: 100%;
`

const DocumentCard = styled(motion.div)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: ${theme.colors.gray[100]};
  transition: all 0.2s ease;

  &:hover {
    background: ${theme.colors.gray[100]};
  }
`

const ActionButton = styled(Flex)`
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  background: ${theme.colors.purple[100]};
  color: ${theme.colors.typePurpure[300]};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${theme.colors.purple[200]};
  }
`

const PreviewContainer = styled(Box)`
  margin-top: 24px;
  border-radius: 8px;
  background: ${theme.colors.gray[100]};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  max-height: 500px;
  padding-bottom: 150px;
  gap: 16px;
`
