import { Delete, Visibility, VisibilityOff } from '@mui/icons-material'
import { Autocomplete, IconButton, Modal, Switch, TextField } from '@mui/material'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Button, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { objectiveTypes, qualitativeSelectOptions } from 'constants/index'
import { useUpdateNoteVisibilityMutation } from 'pages/ShardPage/ParentTask/slice/services'
import { ObjectivesSelector } from 'pages/ShardPage/TreatmentPlan/ObjectiveSelector'
import { useGetObjectivesQuery, useGetProgressQuery } from 'pages/ShardPage/TreatmentPlan/slice/services'
import type { Objective, Progress } from 'pages/ShardPage/TreatmentPlan/slice/types'
import { useEffect, useMemo, useState } from 'react'
import { CloseIcon } from 'theme/assets/icons'
import { useProgressForm } from './useProgressForm'
import { ConfirmationModal } from 'components/shared/ConfimationModal'
import { useDisclosure } from 'hooks/useDisclosure'
import { MuiTextField } from 'components/Core/TextArea/MuiTextField'

type Props = {
  open: boolean
  onClose: () => void
  profile_id: number
  session_id: number
}
type UpdateProgressPayload = { index: number; value: Partial<Progress> }
export const ProgressUpdateModal = ({ onClose, open, profile_id, session_id }: Props) => {
  const [disableButton, setDisableButton] = useState(true)
  const confirmationModalController = useDisclosure()
  const [deleteIndex, setDeleteIndex] = useState<number>()
  const [changingNoteIndex, setChangingNoteIndex] = useState<number>()
  const [progresses, setProgresses] = useState<Partial<Progress | null>[]>([])
  const [changingSwitchIndex, setChangingSwitchIndex] = useState<number>()
  const { data: objectives } = useGetObjectivesQuery(profile_id, { refetchOnMountOrArgChange: true, skip: !open })

  const { data, isLoading: fetchingProgress } = useGetProgressQuery(session_id, {
    skip: !open,
  })
  useEffect(() => {
    // sort by objective title
    const newData = [...(data || [])]
    setProgresses(newData?.sort((a, b) => a.objective.title?.localeCompare(b.objective.title as string) as number) || [])
  }, [data, open])

  const [updateNoteVisibility, { isLoading: updatingNoteVisibility }] = useUpdateNoteVisibilityMutation()
  const updateProgress = ({ index, value }: UpdateProgressPayload) => {
    const promise = new Promise((resolve) => {
      const newProgresses = [...progresses]

      newProgresses[index] = { ...newProgresses[index], ...value }
      if (value.objective?.type === objectiveTypes.QUANTITATIVE) {
        newProgresses[index] = { ...newProgresses[index], qualitative_value: null }
      }
      if (value.objective?.type === objectiveTypes.QUALITATIVE) {
        newProgresses[index] = { ...newProgresses[index], quantitative_value: null }
      }
      setProgresses(newProgresses)
      resolve(true)
    })
    promise.then(() => {
      setValue(`progress-current-level-${index}`, value.quantitative_value, { shouldValidate: true })
      setValue(`progress-current-level-${index}`, value.quantitative_value, { shouldValidate: true })
      setValue(`progress-objective-${index}`, value.objective, { shouldValidate: true })
      setValue(`progress-note-${index}`, value.note, { shouldValidate: true })
    })
  }
  const selectedObjectives = useMemo(() => progresses.map((progress) => progress?.objective?.id), [progresses])
  const closeModal = () => {
    onClose()
    setDisableButton(true)
    setChangingNoteIndex(undefined)
    setProgresses([])
  }
  const {
    control,
    register,
    onSubmit,
    getValues,
    setValue,
    isLoading,
    formState: { errors },
    reset,
  } = useProgressForm({
    progresses,
    session_id,
    handleClose: closeModal,
  })
  const handleClose = () => {
    onClose()
    setDisableButton(true)
    setChangingNoteIndex(undefined)
    setProgresses([])
    reset()
  }
  const removeProgress = () => {
    const newProgresses = [...progresses]
    newProgresses[deleteIndex as number] = null
    setProgresses(newProgresses)
  }
  const filteredProgress = useMemo(() => progresses.filter((progress) => progress !== null), [progresses])
  return (
    <Modal open={open} onClose={handleClose}>
      <Flex minHeight={300} flexDirection="column" gap={20} variant="modal-container">
        <CenterRow justifyContent={['space-between']}>
          <Text variant="heading3">Progress Update</Text>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CenterRow>
        {!fetchingProgress ? (
          <Flex px={[0, 0, 3]} flexDirection="column" gap={15} width="100%" maxHeight={700} overflowY="auto">
            {!fetchingProgress && filteredProgress.length === 0 && (
              <CenterRow py={20} flexDirection="column" gap={16}>
                <Text>No progress recorded yet!</Text>
                <Button
                  onClick={() => {
                    setProgresses((prev) => [...prev, {}])
                  }}
                >
                  Add Progress
                </Button>
              </CenterRow>
            )}

            {progresses?.map(
              (progress, index) =>
                progress && (
                  <Flex pt={['20px']} bg="black.100" p={3} borderRadius={1} key={index} width="100%" flexDirection="column" gap={16}>
                    {!progress.id && (
                      <CenterRow justifyContent={['flex-end']}>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setDeleteIndex(index)
                            confirmationModalController.onOpen()
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </CenterRow>
                    )}
                    <CenterColumn gap="5px">
                      <ObjectivesSelector
                        disabled={Boolean(progress.id)}
                        name={`progress-objective-${index}`}
                        register={register}
                        setValue={setValue}
                        objectives={objectives}
                        updateProgress={(objective: Objective) => {
                          updateProgress({
                            index,
                            value: { objective },
                          })
                        }}
                        selectedObjectives={selectedObjectives}
                        modalOpened={open}
                        objectiveValue={progress?.objective}
                        profile_id={profile_id}
                      />
                      <Text variant="error">{errors?.[`progress-objective-${index}`]?.message as string}</Text>
                    </CenterColumn>
                    {progress?.objective?.type === objectiveTypes.QUANTITATIVE && (
                      <CenterColumn gap="5px">
                        <TextField
                          disabled={Boolean(progress.id)}
                          placeholder="Current Level %"
                          value={progress?.quantitative_value}
                          type="number"
                          // {...register(`progress-current-level-${index}`)}
                          onChange={(e) => {
                            updateProgress({
                              index,
                              value: { quantitative_value: e.target.value },
                            })
                          }}
                        />
                        <Text variant="error">{errors?.[`progress-current-level-${index}`]?.message as string}</Text>
                      </CenterColumn>
                    )}
                    {progress?.objective?.type === objectiveTypes.QUALITATIVE && (
                      <CenterColumn gap="5px">
                        <Autocomplete
                          sx={{ width: '100%', backgroundColor: Boolean(progress.id) ? 'transparent' : 'white' }}
                          disabled={Boolean(progress.id)}
                          options={qualitativeSelectOptions}
                          renderOption={(props, option) => <li {...props}>{option.value}</li>}
                          getOptionLabel={(option) => option.value ?? ''}
                          onChange={(e, value) => {
                            // setValue(`progress-improvement-${index}`, value?.value, { shouldValidate: true })
                            updateProgress({
                              index,
                              value: { qualitative_value: value?.value },
                            })
                          }}
                          renderInput={(params) => <TextField {...params} placeholder="Improvement" name={`progress-improvement-${index}`} />}
                          value={{ value: progress?.qualitative_value ?? '' }}
                        />
                        <Text variant="error">{errors?.[`progress-improvement-${index}`]?.message as string}</Text>
                      </CenterColumn>
                    )}
                    <Flex gap="10px" flexDirection={['column']}>
                      <CenterRow>
                        <Switch
                          checked={progress?.note_visible_to_parent}
                          onChange={(e) => {
                            setChangingSwitchIndex(index)
                            if (progress.id) {
                              updateNoteVisibility({
                                id: progress.id,
                                value: e.target.checked,
                              })
                              updateProgress({ index, value: { note_visible_to_parent: e.target.checked } })
                            } else {
                              updateProgress({ index, value: { note_visible_to_parent: e.target.checked } })
                            }
                          }}
                        />
                        {progress?.note_visible_to_parent ? (
                          updatingNoteVisibility && changingSwitchIndex === index ? (
                            <Spinner width="20px" />
                          ) : (
                            <CenterRow gap="5px">
                              <Visibility />
                              <Text variant="light">Note is now visible to the parent</Text>
                            </CenterRow>
                          )
                        ) : updatingNoteVisibility && changingSwitchIndex === index ? (
                          <Spinner width="20px" />
                        ) : (
                          <CenterRow gap="5px">
                            <VisibilityOff />
                            <Text variant="light">Note is now hidden from parent</Text>
                          </CenterRow>
                        )}
                      </CenterRow>
                      <MuiTextField
                        // disabled={changingNoteIndex !== index && Boolean(progress.id) && Boolean(progress.note)}
                        multiline
                        maxRows={4}
                        maxLength={500}
                        showCharacterCounter
                        placeholder="Note"
                        value={progress?.note}
                        // {...register(`progress-note-${index}`)}
                        onChange={(e) => {
                          if (e.target.value) {
                            setDisableButton(false)
                          } else {
                            if (progress.id) {
                              setDisableButton(true)
                            }
                          }
                          setChangingNoteIndex(index)
                          updateProgress({ index, value: { note: e.target.value } })
                          // setValue(`progress-note-${index}`, e.target.value, { shouldValidate: true })
                        }}
                        helperText={errors?.[`progress-note-${index}`]?.message as string}
                        error={errors?.[`progress-note-${index}`] ? true : false}
                      />
                    </Flex>
                  </Flex>
                ),
            )}
            {filteredProgress.length && filteredProgress.length < Number(objectives?.length) ? (
              <CenterRow>
                <Button
                  onClick={() => {
                    setProgresses((prev) => [...prev, {}])
                    setDisableButton(false)
                  }}
                  variant="secondary"
                >
                  Add another progress
                </Button>
              </CenterRow>
            ) : null}
            {filteredProgress.length ? (
              <CenterRow gap={10} justifyContent={['flex-end']}>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button onClick={onSubmit} disabled={Boolean(data?.length) && disableButton}>
                  {isLoading ? <Spinner width="25px" /> : data?.length ? 'Update' : 'Create'}
                </Button>
              </CenterRow>
            ) : null}
          </Flex>
        ) : (
          <Container flexGrow={1}>
            <Spinner width="30px" />
          </Container>
        )}
        <ConfirmationModal title="Delete Progress?" message="Are you sure want to delete this progress?" onConfirm={removeProgress} {...confirmationModalController} />
      </Flex>
    </Modal>
  )
}
