// Constants
import { apiPath } from '../constants'
// Instruments
import { fetchRequest } from './fetch'
import { request } from './axios'

export const settingsApi = {
  changeUserInfo: (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/users/${data.id}/update/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
      data: JSON.stringify(data.update),
    })
  },
  changeUserLanguages: (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/advisors/${data.id}/update/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
      data: JSON.stringify(data.update),
    })
  },
  changeUserAvatar: (data) => {
    return fetchRequest(`${apiPath}/api/users/upload-pic/`, {
      method: 'POST',
      // headers: {
      //   Authorization: `Token ${data.token}`,
      // },
      body: data.update,
    })
  },

  deleteUserAvatar: (data) => {
    return fetchRequest(`${apiPath}/api/users/remove-pic/`, {
      method: 'DELETE',
      // headers: {
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  uploadFiles: (data) => {
    const formData = new FormData()
    formData.append('file_type', data.type)
    data.files.map((file) => formData.append('files', file))

    return request({
      method: 'POST',
      url: `${apiPath}/api/therapists/documents/upload/`,
      headers: {
        'Content-Type': 'multipart/form-data',
        // Authorization: `Token ${data.token}`,
      },
      data: formData,
    })
  },
  deleteFiles: (data) => {
    return request({
      method: 'DELETE',
      url: `${apiPath}/api/therapists/documents/${data.id}/delete/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  secureUploadFiles: (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/api/therapists/documents/secure-upload/`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data.payload,
    })
  },
}
