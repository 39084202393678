//Core
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//Images
import { ReactComponent as ClockImage } from '../../theme/assets/images/clock_image.svg'
import { ReactComponent as ErrorImage } from '../../theme/assets/images/something_went_wrong_image.svg'
//Styles
import Styles from './styles.module.scss'
//Actions
import { verificationActions } from '../../actions/verificationActions'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { authInstance } from 'utils/utils'
import { Box, Container, Text } from 'components/Core'

export const Verification = () => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleSignIn = () => {
    // Confirm the link is a sign-in with email link.
    setLoading(true)
    if (isSignInWithEmailLink(authInstance, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(authInstance, email, window.location.href)
        .then(async (result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn')
          const token = await result.user.getIdToken()
          dispatch(verificationActions.verifyLink({ token }))
          // You can access the new user by importing getAdditionalUserInfo
          // and calling it with result:
          // getAdditionalUserInfo(result)
          // You can access the user's profile via:
          // getAdditionalUserInfo(result)?.profile
          // You can check if the user is new or existing:
          // getAdditionalUserInfo(result)?.isNewUser
        })
        .catch((error) => {
          setError(true)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  const dispatch = useDispatch()

  const status = useSelector((state) => state.verification.responseStatus)
  const createdToken = useSelector((state) => state.verification.createdToken)

  useEffect(() => {
    // if (token) {
    //   dispatch(verificationActions.verifyLink({ token }))
    // } else if (createdToken) {
    //   dispatch(verificationActions.verifyLink({ token: createdToken }))
    // }
    handleSignIn()
  }, [])
  return (
    <div className={Styles.main}>
      {error || status !== 0 ? (
        <Container flexDirection="column">
          <div className={Styles.text_error}>
            <h3>Someting went wrong</h3>
            <p>Please try again</p>
          </div>
          <ErrorImage />
        </Container>
      ) : (
        loading && (
          <div className={Styles.text}>
            <Text textAlign="center" variant="heading3">
              Almost there
            </Text>
            <Text textAlign="center">Please wait a second</Text>
            <ClockImage />
          </div>
        )
      )}
    </div>
  )
}
