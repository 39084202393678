// Core
// Routing
import { Routes, Route, Outlet, useLocation } from 'react-router-dom'
// Components
import { Main } from '../components'
// Pages
import {
  AdminChatPage,
  AdminParentProfilePage,
  AdvisorApplicationsPage,
  AdvisorAssignTherapistsPage,
  AdvisorChatPage,
  AdvisorParentsPage,
  AdvisorTherapistsPage,
  AdvisorsPage,
  Error404Page,
  ParentChatPage,
  TherapistChatPage,
  QuestionsPage,
  SummaryPage,
  TherapyPage,
  TherapistApplicationsPage,
  TherapistPatientsPage,
  ChildProfilePage,
  ParentsAdminPage,
  NotesPage,
  AdminMeetingsPage,
  SignInPage,
  ParentQuestionnaireForExistingAccount,
  AdvisorParentProfilePage,
} from '../pages'
import { AuthProvider } from 'context/AuthContext'
import { ZoomEmbedded } from 'components/ParentFlow/Therapy/ZoomPopup/ZoomEmbedded'
import { FeedbackForm } from 'pages/FeedbackForm/FeedbackForm'
import { Unauthorized } from 'pages/Unauthorized/Unauthorized'
import { ParentTreatmentPlan } from 'pages/ParentPages/ParentTreatmentPlan'
import { ParentTaskView } from 'pages/ParentPages/ParentTaskView'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import en from 'pages/ShardPage/ParentTaskAndTreatmentPlanLocale/en.json'
import ar from 'pages/ShardPage/ParentTaskAndTreatmentPlanLocale/ar.json'
import { deleteLocalStorage } from 'utils'
import { PARENT_ONBOARDING_COOKIES_NAME } from 'data/enums'
import { AdminAnalytics } from 'components/AdminPanel/AdminAnalytics/AdminAnalytics'
import { useAuth } from 'hooks/useAuth'
import { useLinkExistingUserWithPromoCodeMutation, useOpenNotificationMutation } from 'defaultLayout/slice/service'
import { useNotifications } from 'serviceWorker/useNotification'
import { Box, Button, CenterColumn, CenterRow, Text, Image } from 'components/Core'
import { usePWA } from 'serviceWorker/usePWA'
import { IconButton, Modal } from '@mui/material'
import { CloseIcon } from 'theme/assets/icons'
import { useContentBundle } from 'hooks/useContentBundle'
import modalEnglish from './locale/en.json'
import modalArabic from './locale/ar.json'
import { NewParentOnBoarding } from 'components/ParentFlow/Therapy/NewParentOnboarding/NewParentOnBoarding'
import { ChatPage } from 'pages/NewChatPage/NewChatPage'
import { Courses } from 'pages/Courses/Courses'
import { CourseDetail } from 'pages/Courses/CourseDetail'
import { CoursePaymentSuccess } from 'pages/PaymentSuccess/CoursePaymentSuccess'
import { CoursePage } from 'pages/Courses/CoursePage'
import { courseEnglish } from './locale/course_english'
import { courseArabic } from './locale/course_arabic'

export const Private = () => {
  console.log('Private')
  const { enableNotifications } = useNotifications()

  const { user } = useAuth()
  const { i18n, t } = useTranslation()
  const [notificationOpened] = useOpenNotificationMutation()

  const { pathname } = useLocation()
  useEffect(() => {
    i18n.addResourceBundle('en', 'treatmentPlanPlusTask', en)
    i18n.addResourceBundle('ar', 'treatmentPlanPlusTask', ar)
    deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING)
  }, [])

  useEffect(() => {
    if (user && user?.notification_channels?.includes('web push')) {
      enableNotifications()
    }
  }, [user, pathname])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.type === 'PLAY_NOTIFICATION_SOUND') {
          const audio = new Audio(event.data.soundUrl)
          audio.volume = 0.2
          audio.pause()
          audio.currentTime = 0
          audio.play()
          audio.onended = () => {
            audio.pause()
            audio.currentTime = 0
          }
        }
        if (event.data.type === 'GET_CURRENT_ROUTE') {
          event.ports[0].postMessage({
            type: 'CURRENT_ROUTE',
            route: window.location.href,
          })
        }
        if (event.data?.type === 'OPEN_NOTIFICATION' && event.data?.notificationState?.notificationId) {
          notificationOpened(event.data?.notificationState?.notificationId)
        }
      })

      // Client should respond with current route
    }
  }, [])

  const { handleInstallApp, isInstalled, open, handleDoNotShow, handleRemindMeLater } = usePWA()
  useContentBundle({
    arabicContent: modalArabic,
    englishContent: modalEnglish,
    key: 'private',
  })

  useContentBundle({
    arabicContent: courseArabic,
    englishContent: courseEnglish,
    key: 'course',
  })
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/session" element={<ZoomEmbedded />} />
          <Route path="/feedback" element={<FeedbackForm />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          {/* <Route path="parent-task/:parentTaskId" element={<ParentTaskDetail />} /> */}
          <Route path="profile" element={<Outlet />}>
            {user?.user_type === 'parent' && (
              <Route path="parent" element={<Outlet />}>
                <Route path="therapy" element={<TherapyPage />} />
                <Route path="treatment-plan" element={<ParentTreatmentPlan />} />
                <Route path="parent-task" element={<ParentTaskView />} />
                <Route path="courses" element={<Courses />} />
                <Route path="course/:courseId" element={<CourseDetail />} />
                <Route path="course/start/:courseId" element={<CoursePage />} />

                <Route path="therapy/add_questionnaire" element={<ParentQuestionnaireForExistingAccount isAccountExists={true} />} />
                <Route path="therapy/:childId/notes" element={<NotesPage />} />
                <Route path="chat/:profileId" element={<ChatPage />} />
                <Route path="additional-info" element={<NewParentOnBoarding />} />
              </Route>
            )}
            {user?.user_type === 'therapist' && (
              <Route path="therapist" element={<Outlet />}>
                <Route path="applications" element={<TherapistApplicationsPage />} />
                <Route path="patients" element={<TherapistPatientsPage />} />
                <Route path="patients/child/:profileId" element={<ChildProfilePage />} />
                <Route path="patients/child/:profileId/notes" element={<NotesPage />} />
                <Route path="chat" element={<ChatPage />} />
              </Route>
            )}
          </Route>
          <Route path="admin_panel" element={<Outlet />}>
            {user?.user_type === 'advisor' && (
              <Route path="advisor" element={<Outlet />}>
                <Route path="applications" element={<AdvisorApplicationsPage />} />
                <Route path="chat" element={<ChatPage />} />
                <Route path="therapists" element={<AdvisorTherapistsPage />} />
                <Route path="profiles" element={<AdvisorParentsPage />} />
                <Route path="profiles/assign/:profileId" element={<AdvisorAssignTherapistsPage />} />
                <Route path="applications/:profileId" element={<AdvisorParentProfilePage />} />
                <Route path="applications/onboarding_children" element={<QuestionsPage />} />
                <Route path="applications/onboarding_children/summary" element={<SummaryPage />} />
              </Route>
            )}
            {user?.user_type === 'admin' && (
              <Route path="admin" element={<Outlet />}>
                <Route path="advisors" element={<AdvisorsPage />} />
                <Route path="therapists" element={<AdvisorTherapistsPage />} />
                <Route path="parents" element={<ParentsAdminPage />} />
                <Route path="parents/:childId" element={<AdminParentProfilePage />} />
                <Route path="parents/:childId/notes" element={<NotesPage />} />
                <Route path="chat" element={<ChatPage />} />
                <Route path="analytics" element={<AdminAnalytics />} />
                <Route path="meetings" element={<AdminMeetingsPage />} />
              </Route>
            )}
            {/* {user?.user?.user_type === 'supervisor' && <Route path="admin/parents" element={<ParentsPage />} />}
            {user?.user?.user_type === 'supervisor' && (
              <>
                <Route path="children/therapist_info" element={<ChildrenHistoryTherapistDetailsPage />} />
                <Route path="children/history" element={<ChildViewHistoryPage />} />
              </>
            )}
            {user?.user?.user_type === 'supervisor' && <Route path="advisors" element={<AdvisorsPage />} />} */}
          </Route>
          {/* <Route path="payment" element={<PaymentPage />} /> */}
        </Route>
        <Route path="*" element={<Error404Page />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/course-payment-success" element={<CoursePaymentSuccess />} />
      </Routes>
      <Modal open={open} onClose={handleRemindMeLater}>
        <CenterColumn gap={20} variant="modal-container">
          <CenterRow justifyContent="space-between" width="100%">
            <Text variant="heading3">{t('private:installApp')}</Text>
            <IconButton onClick={handleRemindMeLater}>
              <CloseIcon />
            </IconButton>
          </CenterRow>
          {isInstalled ? (
            <CenterColumn gap={[16]} width="100%" alignItems="center">
              <Text>{t('private:installationGuide')}</Text>
              <video
                loop
                autoPlay
                style={{
                  maxHeight: 300,

                  borderRadius: 10,
                }}
                src="/Install Guide.mp4"
              />
            </CenterColumn>
          ) : (
            <CenterColumn p={[10]} width="100%" gap={[10]} alignItems="center">
              <Box height={72}>
                <Image src="/logo72.png" />
              </Box>
              <Text textAlign={['center']} fontSize={[16, 20]}>
                {t('private:installationGuideDescription')}
              </Text>
              <Button mt={[3]} width={['100%', '100%', '60%', '50%', '30%']} onClick={handleInstallApp}>
                {t('private:install')}
              </Button>
              <Button variant="secondary" width={['100%', '100%', '60%', '50%', '30%']} onClick={handleRemindMeLater}>
                {t('private:remindMeLater')}
              </Button>
              <Button variant="ghost" width={['100%', '100%', '60%', '50%', '30%']} onClick={handleDoNotShow}>
                {t('private:doNotShowAgain')}
              </Button>
            </CenterColumn>
          )}
        </CenterColumn>
      </Modal>
    </AuthProvider>
  )
}
