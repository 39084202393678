import { Close, X } from '@mui/icons-material'
import { Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popover, Popper } from '@mui/material'
import { CenterRow, Chip, Container, Flex } from 'components/Core'
import { filterDropDownList } from 'constants/index'
import { useDisclosure } from 'hooks/useDisclosure'
import { type SyntheticEvent, useEffect, useRef } from 'react'

type Props = {
  filters: Array<string | undefined>
  setFilter: (filters: any) => void
  removeFilter: (filter: string) => void
}
export const Filters = ({ filters, setFilter, removeFilter }: Props) => {
  const { open, onOpen, onClose, toggle } = useDisclosure()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    onClose()
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      onOpen()
    } else if (event.key === 'Escape') {
      onOpen()
    }
  }
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <Flex flexDirection="column" flexWrap={['wrap']}>
      <CenterRow flexDirection={['row-reverse', 'row-reverse', 'row']} gap={10}>
        {filters?.map(
          (filter) =>
            filter && (
              <Chip
                rightAdornment={
                  <IconButton size="small" onClick={() => removeFilter(filter)}>
                    <Close sx={{ fontSize: '14px' }} />
                  </IconButton>
                }
                type="info"
                value={filter}
                key={filter}
              />
            ),
        )}
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={toggle}
          variant="outlined"
          sx={{
            textTransform: 'capitalize',
            // p: 0,
            fontSize: '15px',
          }}
        >
          Filters
        </Button>
      </CenterRow>
      <Popper sx={{ zIndex: 100 }} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  {filterDropDownList.map((item) => (
                    <MenuItem
                      key={item.value}
                      onClick={() => {
                        setFilter(item.value)
                        onClose()
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Flex>
  )
}
