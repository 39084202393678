import { request } from 'api/axios'
import { NotificationPayload, PushSubscriptionData } from './type'
import { authInstance } from 'utils/utils'

export interface NavigatorWithUserAgentData extends Navigator {
  userAgentData?: {
    brands: Array<{ brand: string; version: string }>
    platform: string
  }
}
export class NotificationService {
  private static instance: NotificationService
  private subscription: PushSubscriptionData | null = null

  private constructor() {}

  static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService()
    }
    return NotificationService.instance
  }

  async init(storeSubscriptionState?: (value: PushSubscriptionData | undefined) => void): Promise<void> {
    if (!('Notification' in window)) return

    try {
      // Check if service worker is already registered
      const registrations = await navigator.serviceWorker.getRegistrations()
      let registration

      if (registrations.length === 0) {
        registration = await navigator.serviceWorker.register('/service-worker.js')
      } else {
        registration = registrations[0]
      }

      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        const subscriptionState = await this.registerPushSubscription()
        storeSubscriptionState?.(subscriptionState)
      }
    } catch (error) {
      console.error('Notification initialization failed:', error)
    }
  }

  private async registerPushSubscription() {
    const registrations = await navigator.serviceWorker.getRegistrations()

    let registration: ServiceWorkerRegistration | null = null
    try {
      // Force activate if service worker is waiting
      if (registrations[0]?.waiting) {
        registrations[0].waiting.postMessage({ type: 'SKIP_WAITING' })
      }

      registration = await navigator.serviceWorker.ready

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
      })

      this.subscription = subscription.toJSON() as PushSubscriptionData
      await this.sendSubscriptionToServer()
      return this.subscription
    } catch (error) {
      console.error('Failed to register push subscription:', error)
    }
  }

  private async sendSubscriptionToServer(): Promise<void> {
    if (!this.subscription) return

    const nav = navigator as NavigatorWithUserAgentData
    const ua = nav.userAgent
    let browserName
    if (ua.includes('Firefox')) browserName = 'Firefox'
    else if (ua.includes('Chrome')) browserName = 'Chrome'
    else if (ua.includes('Safari')) browserName = 'Safari'
    else if (ua.includes('Edge')) browserName = 'Edge'
    else if (ua.includes('Opera')) browserName = 'Opera'
    else if (ua.includes('Brave')) browserName = 'Brave'

    const device_info = {
      userAgent: nav.userAgent,
      browserName,
      osName: nav.userAgentData?.platform || nav.platform || 'unknown',
      language: nav.language,
      deviceId: crypto.randomUUID(),
      lastUpdated: new Date().toISOString(),
    }

    await request({
      url: '/push_notification/subscribe/',
      method: 'POST',
      data: { ...this.subscription, device_info },
    })
  }

  async unsubscribe(): Promise<void> {
    try {
      const registration = await navigator.serviceWorker.ready
      const subscription = await registration.pushManager.getSubscription()
      if (subscription) {
        await subscription.unsubscribe()
      }

      this.subscription = null
    } catch (error) {
      console.log(error)
    }
  }
}
