import en from './locale/en.json'
import ar from './locale/ar.json'
import { Box, CenterRow, Container } from 'components/Core'
import { ChildInfo } from './ChildInfo'
import { useContentBundle } from 'hooks/useContentBundle'
import { englishValidationErrors } from 'locales/validations/englishValiationErrors'
import { arabicValidationErrors } from 'locales/validations/arabicValidationErrors'
import { Mandatory } from 'components/Auth/ParentOnboarding/QuestionsPartMandatory/Mandatory'
import { useQuestionnaireForm } from 'hooks/useQuestionnaireForm'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete, IconButton, LinearProgress, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectQuestionnaireData } from 'components/Auth/ParentOnboarding/QuestionsPartMandatory/slice/selectors'
import { selectChildInfo } from './slice/selectors'
import { TellUsMore } from './TellUsMore/TellUsMore'
import { ONBOARDING_STEP } from 'data/enums'
import { CALCOM_ADVISOR_CHILD_LINK } from 'constants/index'
import { useNavigate } from 'react-router-dom'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { FlowContainer } from './FlowContainer'
import { CalcomBookingView } from './CalcomBookingView'
import { RecordingConsent } from './RecordingConsent/RecordingConsent'
import { parentActions } from 'actions'
import { AnyAction } from '@reduxjs/toolkit'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { getInitials } from 'utils'
import { GetProfileResult, useGetAllParentProfilesQuery } from 'pages/ParentPages/services'
import { Spinner } from 'components/ChatSystem/Common/Spinner'

export const NewParentOnBoarding = () => {
  const dispatch = useDispatch()
  const [isFetchingTheray, setIsFetchingTheray] = useState(true)
  const { direction, isArabic } = useGetLanguage()
  const [childInfoCurrentStep, setChildInfoCurrentStep] = useState(1)
  const [metadataForRecording, setMetadataForRecording] = useState<object>({})
  const [quetionIndex, setQuestionIndex] = useState(0)

  const [answeredCount, setAnsweredCount] = useState(0)
  const questionnaireData = useSelector(selectQuestionnaireData)
  const childInfo = useSelector(selectChildInfo)
  const { t } = useContentBundle({
    englishContent: en,
    arabicContent: ar,
    key: 'parentOnboarding',
  })
  useContentBundle({
    englishContent: englishValidationErrors,
    arabicContent: arabicValidationErrors,
    key: 'validation',
  })
  const getProgressUpdate = useCallback(() => {
    let count = 0
    questionnaireData.forEach((mainStatement) => {
      if (mainStatement.main_statement_answer !== '') {
        count++
      }
    })

    if (childInfo.birth_date) {
      count += 1
    }
    if (childInfo.child_name) {
      count += 1
    }
    if (childInfo.concerns?.length) {
      count += 1
    }
    if (childInfo.languages?.length) {
      count += 1
    }

    setAnsweredCount(count)
  }, [childInfo, questionnaireData])
  useEffect(() => {
    getProgressUpdate()
  }, [questionnaireData])
  const { abTestingState, getSteps, setOnboardingStep, setAddChildStep } = useQuestionnaireForm()

  /**@ProgressBar */
  const totalCount = useMemo(() => {
    let totalCount = questionnaireData.length + 4

    return totalCount
  }, [questionnaireData])
  const progress = useMemo(() => {
    return Math.round((answeredCount / totalCount) * 100)
  }, [answeredCount, totalCount])

  const navigate = useNavigate()
  const { assignedAdvisor, allProfiles } = useSelector((state: any) => state.parent)
  useEffect(() => {
    // @ts-ignore
    dispatch(parentActions.getTherapyInfo(setIsFetchingTheray) as unknown as AnyAction)
  }, [])

  useEffect(() => {
    if (allProfiles?.length && !assignedAdvisor && !isFetchingTheray) {
      setOnboardingStep('book_advisory_call')
    } else {
      setOnboardingStep('add_child')
    }
  }, [allProfiles, assignedAdvisor, isFetchingTheray])
  const increaseQuestionIndex = () => {
    setQuestionIndex(quetionIndex + 1)
  }
  const decreaseQuestionIndex = () => {
    setQuestionIndex(quetionIndex - 1)
  }

  return (
    <Container
      width="100%"
      bg="white"
      overflowY="scroll"
      justifyContent={isFetchingTheray ? 'center' : 'flex-start'}
      flexDirection={['column']}
      py={[20]}
      pt={[100]}
      minHeight={'100%'}
    >
      {!isFetchingTheray ? (
        <>
          <>
            {childInfoCurrentStep === 1 && abTestingState.onboardingStep === 'add_child' && (
              <CenterRow justifyContent={isArabic ? 'flex-end' : 'flex-start'} borderRadius={10} width={['100%', '100%', '90%', '804px']} mb={30}>
                <IconButton
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  {isArabic ? <ArrowForward /> : <ArrowBack />}
                </IconButton>
              </CenterRow>
            )}
            {[ONBOARDING_STEP.ADD_CHILD, ONBOARDING_STEP.QUESTIONNAIRE].includes(abTestingState.onboardingStep) && (
              <FlowContainer mb={20}>
                <LinearProgress
                  sx={{ width: '100%', height: '6px', borderRadius: '10px', transform: isArabic ? 'scaleX(-1)' : 'none' }}
                  color="secondary"
                  variant="determinate"
                  value={(quetionIndex / totalCount) * 100}
                />
              </FlowContainer>
            )}
            {abTestingState.onboardingStep === 'add_child' && (
              <ChildInfo
                getProgressUpdate={getProgressUpdate}
                currentStep={childInfoCurrentStep}
                setCurrentStep={setChildInfoCurrentStep}
                setOnboardingStep={setOnboardingStep}
                t={t}
                increaseQuestionIndex={increaseQuestionIndex}
                decreaseQuestionIndex={decreaseQuestionIndex}
              />
            )}
            {abTestingState.onboardingStep === 'questionnaire' && (
              <Mandatory
                increaseQuestionIndex={increaseQuestionIndex}
                decreaseQuestionIndex={decreaseQuestionIndex}
                setChildInfoCurrentStep={setChildInfoCurrentStep}
                getSteps={getSteps}
                setAddChildStep={setAddChildStep}
                setOnboardingStep={setOnboardingStep}
              />
            )}
            {abTestingState.onboardingStep === 'report' && (
              <TellUsMore decreaseQuestionIndex={decreaseQuestionIndex} questionnaireData={questionnaireData} setOnboardingStep={setOnboardingStep} />
            )}
          </>
          <>
            {abTestingState.onboardingStep === 'book_advisory_call' && (
              <FlowContainer py={20} alignItems="center" width={['100%', '100%', '100%', '90%']}>
                <CenterRow flexDirection={['column']} gap={10} px={[1, 1, 2]} maxWidth={['100%', '100%', '100%', '100%', '90%', '63%']}>
                  {allProfiles.length > 1 ? <ProfileSelector /> : null}
                  <RecordingConsent direction={direction} setMetaData={setMetadataForRecording} />
                </CenterRow>
                <CalcomBookingView
                  onSuccess={() => {
                    window.location.replace('/')
                  }}
                  metadata={metadataForRecording}
                  link={CALCOM_ADVISOR_CHILD_LINK}
                />
              </FlowContainer>
            )}
          </>
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  )
}

const ProfileSelector = () => {
  const [selectedProfile, setSelectedProfile] = useState<Partial<GetProfileResult>>({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, isSuccess } = useGetAllParentProfilesQuery(null)
  const lastSelectedProfile = localStorage.getItem('lastSelectedProfile')
  const defaultValue = useMemo(() => {
    const result = data?.find((item) => String(item?.id) === lastSelectedProfile)
    return result
  }, [data, lastSelectedProfile, isSuccess])
  useEffect(() => {
    if (defaultValue) {
      setSelectedProfile(defaultValue)
    }
  }, [defaultValue])
  return (
    <CenterRow width="100%">
      <Container bg="primary.100" color="typePurpure.300" padding="16px" borderRadius=" 8px">
        {getInitials(selectedProfile?.full_name)}
      </Container>
      <Autocomplete
        options={data ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: 20,
                fontWeight: 'bold',
                ':hover': {
                  cursor: 'pointer',
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => <li {...props}>{option?.full_name ?? ''}</li>}
        getOptionLabel={(option) => (option as Partial<GetProfileResult>)?.full_name ?? ''}
        sx={{
          maxWidth: '350px',
          width: '160px',
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        }}
        onChange={(e, value) => {
          setSelectedProfile(value as GetProfileResult)
          localStorage.setItem('lastSelectedProfile', String(value?.id))
          dispatch(parentActions.getTherapyInfo() as any)
          navigate('/')
        }}
        value={selectedProfile}
        selectOnFocus={false}
        disableClearable
      />
    </CenterRow>
  )
}
