import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import type { ParentTask } from './types'
import { notify } from 'helpers'
import { ParentStatusType } from 'utils/types/types'
import { requestMessages } from 'helpers/requestMessages'
type GetParentTasksResponse = {
  count: number
  next: string | null
  previous: string | null
  results: {
    [key in ParentStatusType]: {
      count: number
      status: ParentStatusType
      tasks: Array<ParentTask>
    }
  }
}

type GetParentTaskQuery = {
  profile_id: number
  page: number
  search: string
  date_filter: string | undefined
}

interface CreateParentTaskPayload extends Partial<ParentTask> {
  profile_id: number
  objective_id: number
}

type ParentTaskCommentPayload = { id: number; comment: string }
type CommonResponse = {
  message: string
}

export const parentTaskService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    createOrUpdateParentTask: build.mutation<ParentTask, { data: CreateParentTaskPayload; isUpdate: boolean; resetState?: () => void }>({
      query: ({ data, isUpdate }) => ({
        url: isUpdate ? apiRoutes.parentTask.update : apiRoutes.parentTask.create,
        method: isUpdate ? 'PUT' : 'POST',
        data,
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
      invalidatesTags: ['get-parent-tasks', 'get-single-parent-task'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const language = localStorage.getItem('language')
          const successMessage = arg.isUpdate ? requestMessages?.updatedSuccessfully?.[language || 'en'] : requestMessages?.taskCreatedSuccessfully?.[language || 'en']
          const response = await queryFulfilled
          if (response.data) {
            arg.resetState?.()
            notify(successMessage, false)
          }
        } catch (error: any) {
          notify(error?.error?.data?.message, true)
        }
      },
    }),
    getParentTasks: build.query<GetParentTasksResponse, GetParentTaskQuery>({
      query: ({ page, profile_id, search, date_filter }) => ({
        url: `${apiRoutes.getParentTasks}?profile_id=${profile_id}&page=${page}&search=${search}&date_filter=${date_filter}`,
        method: 'GET',
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
      providesTags: ['get-parent-tasks'],
    }),
    getOneParentTask: build.query<ParentTask, { id: number }>({
      query: ({ id }) => ({
        url: `${apiRoutes.parentTask.getOne}?id=${id}`,
        method: 'GET',
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
      providesTags: ['get-single-parent-task'],
    }),
    commentParentTask: build.mutation<CommonResponse, { data: ParentTaskCommentPayload; afterEffect?: () => void }>({
      query: ({ data }) => ({
        url: apiRoutes.parentTask.comment,
        method: 'POST',
        data,
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
      invalidatesTags: ['get-parent-tasks', 'get-single-parent-task'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled

          if (response.data) {
            const language = localStorage.getItem('language')
            const message = requestMessages?.commentedSuccessfully?.[language || 'en']
            // notify(message, false)
            arg.afterEffect?.()
          }
        } catch (error: any) {
          notify(error?.error?.data?.message, true)
        }
      },
    }),
    deleteParentTask: build.mutation<CommonResponse, { taskId: number; onSuccess?: () => void }>({
      query: ({ taskId }) => ({
        url: `${apiRoutes.parentTask.delete}?id=${taskId}`,
        method: 'DELETE',
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
      invalidatesTags: ['get-parent-tasks'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          if (response.data) {
            notify(response.data.message, false)
            arg.onSuccess?.()
          }
        } catch (error: any) {
          notify(error?.error?.data?.message, true)
        }
      },
    }),
    updateNoteVisibility: build.mutation<string, { id: number; value: boolean }>({
      query: (data) => ({
        url: apiRoutes.updateNoteVisibility,
        data,
        method: 'PUT',
        // headers: {
        //   Authorization: `Token ${localStorage.getItem('token')}`,
        // },
      }),
      invalidatesTags: ['get-goals', 'get-progresses'],
    }),
  }),
})

export const {
  useCreateOrUpdateParentTaskMutation,
  useGetParentTasksQuery,
  useCommentParentTaskMutation,
  useGetOneParentTaskQuery,
  useDeleteParentTaskMutation,
  useUpdateNoteVisibilityMutation,
} = parentTaskService
