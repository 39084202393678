import type { Goal } from './slice/types'
import { Add, Flag, Image } from '@mui/icons-material'
import { Box, Button, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { useForm, useWatch } from 'react-hook-form'
import { useTreatmentPlanSlice } from './slice'
import { useSelector } from 'react-redux'
import { selectGoal, selectObjectives } from './slice/selectors'
import { useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { createTreatmentPlanValidation } from './validation'
import { useGetTreatmentPlansQuery } from './slice/services'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { GoalList } from './GoalList'
import { GoalForm } from './GoalForm'
import { useGoalFormController } from './slice/hooks'
import { getValue } from '@testing-library/user-event/dist/utils'
import { ProfileName } from 'components/ClientProfileComponents/GeneralInformation/ProfileName'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useTranslation } from 'react-i18next'
import { TaskNotFound } from 'components/TaskNotFound/TaskNotFound'
import { useUserTypeCheck } from 'hooks/useUserTypeCheck'

type Props = {
  profile_id: number | string
  name: string | undefined
  // To allow editing or creating treatment plan for specific user (e.g. for therapist) and prevent editing for other users (e.g. parent ot advisor which only have view access)
  allowEdit: boolean
  ProfileSelector?: () => JSX.Element
  hideGoalStatus?: boolean
}
export const TreatmentPlan = ({ profile_id, name, allowEdit, hideGoalStatus, ProfileSelector }: Props) => {
  const { direction } = useGetLanguage()
  const { t } = useTranslation()
  const { isUserParent } = useUserTypeCheck()

  const { updateGoal, resetState, setEditGoalValue } = useTreatmentPlanSlice()
  const { closeGoalForm, openGoalForm, goalFormOpened } = useGoalFormController()
  const goal = useSelector(selectGoal)
  const objectives = useSelector(selectObjectives)
  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(createTreatmentPlanValidation(objectives)),
  })
  const watchDescription = useWatch({
    name: 'description',
    control,
  })

  const restObjectiveFormState = (index: number) => {
    setValue(`objectiveType-${index}`, '')
    setValue(`objective-title-${index}`, '')
    setValue(`objective-description-${index}`, '')
    setValue(`objective-baseline-percentage-${index}`, '')
    setValue(`objective-target-percentage-${index}`, '')
  }

  const { data: allGoals, isLoading: isLoadingPlans, isFetching } = useGetTreatmentPlansQuery({ profile_id }, { skip: !profile_id })

  useEffect(() => {
    updateGoal({ description: watchDescription })
  }, [watchDescription])

  const showAddGoal = useMemo(() => {
    if (!goalFormOpened && !allGoals?.length && !isLoadingPlans && allowEdit) {
      return true
    } else {
      return false
    }
  }, [goalFormOpened, allGoals, isLoadingPlans, allowEdit])

  const showNoGoals = useMemo(() => {
    if (!isLoadingPlans && !allGoals?.length && !allowEdit) {
      return true
    } else {
      return false
    }
  }, [isLoadingPlans, allGoals])

  const profileHasGoalsCreated = useMemo(() => {
    if (goalFormOpened) {
      return false
    }
    if (allGoals && allGoals.length > 0) {
      return true
    } else {
      return false
    }
  }, [allGoals, openGoalForm])
  return (
    <Box direction={direction} p={[1, 1, '20px']} bg="white">
      <CenterRow mb={[3, 3, 0]} width="100%">
        {ProfileSelector ? <ProfileSelector /> : <ProfileName name={name} />}
      </CenterRow>
      <Container
        flexDirection={['column']}
        alignItems={goalFormOpened ? 'flex-start' : 'center'}
        justifyContent={goalFormOpened || profileHasGoalsCreated ? 'flex-start' : 'center'}
        pb={20}
        minHeight={['90vh']}
      >
        {isLoadingPlans ? (
          <Spinner width="30px" />
        ) : profileHasGoalsCreated ? (
          <Flex width="100%" flexDirection={['column']} gap={20}>
            <CenterRow borderBottom={1} pb={3} width={['100%']} justifyContent={['space-between']}>
              <Text variant="heading4">
                {t('treatmentPlanPlusTask:goals')} ({allGoals?.length})
              </Text>
              {allowEdit && (
                <Button onClick={openGoalForm} minWidth="fit-content">
                  <Add />
                  <Text>{t('treatmentPlanPlusTask:addGoal')}</Text>
                </Button>
              )}
            </CenterRow>
            {allGoals?.map((goal, index) => (
              <GoalList
                setEditGoalValue={(goal: Goal) => {
                  setEditGoalValue(goal)
                  openGoalForm()
                }}
                key={goal.id + '-' + index}
                goal={goal}
                allowEdit={allowEdit}
                hideGoalStatus={hideGoalStatus}
                isLoadingPlans={isFetching}
                profile_id={profile_id as number}
              />
            ))}
          </Flex>
        ) : (
          goalFormOpened && (
            <GoalForm
              objectives={objectives}
              control={control}
              register={register}
              errors={errors}
              goal={goal}
              open={goalFormOpened}
              restObjectiveFormState={restObjectiveFormState}
              setValue={setValue}
              watchDescription={watchDescription}
              closeGoalForm={closeGoalForm}
              resetFormState={reset}
              restReduxState={resetState}
              profile_id={profile_id}
              handleSubmit={handleSubmit}
              watch={watch}
            />
          )
        )}
        {showAddGoal && (
          <Container flexDirection={['column']} gap={10}>
            <Text variant="heading4" textAlign={'center'} fontWeight="normal">
              No goals are created yet, create one by clicking Add Goal button
            </Text>
            <Button onClick={openGoalForm}>
              <Add />
              <Text>Add Goal</Text>
            </Button>
          </Container>
        )}
        {showNoGoals && (
          <CenterColumn gap={20} alignItems={['center']}>
            <Box width={100} height={100}>
              <img width={'100%'} height={'100%'} src="https://numuw-prod-public.s3.me-central-1.amazonaws.com/email_imgs/8.png" alt="empty goals" />
            </Box>
            <CenterColumn alignItems="center" gap="10px">
              {isUserParent ? (
                <>
                  <Text color="textLightGray" textAlign="center" variant="heading4">
                    {t('treatmentPlanPlusTask:noGoalsTitle')}
                  </Text>
                  <Text textAlign="center" maxWidth="400px" color="textLightGray">
                    {t('treatmentPlanPlusTask:noGoalsSubtitle')}
                  </Text>
                </>
              ) : (
                <Text color="textLightGray" textAlign="center" variant="heading4">
                  Treatment plan is not available yet for this child!
                </Text>
              )}
            </CenterColumn>
          </CenterColumn>
        )}
      </Container>
    </Box>
  )
}
