/* eslint-disable no-useless-escape */
// Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
// Instruments
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// Actions
import { clearError, setError } from '../../../reducers/generalSlice'
import { setUser } from '../../../reducers/authSlice'
import { authActions } from '../../../actions'
import { verifyPasswordResetCode, sendPasswordResetEmail } from 'firebase/auth'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, Loader, SaveButton } from '../../GeneralComponents'
import { authInstance } from 'utils/utils'
import { notify } from 'helpers'
import { Box, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { SuccessLottie } from 'components/SuccessLottie/SuccessLottie'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import Countdown from 'react-countdown'

const schema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com')
    .required('Please enter your email address'),
})

export const PasswordRecovery = () => {
  const countdownStartDate = localStorage.getItem('countdownStartDate')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showResendButton, setShowResendButton] = useState(false)

  // const loading = useSelector((state) => state.general.loading)
  const error = useSelector((state) => state.general.error)
  const user = useSelector((state) => state.auth.user)

  const [isActiveButton, setIsActiveButton] = useState(true)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email, { shouldValidate: true })
    }
  }, [user?.email])

  const onFormSubmit = async (values) => {
    setLoading(true)
    await sendPasswordResetEmail(authInstance, values.email, {
      url: `${process.env.REACT_APP_SITE_URL}/pass-reset`,
    })
      .then(() => {
        setSuccess(true)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsActiveButton(false)
        setTimeout(() => {
          setIsActiveButton(true)
        }, 15000)
        setLoading(false)
        setShowResendButton(false)
        localStorage.setItem('countdownStartDate', Date.now())
      })
  }

  const email = useWatch({
    control,
    name: 'email',
  })

  return (
    <>
      {success ? (
        <Flex py={40} alignItems="center" height="100%" width="100%" flexDirection={['column']}>
          <Box width={[100, 150]} height={[100, 150]}>
            {loading ? <Spinner /> : <SuccessLottie />}
          </Box>
          <CenterColumn gap={10}>
            <Text textAlign={'center'} fontSize={[16, 20]} fontWeight="bold">
              Reset password email sent successfully
            </Text>
            <Text textAlign={'center'}>Please check your inbox ({email})</Text>
            <div className={Styles.clarification}>
              <CenterRow gap={'6px'} width="100%" justifyContent="center">
                <span>{'Didn’t get any link?'}</span>
                {!showResendButton && <Text fontWeight="bold">Resend In</Text>}
                {!showResendButton && (
                  <Countdown
                    autoStart
                    date={JSON.parse(countdownStartDate ?? Date.now()) + 90000}
                    onComplete={() => {
                      setShowResendButton(true)
                      localStorage.removeItem('countdownStartDate')
                    }}
                    renderer={(props) => {
                      return (
                        <CenterRow gap="2px">
                          <Text fontWeight="bold" color="black.600">
                            {props.minutes}
                          </Text>
                          :
                          <Text fontWeight="bold" color="black.600">
                            {props.seconds}
                          </Text>
                        </CenterRow>
                      )
                    }}
                  />
                )}
                {showResendButton && (
                  <button onClick={handleSubmit(onFormSubmit)} className={isValid && isActiveButton ? Styles.send_btn : Styles.send_btn_disabled}>
                    {'Resend a new one'}
                  </button>
                )}
              </CenterRow>
            </div>
          </CenterColumn>
        </Flex>
      ) : (
        <section className={Styles.password}>
          <div className={Styles.password_container}>
            <h4>{'Forgot password?'}</h4>
            {error && (
              <div className={Styles.password_error}>
                <span />
                <span>{error}</span>
              </div>
            )}
            <p className={Styles.confirm_msg}>{'Please enter the email address you use when creating account and we will send you link to reset password'}</p>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <TextField label={'Email address'} class={Styles.form_item} placeholder={'Enter your email'} error={errors?.email} register={register('email')} />
              {loading ? <Loader class={Styles.loader} /> : <SaveButton class={Styles.submit_btn} isValid title={'Send a link'} />}
            </form>
          </div>
        </section>
      )}
    </>
  )
}
