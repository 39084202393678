// Core
import { string, bool, func } from 'prop-types'

// Styles
import Styles from './styles.module.scss'
// Components
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user_updated.svg'
import { getInitials } from 'utils'

export const UserAvatar = ({ avatar, online, onChatDetailsOpenning, senderName = '' }) => {
  return (
    <div className={Styles.avatar} onClick={onChatDetailsOpenning}>
      {avatar ? (
        <div className={Styles.avatar__placeholder}>
          <img src={avatar}></img>
        </div>
      ) : (
        <div className={Styles.avatar__placeholder}>{senderName ? getInitials(senderName) : <UserIcon />}</div>
      )}
      {/* <div className={online ? Styles.avatar__online : Styles.avatar__offline}></div> */}
    </div>
  )
}

UserAvatar.propTypes = {
  avatar: string,
  online: bool,
  onShowContactDetails: func,
}
