import { Close } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'
import { Box } from 'components/Core'
import { Direction } from 'components/Core/common/types'
import { statusColor } from 'constants/index'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { theme } from 'utils/theme'

export const JourneyMenu = styled(motion.div)`
  position: absolute !important;
  top: 70px !important;
  right: 20px !important;
  min-width: 300px !important;
  background: rgba(0, 0, 0, 0.95) !important;
  backdrop-filter: blur(8px) !important;
  border-radius: 16px !important;
  padding: 16px !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
`

export const JourneyItem = styled(motion.button)<{ completed?: boolean; current?: boolean }>`
  width: 100% !important;
  padding: 12px 16px !important;
  margin-bottom: 8px !important;
  border-radius: 12px !important;
  border: none !important;
  background: ${(props) => (props.current ? '#434da1' : 'rgba(255, 255, 255, 0.1)')} !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;

  &:hover {
    transform: translateY(-2px) !important;
    background: ${(props) => (props.current ? '#434da1' : 'rgba(255, 255, 255, 0.15)')} !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
  }
`

export const StepNumber = styled.span<{ completed?: boolean; current?: boolean }>`
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  background: ${({ completed }) => (completed ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.2)')} !important;
  color: ${({ completed }) => (completed ? '#00C853' : 'white')} !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  font-weight: 600 !important;
`

export const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 10px;

  .vjs-poster img {
    object-fit: fill;
  }
  .video-js {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  .vjs-fullscreen {
    border-radius: 0;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }
`

export const NextEpisodeOverlay = styled(motion.div)`
  background: rgba(0, 0, 0, 0.5);
  border: ${theme.borders[2]};
  padding: 5px;
  border-radius: 8px;
  color: black;
  font-size: inherit;
  z-index: 100;
`

const PlayingWave = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 2px;
  height: 14px;
`

const WaveBar = styled(motion.div)`
  width: 2px;
  background: #00c853;
  border-radius: 2px;
`

export const AnimatedWave = () => (
  <PlayingWave>
    {[...Array(3)].map((_, i) => (
      <WaveBar
        key={i}
        initial={{ height: 4 }}
        animate={{
          height: [4, 14, 4],
        }}
        transition={{
          repeat: Infinity,
          duration: 0.8,
          delay: i * 0.2,
          ease: 'easeInOut',
        }}
      />
    ))}
  </PlayingWave>
)

export const VideoDetailOverlay = styled(motion.div)`
  position: absolute;
  bottom: 27px;
  left: 0;
  right: 0;
  padding: 2rem;
  z-index: 10;
`

export const GradientBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 70%, transparent 100%);
  z-index: -1;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
`

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`

export const MetaInfo = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  opacity: 0.8;
`

export const NoAccessCard = styled(motion.div)`
  background: ${theme.colors.white};
  border-radius: 24px;
  padding: 24px;
  text-align: center;
  box-shadow: ${theme.shadows.lg};
  max-width: 600px;
  width: 100%;
  gap: 16px;
`

export const IconWrapper = styled(motion.div)`
  width: 80px;
  height: 80px;
  background: ${theme.colors.primary[100]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
`

export const MCQContainer = styled(motion.div)`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const QuestionCard = styled(Box)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;

  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
`

export const OptionsContainer = styled.div`
  display: grid;
  gap: 1rem;
  margin: 2rem 0;
`

export const OptionButton = styled(motion.button)<{ selected?: boolean; correct?: boolean; wrong?: boolean; disabled?: boolean }>`
  padding: 1rem 2rem;
  border-radius: 12px;
  border: none;
  background: ${(props) => {
    if (props.correct) return 'rgba(46, 213, 115, 0.2)'
    if (props.wrong) return 'rgba(255, 71, 87, 0.2)'
    if (props.selected) return theme.colors.purple[300]
  }};
  color: ${(props) => {
    if (props.correct) return '#2ed573'
    if (props.wrong) return '#ff4757'
  }};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: left;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => {
      if (props.disabled) return ''
      if (!props.selected) return 'rgba(0, 0, 0, 0.2)'
      if (props.selected) return theme.colors.purple[300]
    }};
  }
`

export const SubmitButton = styled(motion.button)`
  background: ${theme.colors.typePurpure[200]};
  border: none;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 2rem;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const ExplanationCard = styled(motion.div)<{ direction: Direction }>`
  background: rgba(202, 142, 241, 0.1);
  border-radius: 12px;
  // margin-top: 2rem;
  padding: 1.5rem;
  border-left: ${(props) => (props.direction === 'rtl' ? 'none' : '4px solid #8450a0')};
  border-right: ${(props) => (props.direction === 'rtl' ? '4px solid #8450a0' : 'none')};
`

export const CloseButton = (props: IconButtonProps) => (
  <IconButton
    sx={{
      bgcolor: `${statusColor.default.backgroundColor} !important`,
      color: `${statusColor.default.color} !important`,
      height: '40px',
      width: '40px',
      zIndex: 100,
    }}
    {...props}
  >
    <Close />
  </IconButton>
)
