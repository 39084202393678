import Styles from './Styles.module.scss'

import React, { useState } from 'react'
import { ApplePayButton, abortApplePaySession } from '@tap-payments/apple-pay-button'
import { APPLE_PAY_MERCHANT_DOMAIN, APPLE_PAY_MERCHANT_ID, paymentKey } from 'constants/index'
import { Modal } from '@mui/material'
import { CloseIcon } from 'theme/assets/icons'
import { PaymentPage } from '../TapGateway'

export const MacOSPayment = ({ open, onClose, openPaymentExpiresPopup, orderDetails }) => {
  const [isOpenTapMpdal, setIsOpenTapModal] = useState(false)
  const { amount, currency, full_name, phone_number, email, description, order_id, transaction_id, created_at } = orderDetails

  const openTapPaymentModal = () => {
    // onClose()
    setIsOpenTapModal(true)
  }

  const handleCloseTapModal = () => {
    setIsOpenTapModal(false)
  }
  // console.log(APPLE_PAY_MERCHANT_ID)
  // console.log(APPLE_PAY_MERCHANT_DOMAIN)
  // console.log(paymentKey)
  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.main_container__header}>
          <h6>Select payment method</h6>
          <CloseIcon onClick={onClose} className={Styles.icon} />
        </div>
        <ApplePayButton
          debug={true}
          scope="TapToken"
          publicKey={paymentKey}
          merchant={{
            domain: APPLE_PAY_MERCHANT_DOMAIN,
            id: APPLE_PAY_MERCHANT_ID,
          }}
          environment="production"
          acceptance={{
            supportedBrands: ['masterCard', 'visa'],
          }}
          features={{
            supportsCouponCode: false,
            // shippingContactFields: ['name', 'phone', 'email'],
          }}
          transaction={{
            currency,
            amount,
          }}
          customer={{
            name: [
              {
                locale: 'en',
                first: full_name,
                last: full_name,
              },
            ],
            contact: {
              email,
              // phone: {
              //   number: phone_number,
              // },
            },
          }}
          interface={{
            locale: 'en',
            theme: 'dark',
            type: 'buy',
            edges: 'curved',
          }}
          onCancel={() => {
            // it's called when the user cancels the payment
            console.log('onCancel')
          }}
          onError={(error) => {
            // it's called when there is an error with the payment
            console.log('onError', error)
          }}
          onReady={() => {
            // it's called when the apple pay button is ready to click
            console.log('onReady')
          }}
          onSuccess={async (data, event) => {
            // it's called when the payment is successful
            console.log('onSuccess', data)
            // event is the same as the event in the onpaymentauthorized event https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentauthorizedevent
            console.log('apple pay event', event)
          }}
          // onMerchantValidation={(status) => {
          //   // it's called when the merchant validation is done
          //   console.log('onMerchantValidation', status)
          // }}
          // onPaymentMethodSelected={async (paymenMethod) => {
          //   // it's a function that give you a chance to update the total
          //   // amount and the line items based on the payment method
          //   return {
          //     newTotal: {
          //       label: 'Merchant Name',
          //       amount: '1.00',
          //     },
          //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentmethodupdate)
          //   }
          // }}
          // onShippingMethodSelected={async (shippingMehod) => {
          //   // it's a function that give you a chance to update the total
          //   // amount and the line items based on the shipping method
          //   return {
          //     newTotal: {
          //       label: 'Merchant Name',
          //       amount: '1.00',
          //     },
          //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingmethodupdate)
          //   }
          // }}
          // onShippingContactSelected={async (shippingContact) => {
          //   // it's a function that give you a chance to update the total
          //   // amount and the line items based on the shipping contact
          //   return {
          //     newTotal: {
          //       label: 'Merchant Name',
          //       amount: '1.00',
          //     },
          //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingmethodupdate)
          //   }
          // }}
          // onCouponChanged={async (couponCode) => {
          //   // it's a function that give you a chance to update the total
          //   // amount and the line items based on the coupon code
          //   return {
          //     newTotal: {
          //       label: 'Merchant Name',
          //       amount: '1.00',
          //     },
          //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentmethodupdate)
          //   }
          // }}
        />
        <p onClick={openTapPaymentModal}>Choose another payment method</p>
        {isOpenTapMpdal && <PaymentPage orderDetails={orderDetails} isOpen={isOpenTapMpdal} closePopup={handleCloseTapModal} openPaymentExpiresPopup={openPaymentExpiresPopup} />}
      </div>
    </Modal>
  )
}
