// Instruments
// Constants
import { apiPath } from '../constants'
import { request } from './axios'

export const advisorOnboardingApi = {
  changeStatusApply: async (data) => {
    return request({
      method: 'POST',
      url: `/landing/consultations/${data.id}/in-a-meeting/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getAllApplies: async () => {
    return request({
      method: 'GET',
      url: `/landing/consultations/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getResults: async (data) => {
    return request({
      method: 'GET',
      url: `/landing/consultations/${data.meeting_id}/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  submitApply: async (data) => {
    return request({
      method: 'POST',
      url: `/landing/consultations/${data.meeting_id}/accept/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
      data: JSON.stringify(data.payload),
    })
  },
  declineApply: async (data) => {
    return request({
      method: 'POST',
      url: `/landing/consultations/${data.meeting_id}/decline/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
      data: JSON.stringify(data.payload),
    })
  },
  getAllTherapists: async () => {
    return request({
      url: `/therapists/all/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getAllParentsForAssign: async () => {
    return request({
      url: `/initial-calls/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  getParentInfoForAssign: async (data) => {
    return request({
      url: `/initial-calls/${data.id}/`,
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
  assignTherapists: async (data) => {
    return request({
      url: `/initial-calls/${data.id}/assign/`,
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
      data: JSON.stringify(data.payload),
    })
  },
  getProfile: async (data) => {
    return request({
      url: `/advisors/profiles/${data.profileId}/`,
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Token ${data.token}`,
      // },
    })
  },
}
