import { useEffect, useState } from 'react'
import { Box, Button, CenterColumn, Flex, Text } from 'components/Core'
import { Rating, TextField } from '@mui/material'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { useGetUserRatingQuery, useRateCourseMutation } from './service'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import type { CourseContentType } from 'navigations/locale/course_english'
import type { Direction } from 'components/Core/common/types'

interface CourseFeedbackProps {
  courseId: number | string | undefined
  content: CourseContentType
  direction: Direction
}

export const CourseFeedback = ({ courseId, content, direction }: CourseFeedbackProps) => {
  const [rating, setRating] = useState<number>(0)
  const [comment, setComment] = useState('')
  const [rate, { isLoading, isSuccess }] = useRateCourseMutation()
  const { data: feedback } = useGetUserRatingQuery({
    course_id: courseId,
  })
  const navigate = useNavigate()

  useEffect(() => {
    setRating(feedback?.rating || 0)
    setComment(feedback?.comment || '')
  }, [feedback])

  useEffect(() => {
    if (isSuccess) navigate(`/profile/parent/course/${courseId}`)
  }, [isSuccess])

  return (
    <FeedbackContainer direction={direction} width={['100%', '100%', '90%', '80%', '60%']}>
      <CenterColumn gap={4} width="100%">
        <Text variant="heading4">{content.courseFeedback}</Text>

        <RatingSection>
          <Text variant="body2" color="gray.600">
            {content.rateThisCourse}
          </Text>
          <Rating
            disabled={Boolean(feedback)}
            value={rating}
            onChange={(_, value) => setRating(value || 0)}
            sx={{
              fontSize: '50px',
              direction: 'ltr',
            }}
          />
        </RatingSection>

        <CommentSection>
          <Text variant="body2" color="gray.600">
            {content.comment}
          </Text>
          <TextField
            sx={{
              direction: 'ltr',
            }}
            multiline
            minRows={5}
            disabled={Boolean(feedback)}
            placeholder={content.shareYourThoughts}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </CommentSection>

        <Button
          width={'100%'}
          borderRadius={10}
          onClick={() => {
            rate({ rating, comment, course_id: courseId })
          }}
          disabled={!rating || Boolean(feedback)}
        >
          {isLoading ? <Spinner width="25px" /> : content.submitFeedback}
        </Button>
      </CenterColumn>
    </FeedbackContainer>
  )
}

const FeedbackContainer = styled(Box)`
  padding: 24px;
  border-radius: 12px;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`

const RatingSection = styled(CenterColumn)`
  gap: 12px;
  align-items: center;
`

const CommentSection = styled(CenterColumn)`
  gap: 8px;
  width: 100%;
`

const CommentInput = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  background: ${theme.colors.gray[100]};
  resize: none;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: ${theme.colors.purple[200]};
  }
`

const SubmitButton = styled.button<{ disabled: boolean }>`
  padding: 12px 24px;
  border-radius: 8px;
  background: ${(props) => (props.disabled ? theme.colors.gray[300] : theme.colors.purple[200])};
  color: white;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;

  &:hover {
    background: ${(props) => !props.disabled && theme.colors.purple[300]};
  }
`
