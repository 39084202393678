//Core
import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
//Validator
import { schemaValidEditTherapist } from './validator'
//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
// Data
import { professional_qualities, licence, expertise, provide_clinical, j_advantage_admin, services, education } from '../../../../data'

//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
import { ReactComponent as AddIcon } from '../../../../theme/assets/icons/add.svg'
//Components
import { TextAreaField, TextField, TextFieldAutocomplete, TextFieldMultiple, RadioGroupField } from '../../../GeneralComponents'
import { Spinner } from '../../../ChatSystem/Common/Spinner'
//Actions
import { adminActions, parentActions } from '../../../../actions'
//Utils
import { getInitials } from '../../../../utils'
import { useAuth } from 'hooks/useAuth'

export const EditTherapistInfoModal = ({ open, onClose, selectedTherapistId }) => {
  const [currentTherapist, setCurrentTherapist] = useState({})

  const [valuesForExpertise, setValuesForExpertise] = useState([])
  const [valuesForServicesProvided, setValuesForServicesProvided] = useState([])
  const [valuesForAdvantages, setValuesForAdvantages] = useState([])
  const [valueForQualification, setValueForQualification] = useState([])
  const [valueForLicence, setValueForLicence] = useState([])

  const [openCustomExpertiseValue, setOpenCustomExpertiseValue] = useState(false)
  const [openCustomServicesValue, setOpenCustomServicesValue] = useState(false)
  const [openCustomQualificationValue, setOpenCustomQualificationValue] = useState(false)
  const [openCustomLicenceValue, setOpenCustomLicenceValue] = useState(false)
  const [openCustomAdvantageValue, setOpenCustomAdvantageValue] = useState(false)

  // const user = useSelector((state) => state.auth.user)
  const { user: authUser } = useAuth()
  const user = { user: authUser }
  const therapist = useSelector((state) => state.parent.currentTherapist)
  const loading = useSelector((state) => state.general.loading)

  const dispatch = useDispatch()

  useEffect(() => {
    const data = {
      token: user.token,
      id: selectedTherapistId,
    }
    dispatch(parentActions.getOneTherapist(data))
  }, [selectedTherapistId])

  useEffect(() => {
    setCurrentTherapist(therapist)
  }, [therapist])

  // console.log(therapist)
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    resetField,
    setError,
  } = useForm({
    mode: 'all',
    resolver: schemaValidEditTherapist(),
    defaultValues: {
      session_price: currentTherapist.session_price,
      bio: currentTherapist.bio,
      professional_qualities: currentTherapist.professional_qualities,
      licence: currentTherapist.licence,
      expertise: currentTherapist.expertise,
      experience: currentTherapist.experience,
      nationality: currentTherapist.nationality,
      age_groups_served: currentTherapist.age_groups_served,
      clinical_services_provided: currentTherapist.clinical_services_provided,
      job_advantages: currentTherapist.job_advantages,
      services: currentTherapist.current_status,
      education_level: currentTherapist.education_level,
      custom_expertise: '',
      custom_services: '',
      custom_qualification: '',
      custom_licence: '',
      custom_advantage: '',
    },
  })

  useEffect(() => {
    reset({
      session_price: currentTherapist.session_price,
      bio: currentTherapist.bio,
      experience: currentTherapist.experience,
      nationality: currentTherapist.nationality,
      age_groups_served: currentTherapist.age_groups_served,
      services: currentTherapist.current_status,
      education_level: currentTherapist.education_level,
      job_advantages: currentTherapist.job_advantages,
    })

    const newArrayOfValuesExpertise = []
    const newArrayOfValuesServices = []
    const newArrayOfValuesLicence = []
    const newArrayOfValuesQualifications = []

    if (currentTherapist?.expertise) {
      for (const item of currentTherapist.expertise) {
        const valueObject = { value: item }
        newArrayOfValuesExpertise.push(valueObject)
      }
    }

    if (currentTherapist?.clinical_services_provided) {
      for (const item of currentTherapist.clinical_services_provided) {
        const valueObject = { value: item }
        newArrayOfValuesServices.push(valueObject)
      }
    }

    if (currentTherapist?.licence) {
      for (const item of currentTherapist.licence) {
        const valueObject = { value: item }
        newArrayOfValuesLicence.push(valueObject)
      }
    }
    if (currentTherapist?.professional_qualities) {
      for (const item of currentTherapist.professional_qualities) {
        const valueObject = { value: item }
        newArrayOfValuesQualifications.push(valueObject)
      }
    }

    setValuesForExpertise([...newArrayOfValuesExpertise])
    setValuesForServicesProvided([...newArrayOfValuesServices])
    setValueForLicence([...newArrayOfValuesLicence])
    setValueForQualification([...newArrayOfValuesQualifications])
  }, [currentTherapist, reset])

  const handleEditTherapist = (data) => {
    const payload = {
      session_price: data.session_price,
      bio: data.bio,
      expertise: [...valuesForExpertise.map((item) => item.value)],
      clinical_services_provided: [...valuesForServicesProvided.map((item) => item.value)],
      job_advantages: data.job_advantages,
      professional_qualities: [...valueForQualification.map((item) => item.value)],
      licence: [...valueForLicence.map((item) => item.value)],
      experience: data.experience,
      nationality: data.nationality,
      age_groups_served: data.age_groups_served,
      current_status: data.services,
      education_level: data.education_level,
    }

    const dataForEdit = {
      token: user.token,
      id: selectedTherapistId,
      payload,
    }

    setOpenCustomExpertiseValue(false)
    setOpenCustomLicenceValue(false)
    setOpenCustomQualificationValue(false)
    setOpenCustomServicesValue(false)
    setOpenCustomAdvantageValue(false)

    dispatch(adminActions.updateTherapistInfo(dataForEdit))
  }

  const handleAddCustomField = (field) => {
    const value = getValues(field)
    if (!value) {
      setError(`${field}`, { type: 'custom', message: `You can't add an empty option.` })
    } else {
      switch (field) {
        case 'custom_services':
          setValuesForServicesProvided((prev) => [...prev, { value }])
          break
        case 'custom_expertise':
          setValuesForExpertise((prev) => [...prev, { value }])
          break
        case 'custom_advantage':
          setValuesForAdvantages((prev) => [...prev, { value }])
          break
        case 'custom_qualification':
          setValueForQualification((prev) => [...prev, { value }])
          break
        case 'custom_licence':
          setValueForLicence((prev) => [...prev, { value }])
          break
      }
      resetField(field)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setValue(name, value, {
      shouldValidate: true,
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <h5>Edit therapist's profile information</h5>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div className={Styles.container_scroll}>
          <div className={Styles.user_details}>
            <div className={Styles.user_details__photo}>{getInitials(currentTherapist?.user?.full_name)}</div>
            <div className={Styles.user_details__text}>
              <p>{currentTherapist?.user?.full_name}</p>
              <span>{currentTherapist?.user?.email}</span>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className={Styles.main_content}>
              <TextAreaField
                name={'bio'}
                label={'About therapist'}
                register={register('bio')}
                control={control}
                error={errors.bio}
                inputClass={Styles.input_text_area}
                class={Styles.input_text_area}
              />
              <div className={Styles.price_field}>
                <TextField
                  label={'Hourly price'}
                  register={register('session_price')}
                  error={errors.session_price}
                  error_wraper={Styles.error_wraper}
                  class={Styles.price_form_item}
                />
                <span>USD/hour</span>
              </div>

              <div className={Styles.two_fields_container}>
                <TextFieldMultiple
                  name="professional_qualities"
                  value={valueForQualification}
                  setNewValue={setValueForQualification}
                  openCustomField={setOpenCustomQualificationValue}
                  control={control}
                  options={professional_qualities}
                  setValue={setValue}
                  freeSolo={true}
                  class={Styles.form_item}
                  disabled={false}
                  class_wrapper={Styles.two_fields_container__multiselector}
                  error={errors?.professional_qualities}
                  label={'Professional qualification'}
                />
                {openCustomQualificationValue && (
                  <div className={Styles.two_fields_container__input}>
                    <TextField
                      label={'Add custom option'}
                      register={register('custom_qualification')}
                      error={errors.custom_qualification}
                      class_wrapper={Styles.two_fields_container__multiselector}
                    />
                    <AddIcon onClick={() => handleAddCustomField('custom_qualification')} className={Styles.two_fields_container__input__icon} />
                  </div>
                )}
              </div>
              <div className={Styles.two_fields_container}>
                <TextFieldMultiple
                  name="licence"
                  value={valueForLicence}
                  setNewValue={setValueForLicence}
                  openCustomField={setOpenCustomLicenceValue}
                  control={control}
                  options={licence}
                  setValue={setValue}
                  freeSolo={true}
                  class={Styles.form_item}
                  disabled={false}
                  class_wrapper={Styles.two_fields_container__multiselector}
                  error={errors?.expertise}
                  label={'Licensing board therapist belongs to'}
                />
                {openCustomLicenceValue && (
                  <div className={Styles.two_fields_container__input}>
                    <TextField
                      label={'Add custom option'}
                      register={register('custom_licence')}
                      error={errors.custom_expertise}
                      class_wrapper={Styles.two_fields_container__multiselector}
                    />
                    <AddIcon onClick={() => handleAddCustomField('custom_licence')} className={Styles.two_fields_container__input__icon} />
                  </div>
                )}
              </div>
              <div className={Styles.two_fields_container}>
                <TextFieldMultiple
                  name="expertise"
                  value={valuesForExpertise}
                  setNewValue={setValuesForExpertise}
                  openCustomField={setOpenCustomExpertiseValue}
                  control={control}
                  options={expertise}
                  setValue={setValue}
                  freeSolo={true}
                  class={Styles.form_item}
                  disabled={false}
                  class_wrapper={Styles.two_fields_container__multiselector}
                  error={errors?.expertise}
                  label={'Areas of expertise'}
                />
                {openCustomExpertiseValue && (
                  <div className={Styles.two_fields_container__input}>
                    <TextField
                      label={'Add custom option'}
                      register={register('custom_expertise')}
                      error={errors.custom_expertise}
                      class_wrapper={Styles.two_fields_container__multiselector}
                    />
                    <AddIcon onClick={() => handleAddCustomField('custom_expertise')} className={Styles.two_fields_container__input__icon} />
                  </div>
                )}
              </div>
              <div className={Styles.two_fields_container}>
                <TextFieldMultiple
                  name="clinical_services_provided"
                  value={valuesForServicesProvided}
                  setNewValue={setValuesForServicesProvided}
                  openCustomField={setOpenCustomServicesValue}
                  control={control}
                  options={provide_clinical}
                  setValue={setValue}
                  freeSolo={true}
                  disabled={false}
                  class_wrapper={Styles.two_fields_container__multiselector}
                  error={errors?.clinical_services_provided}
                  label={'Clinical settings'}
                />
                {openCustomServicesValue && (
                  <div className={Styles.two_fields_container__input}>
                    <TextField
                      label={'Add custom option'}
                      register={register('custom_services')}
                      error={errors.custom_services}
                      class_wrapper={Styles.two_fields_container__multiselector}
                    />
                    <AddIcon onClick={() => handleAddCustomField('custom_services')} className={Styles.two_fields_container__input__icon} />
                  </div>
                )}
              </div>
              <div className={Styles.two_fields_container}>
                <div className={Styles.with_span_field}>
                  <TextField
                    label={'Years of experience'}
                    register={register('experience')}
                    error={errors.experience}
                    error_wraper={Styles.error_wraper}
                    class={Styles.form_item}
                  />
                  <span>years</span>
                </div>
              </div>
              <div className={Styles.two_fields_container}>
                <div className={Styles.with_span_field}>
                  <TextField
                    label={'Client age groups'}
                    register={register('age_groups_served')}
                    error={errors.age_groups_served}
                    error_wraper={Styles.error_wraper}
                    class={Styles.form_item}
                  />
                </div>
                <div className={Styles.with_span_field}>
                  <TextField
                    label={'The main advantages of joining Numuw'}
                    register={register('job_advantages')}
                    error={errors.job_advantages}
                    error_wraper={Styles.error_wraper}
                    class={Styles.form_item}
                  />
                </div>
              </div>
              <div className={Styles.radio_container}>
                <RadioGroupField
                  name="services"
                  defaultValue={currentTherapist?.current_status}
                  control={control}
                  radioLabel={'Do you currently actively provide clinical services?'}
                  class={Styles.label}
                  classLabel={Styles.label}
                  mainClass={Styles.radio}
                  radioClass={Styles.radio_wrap}
                  radioButtons={services}
                  onChange={handleChange}
                />
                <RadioGroupField
                  name="education_level"
                  defaultValue={currentTherapist?.education_level}
                  control={control}
                  radioLabel={'Educational degree'}
                  class={Styles.label}
                  classLabel={Styles.label}
                  mainClass={Styles.radio}
                  radioClass={Styles.radio_wrap}
                  radioButtons={education}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </div>
        <div className={Styles.btns}>
          <button className={Styles.btns__cancel} onClick={onClose}>
            Cancel
          </button>
          <button className={isValid ? Styles.btns__next : Styles.btns__next__disabled} onClick={handleSubmit(handleEditTherapist)}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}
