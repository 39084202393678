import { Box } from 'components/Core'
import { parent_onboarding_report_questionnaire } from '../../data'
import Styles from './Styles.module.scss'
import { useQuestionnaireReportGenerator } from './useQuestionnaireReportGenerator'
import { useMemo } from 'react'
import { mainStatementAnswers } from 'constants/index'

export const AdminsParentOnBoardingReport = ({ questionnaire, childName, language = 'en' }) => {
  const { defaultParagraphForReport, familyDynamicsReport, isReportExist, parentalWellbeingReport } = useQuestionnaireReportGenerator({
    childName,
    questionnaire: questionnaire?.questionnaire,
    language,
    familyDynamics: questionnaire?.familyDynamics,
    parentalWellbeing: questionnaire?.parentalWellbeing,
  })
  const direction = useMemo(() => (language === 'en' ? 'ltr' : 'rtl'), [language])
  return (
    <Box direction={direction} overflowY="auto" maxHeight={600} p={'20px'}>
      {questionnaire?.questionnaire &&
        questionnaire?.questionnaire?.map((section) => {
          if (section?.main_statement_answer === mainStatementAnswers.yes) {
            return (
              <div style={{ marginBottom: 20 }} key={section?.id} className={Styles.main_container__content__item}>
                <h6>{parent_onboarding_report_questionnaire(childName)[section?.category]?.section_name?.[language]}</h6>
                <p>{parent_onboarding_report_questionnaire(childName)[section?.category]?.feedback?.[language]}</p>
              </div>
            )
          }
        })}
      {familyDynamicsReport?.section_name && (
        <div className={Styles.main_container__content__item}>
          <h6>{familyDynamicsReport?.section_name?.[language]}</h6>
          <p>{familyDynamicsReport?.feedback?.[language]}</p>
        </div>
      )}
      {parentalWellbeingReport?.section_name && (
        <div className={Styles.main_container__content__item}>
          <h6>{parentalWellbeingReport?.section_name?.[language]}</h6>
          <p>{parentalWellbeingReport?.feedback?.[language]}</p>
        </div>
      )}
      {!isReportExist && (
        <div style={{ width: '100%' }}>
          <p style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>{defaultParagraphForReport}</p>
        </div>
      )}
    </Box>
  )
}
