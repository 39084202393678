import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useQuestionnaireSlice } from './slice'
import { selectCurrentMainStatementId, selectCurrentSubStatementIndex, selectOpenSubStatements, selectQuestionnaireData, selectUnansweredQuestions } from './slice/selectors'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { mainStatementAnswers } from 'constants/index'
import type { OnboardingStep } from 'defaultLayout/types'
import { PARENT_ONBOARDING_COOKIES_NAME } from 'data/enums'

type Options = {
  getSteps: (value: number | string) => void
  setOnboardingStep: (step: OnboardingStep) => void
  setAddChildStep: (value: number) => void
}

export const useQuestionnaire = ({ getSteps, setOnboardingStep, setAddChildStep }: Options) => {
  const [searchParams] = useSearchParams()
  const [answeredCount, setAnsweredCount] = useState(0)
  const [yesClicked, setYesClicked] = useState(false)
  const [noClicked, setNoClicked] = useState(false)
  const { t } = useTranslation()
  const {
    setCurrentMainStatementIndex,
    setMainStatementAnswer,
    toggleSubStatements,
    setSubStatementAnswer,
    setCurrentSubStatementIndex,
    resetSubStatementsIndex,
    skipAllSubStatementsAnswer,
    setUnansweredQuestions,
  } = useQuestionnaireSlice()
  const currentMainStatementIndex = useSelector(selectCurrentMainStatementId)
  const questionnaireData = useSelector(selectQuestionnaireData)
  const subStatementsOpened = useSelector(selectOpenSubStatements)
  const currentSubStatementIndex = useSelector(selectCurrentSubStatementIndex)
  const unansweredQuestions = useSelector(selectUnansweredQuestions)

  /**@ProgressBar */
  const totalCount = useMemo(() => {
    let totalCount = questionnaireData.length
    for (let questionnaire of questionnaireData) {
      totalCount += +questionnaire.sub_statements.length
    }

    return totalCount
  }, [questionnaireData])
  const progress = useMemo(() => {
    return Math.round((answeredCount / totalCount) * 100)
  }, [answeredCount, totalCount])
  const isProgressComplete = useMemo(() => progress === 100, [progress])

  useEffect(() => {
    let count = 0
    questionnaireData.forEach((mainStatement) => {
      if (mainStatement.main_statement_answer !== '') {
        count++
      }

      mainStatement.sub_statements.forEach((subStatement) => {
        if (![''].includes(subStatement.sub_statement_answer)) {
          count++
        }
      })
    })

    setAnsweredCount(count)
  }, [questionnaireData, currentMainStatementIndex])

  const isTheLastSubStatementIndex = useMemo(
    () => currentSubStatementIndex === questionnaireData[currentMainStatementIndex].sub_statements.length - 1,
    [currentSubStatementIndex, JSON.stringify(questionnaireData), currentMainStatementIndex],
  )

  const isLastMainStatementIndex = useMemo(() => currentMainStatementIndex === questionnaireData.length - 1, [currentMainStatementIndex, questionnaireData])

  const increaseMainStatementIndex = () => {
    const mainStatementIndex = currentMainStatementIndex + 1
    setCurrentMainStatementIndex(mainStatementIndex)
  }

  const disableNextButton = useMemo(() => {
    if (questionnaireData[currentMainStatementIndex].main_statement_answer === '') {
      return true
    }
    if (subStatementsOpened) {
      if (['', 'Skipped'].includes(questionnaireData[currentMainStatementIndex].sub_statements[currentSubStatementIndex].sub_statement_answer)) {
        return true
      }
      return false
    }

    return progress < 100 && currentMainStatementIndex === questionnaireData.length - 1
  }, [currentMainStatementIndex, JSON.stringify(questionnaireData), currentSubStatementIndex, subStatementsOpened, progress])

  const goBack = () => {
    if (currentMainStatementIndex !== 0) {
      const mainStatementIndex = currentMainStatementIndex - 1
      setCurrentMainStatementIndex(mainStatementIndex)
      return
    }
    // if (!subStatementsOpened && currentMainStatementIndex !== 0 && isVariantB) {
    //   const mainStatementIndex = currentMainStatementIndex - 1
    //   const mainStatementAnswerIsYes = questionnaireData[mainStatementIndex].main_statement_answer === mainStatementAnswers.yes
    //   setCurrentMainStatementIndex(mainStatementIndex)
    //   const subStatementIndex = questionnaireData[mainStatementIndex].sub_statements.length - 1
    //   if (mainStatementAnswerIsYes) {
    //     toggleSubStatements(true)
    //     setCurrentSubStatementIndex(subStatementIndex)
    //   } else {
    //     toggleSubStatements(false)
    //   }
    //   return
    // }

    if (subStatementsOpened) {
      if (currentSubStatementIndex > 0) {
        decreaseSubStatementIndex()
      } else {
        toggleSubStatements(false)
      }
    } else {
      setAddChildStep(2)
      setOnboardingStep('add_child')
    }
  }

  const next = () => {
    const currentMainStatementAnswerIsYes = questionnaireData[currentMainStatementIndex].main_statement_answer === mainStatementAnswers.yes

    // if (!subStatementsOpened && isVariantB && currentMainStatementAnswerIsYes) {
    //   toggleSubStatements(true)
    //   setCurrentSubStatementIndex(0)
    //   return
    // }
    if (subStatementsOpened && !isTheLastSubStatementIndex) {
      increaseSubStatementIndex()
    } else {
      toggleSubStatements(false)
      resetSubStatementsIndex()
      if (currentMainStatementIndex === questionnaireData.length - 1) {
        if (isProgressComplete) {
          setOnboardingStep('report')
        }
        return
      }
      increaseMainStatementIndex()
    }
  }

  const onAnswerYes = (index: number) => {
    setMainStatementAnswer({ index, answer: mainStatementAnswers.yes })

    skipAllSubStatementsAnswer(index)

    if (currentMainStatementIndex === questionnaireData.length - 1) {
      setYesClicked(true)
    } else {
      setCurrentMainStatementIndex(index + 1)
      setYesClicked(false)
    }
  }

  useEffect(() => {
    if (progress === 100 && currentMainStatementIndex === questionnaireData.length - 1) {
      if (yesClicked || noClicked) {
        setOnboardingStep('report')
      }
    }
  }, [progress, yesClicked, noClicked, currentMainStatementIndex, questionnaireData.length])

  const onAnswerNo = (index: number) => {
    setMainStatementAnswer({ index, answer: mainStatementAnswers.no })
    skipAllSubStatementsAnswer(index)
    toggleSubStatements(false)

    const isLastQuestion = index === questionnaireData.length - 1
    if (isLastQuestion) {
      setNoClicked(true)
    } else {
      setCurrentMainStatementIndex(index + 1)
      setNoClicked(false)
    }
  }

  const increaseSubStatementIndex = () => {
    if (isTheLastSubStatementIndex) {
      if (currentMainStatementIndex === questionnaireData.length - 1) {
        setOnboardingStep('report')

        resetSubStatementsIndex()
        toggleSubStatements(false)
      } else {
        next()
      }
      return
    }
    const subStatementIndex = currentSubStatementIndex + 1

    setCurrentSubStatementIndex(subStatementIndex)
  }

  const decreaseSubStatementIndex = () => {
    const subStatementIndex = currentSubStatementIndex - 1

    setCurrentSubStatementIndex(subStatementIndex)
  }

  useEffect(() => {
    localStorage.setItem(PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE, JSON.stringify(questionnaireData))
  }, [JSON.stringify(questionnaireData)])

  /**@StepOnURL */
  useEffect(() => {
    const mainStep = currentMainStatementIndex + 1
    const step = `${mainStep}.${subStatementsOpened ? currentSubStatementIndex + 1 : currentSubStatementIndex}`
    getSteps(step)
  }, [currentMainStatementIndex, currentSubStatementIndex, subStatementsOpened])

  return {
    t,
    setCurrentMainStatementIndex,
    setMainStatementAnswer,
    goBack,
    next,
    toggleSubStatements,
    setSubStatementAnswer,
    increaseSubStatementIndex,
    resetSubStatementsIndex,
    onAnswerNo,
    onAnswerYes,
    disableNextButton,
    searchParams,
    currentMainStatementIndex,
    questionnaireData,
    subStatementsOpened,
    currentSubStatementIndex,
    progress,
    unansweredQuestions,
    isLastMainStatementIndex,
  }
}
