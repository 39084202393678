import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Container } from 'components/Core'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
export const FirebaseAuth = () => {
  const { search } = useLocation()
  useEffect(() => {
    const { continueUrl } = queryString.parse(search)
    const currentParams = new URLSearchParams(search)
    console.log(currentParams.toString())
    console.log(continueUrl)
    setTimeout(() => {
      if (continueUrl) {
        window.location.href = `${continueUrl}?${currentParams.toString()}`
      } else {
        window.location.href = `${process.env.REACT_APP_SITE_URL}/pass-reset?${currentParams.toString()}`
      }
    }, 1000)
  }, [search])
  return (
    <Container height="100vh" flexDirection={['column']}>
      <Spinner />
    </Container>
  )
}
