// Routing
import history from '../lib/history'
// Actions
import { setFileUploadToken, setIsAuth, setTherapistEmail, setUser } from '../reducers/authSlice'
import { clearError, clearMessage, setError, setLoading, setMessage } from '../reducers/generalSlice'
import { setOrderTimestamp } from '../reducers/parentSlice'
// Api
import { authApi } from '../api'
// Utils
import { setTimeZone } from '../reducers/profileSlice'
import { deleteCookie, setCookie } from '../utils'
import { loginAUser, notify } from '../helpers'
import { toast } from 'react-toastify'
import { errorsText } from 'errors'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { authInstance } from 'utils/utils'
import { defaultLayoutActions } from 'defaultLayout/slice'

export const authActions = {
  signupTherapist: (data) => (dispatch) => {
    dispatch(setLoading(true))
    dispatch(setTherapistEmail(data?.data?.email))
    try {
      authApi
        .signupTherapist(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          dispatch(clearError())
          dispatch(setLoading(false))

          dispatch(setFileUploadToken(res?.file_upload_token))
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  signupAdvisor: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .loginUser(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(setUser(res))
            dispatch(clearError())
            dispatch(setLoading(false))
            history.push('/signup/advisor/update')
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  signupUpdateAdvisor: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .signupUpdateAdvisor(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            localStorage.setItem('token', res?.token)
            dispatch(setIsAuth(true))
            dispatch(setUser(res))
            dispatch(clearError())
            dispatch(setLoading(false))
            const user = {
              user: {
                id: res.user.id,
                user_type: res.user.user_type,
                is_email_confirmed: res.user.is_email_confirmed,
                email: res.user.email,
                timeZone: data.timeZone,
                notification_channels: res.user.notification_channels,
              },
              profile: res.profile,
              token: res.token,
            }

            setCookie('user', JSON.stringify(user), {
              secure: true,
              samesite: true,
              'max-age': 31536000,
            })
            history.push(`/admin_panel/advisor/applications`)
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  sendVerifyEmail: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .verifyEmail(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(setLoading(false))

            if (res.result) {
              dispatch(clearError())
              dispatch(setError(res.result))
            }
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  sendConfirmEmail: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .confirmEmail(data)
        .then((response) => response.json())
        .then((res) => {
          if (res.is_active) {
            dispatch(setUser(res))
            dispatch(clearError())
            dispatch(setLoading(false))
            dispatch(clearMessage())
            dispatch(setMessage('Thank you, you have successfully verified your email'))
          } else {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  sendRecoveryPassword: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .recoveryPassword(data)
        .then(async (response) => {
          const result = await response.json()
          if (response.ok) {
            notify('Successfully sent a password recovery link to your email', false)

            dispatch(setLoading(false))
            dispatch(clearError())
            return result
          } else {
            throw new Error(result?.message ?? result?.detail)
          }
        })
        .catch((error) => {
          // console.log(error?.message)
          notify(error?.message || 'Failed to send a password recovery link to your email, please try again')
          dispatch(setLoading(false))
          dispatch(clearError())
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  sendResetPasswordValidToken: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .resetPasswordValidToken(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res.is_active) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
            history.push('/signup/pass-recovery')
          } else {
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  sendResetPassword: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      authApi
        .resetPassword(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (res.is_password_changed) {
            dispatch(setUser(res))
            dispatch(clearError())
            dispatch(setLoading(false))
            dispatch(clearMessage())
            dispatch(setMessage('Thank you, your password has been successfully changed'))
          } else {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
            history.push('/signup/pass-recovery')
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  checkUserEmail: (data) => (dispatch) => {
    dispatch(clearError())
    try {
      authApi
        .checkUserEmail(data)
        .then((response) => response.json())
        .then((res) => {
          if (res.is_email_exists) {
            if (data?.action) data?.action()
            dispatch(setError('An account with this email already exist!'))
          } else {
            dispatch(clearError())
          }
        })
        .catch(() => {
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  loginUser: (data) => (dispatch) => {
    // dispatch(setLoading(true))
    dispatch(defaultLayoutActions.setIsLoggingIn(true))

    try {
      authApi
        .loginUser(data)
        .then(async (res) => {
          if (data?.fromSignUp) {
            dispatch(defaultLayoutActions.setIsAuthenticating(true))
          }
          await signInWithEmailAndPassword(authInstance, data.email, data.password)
            .then(() => {
              loginAUser(res?.data, dispatch)
            })
            .catch((error) => {
              console.log(error)
              if (error.code === 'auth/user-not-found') {
                notify('User not found')
              } else if (error.code === 'auth/wrong-password') {
                notify('Wrong password')
              } else if (error.code === 'auth/invalid-credential') {
                notify('Invalid credential')
              } else {
                notify('Something went wrong')
              }
            })
            .finally(() => {
              dispatch(defaultLayoutActions.setIsLoggingIn(false))
              dispatch(defaultLayoutActions.setIsAuthenticating(false))
            })
        })
        .catch((e) => {
          notify(e?.response?.data?.message)
        })
        .finally(() => {
          dispatch(defaultLayoutActions.setIsLoggingIn(false))
          dispatch(defaultLayoutActions.setIsAuthenticating(false))
        })
    } catch (error) {
      console.log(error)
      dispatch(defaultLayoutActions.setIsLoggingIn(false))
    }
  },

  logoutUser: (deleteSubscription) => (dispatch) => {
    try {
      // Logout from firebase also using authInstance
      deleteSubscription()
      signOut(authInstance)
      localStorage.removeItem('token')
      deleteCookie('user')
      window.location.href = '/'
      localStorage.removeItem('impersonated')

      // authApi
      //   .logoutUser(data)
      //   .then((response) => {
      //     if (response.ok) {
      //       return null
      //     }

      //     dispatch(setLoading(false))
      //     dispatch(clearError())
      //     dispatch(setError('Something went wrong, please try again later!'))
      //   })
      //   .catch(() => {
      //     dispatch(setLoading(false))
      //     dispatch(clearError())
      //     dispatch(setError('Something went wrong, please try again later!'))
      //   })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  getUser: (data) => (dispatch) => {
    try {
      authApi
        .getUser(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }

          if (response.status === 401) {
            localStorage.removeItem('token')
            deleteCookie('user')
            history.push('/login')
          }
        })
        .then((res) => {
          if (res) {
            localStorage.setItem('user_preferred_language', res?.preferred_language)
            const updatedUser = {
              ...data,
              user: {
                ...data.user,
                ...res,
              },
            }
            dispatch(setUser(updatedUser))
            dispatch(setIsAuth(true))
            dispatch(clearError())
          }
        })
        .catch(() => {
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  postQuestionnaireForParent: (data) => (dispatch) => {
    dispatch(setLoading(true))
    const toastId = toast.loading(data?.pendingMessage)

    try {
      authApi
        .signupParent(data?.payload)
        .then((res) => {
          toast.dismiss(toastId)
          notify(data?.successMessage, false)
          data?.action()
          dispatch(setLoading(false))
        })
        .catch((error) => {
          toast.dismiss(toastId)
          notify(errorsText[error?.response?.data?.message]?.[data?.payload?.parent_info?.preferred_language ?? 'en'] ?? error?.response?.data?.message)
          // console.log('authActions.postQuestionnaireForParent', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      notify('Failed to create your account, please try again later.')
      // console.log('authActions.postQuestionnaireForParent', error)
      dispatch(setLoading(false))
    }
  },
}
