// Constants
import { authInstance } from 'utils/utils'
import { apiPath } from '../constants'

/**
 * WebSocketService class provides methods to manage WebSocket connections and communication.
 */
export default class WebSocketService {
  /**
   * Creates an instance of WebSocketService.
   * @param {string} action - The action type ('chat', 'notifications', 'sessions')
   * @param {string} room_id - The room ID for WebSocket connection
   * @param {Object} user - The user object containing user details
   */
  constructor(action, room_id, user) {
    this.action = action
    this.socketRef = null
    this.callbacks = {}
    this.room_id = room_id
    this.user = user
  }

  /**
   * Connects to the WebSocket server based on the provided action.
   */
  connect = async () => {
    const token = await authInstance.currentUser.getIdToken()
    let path
    if (this.action === 'chat' && this?.room_id) {
      path = `${apiPath.replace(/http(s)?:/, 'ws$1:')}/ws/chat/${this.room_id}/`
    } else if (this.action === 'notifications' && this?.user?.id) {
      path = `${apiPath.replace(/http(s)?:/, 'ws$1:')}/ws/notifications/${this?.user?.id}/`
    } else if (this.action === 'sessions' && this?.user?.id) {
      path = `${apiPath.replace(/http(s)?:/, 'ws$1:')}/ws/parents/${this?.user?.id}/history/`
    }
    if (path) {
      this.socketRef = new WebSocket(path)
    }
    if (this.socketRef && token) {
      this.socketRef.onopen = () => {
        if (this.socketRef.readyState === WebSocket.OPEN) {
          this.socketRef.send(JSON.stringify({ command: 'set_token', token }))
        }
      }
      console.log('WebSocket connection established.')
    } else {
      console.log('WebSocket connection failed to establish.')
      return
    }
    if (this.action === 'chat') {
      this.socketNewMessage(
        JSON.stringify({
          command: 'fetch_messages',
        }),
      )
    }

    this.socketRef.onmessage = (e) => {
      this.socketNewMessage(e.data)
    }
    // eslint-disable-next-line no-unused-vars
    this.socketRef.onerror = () => {}
    this.socketRef.onclose = () => {}
  }

  /**
   * Processes incoming WebSocket messages and invokes registered callbacks.
   * @param {string} data - The JSON stringified WebSocket message data
   */
  socketNewMessage(data) {
    const parsedData = JSON.parse(data)
    const command = parsedData.command
    if (Object.keys(this.callbacks).length === 0) {
      return
    }
    if (command === 'messages') {
      this.callbacks[command]({ messages: parsedData.messages, max_pages: parsedData.max_pages, room_id: parsedData.room_id })
      return
    }
    if (command === 'new_message') {
      this.callbacks[command](parsedData.message)
      return
    }
    if (command === 'progress') {
      console.log('=====>', parsedData)
      // this.callbacks[command](parsedData.notification)
      return
    }
    if (command === 'share_file') {
      this.callbacks[command](parsedData.file)
      return
    }
    if (command === 'notification_message') {
      this.callbacks['notification_message'](parsedData.message)
      return
    }
    if (command === 'history_data') {
      this.callbacks['history_data'](parsedData.data)
      return
    }
    if (command === 'free_consultation_data') {
      this.callbacks['fetch_free_consultation'](parsedData.data)
      return
    }
    if (command === 'screening_data') {
      this.callbacks['screening_data'](parsedData.data)
      return
    }
    if (command === 'error') {
      this.callbacks['error'](parsedData.message)
      return
    }
  }

  /**
   * Sends a WebSocket message to fetch messages for a specific room.
   * @param {string} username - The username sending the fetch request
   * @param {string} room_id - The ID of the room to fetch messages from
   * @param {number} page_number - The page number of messages to fetch
   */
  fetchMessages(username, room_id, page_number) {
    this.sendMessage({ command: 'fetch_messages', from: username, room_id: room_id, page_number: page_number })
  }

  /**
   * Sends a WebSocket message to fetch session history for a specific profile.
   * @param {string} profile_id - The ID of the profile to fetch session history for
   */
  fetchSessionsHistory(profile_id) {
    this.sendMessage({ command: 'fetch_history', profile_id })
  }

  fetchFreeConsultation(profile_id) {
    this.sendMessage({ command: 'fetch_free_consultation', profile_id })
  }

  /**
   * Sends a WebSocket message for a new chat message.
   * @param {Object} message - The message object containing details of the new message
   */
  newChatMessage(message) {
    this.sendMessage({ command: 'new_message', from: message.from, message: message.content, room_id: message.roomId })
  }

  /**
   * Sends a WebSocket message for sharing a file.
   * @param {Object} message - The message object containing details of the file to be shared
   */
  shareFile(message) {
    this.sendMessage({ command: 'share_file', from: message.from, file: message.content, room_id: message.roomId })
  }

  /**
   * Sets the callbacks for handling WebSocket events.
   * @param {Function} messagesCallback - Callback function for handling incoming messages
   * @param {Function} newMessageCallback - Callback function for handling new incoming messages
   * @param {Function} shareFileCallback - Callback function for handling shared files
   */
  setCallbacks(messagesCallback, newMessageCallback, shareFileCallback) {
    // console.log(shareFileCallback)
    this.callbacks['messages'] = messagesCallback
    this.callbacks['new_message'] = newMessageCallback
    this.callbacks['share_file'] = shareFileCallback
  }

  /**
   * Sets the callback for handling notification messages.
   * @param {Function} notificationCallback - Callback function for handling notification messages
   */
  setNotificationCallback(notificationCallback) {
    this.callbacks['notification_message'] = notificationCallback
  }

  /**
   * Sets the callback for handling session history data.
   * @param {Function} sessionsCallback - Callback function for handling session history data
   */
  setSessionsCallback(sessionsCallback, freeConsultationCallback) {
    this.callbacks['history_data'] = sessionsCallback
    if (freeConsultationCallback) {
      this.callbacks['fetch_free_consultation'] = freeConsultationCallback
    }
  }

  /**
   * Sets the callback for handling screening data.
   * @param {Function} screeningCallback - Callback function for handling screening data
   */
  setScreeningCallback(screeningCallback) {
    this.callbacks['screening_data'] = screeningCallback
  }

  /**
   * Sets the callback for handling WebSocket errors.
   * @param {Function} errorCallback - Callback function for handling WebSocket errors
   */
  setErrorCallback(errorCallback) {
    this.callbacks['error'] = errorCallback
  }

  /**
   * Sends a message through the WebSocket connection.
   * @param {Object} data - The data object to be sent via WebSocket
   */
  sendMessage(data) {
    // we send message to Consumer receive method
    try {
      this.socketRef?.send(JSON.stringify({ ...data }))
    } catch (err) {
      // console.log(err.message)
    }
  }

  /**
   * Returns the current state of the WebSocket connection.
   * @returns {number} - The WebSocket ready state (0 = CONNECTING, 1 = OPEN, 2 = CLOSING, 3 = CLOSED)
   */
  state() {
    return this.socketRef?.readyState
  }
}
