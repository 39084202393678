// Constants
import { apiPath } from '../constants'
import { request } from './axios'
import { fetchRequest } from './fetch'

export const adminApi = {
  getAllParents: () => {
    return fetchRequest(`${apiPath}/api/parents/`, {
      method: 'GET',
    })
  },
  getChildProfile: (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/parents/profiles/${data.id}/`,
    })
  },
  getChildHistory: (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/parents/profiles/${data.id}/history/`,
    })
  },
  provideDiscount: (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/api/payments/apply-discount/`,

      data: JSON.stringify(data.payload),
    })
  },
  getAllAdvisors: () => {
    return fetchRequest(`${apiPath}/api/advisors/all/`, {
      method: 'GET',
    })
  },

  createAdvisor: (data) => {
    return fetchRequest(`${apiPath}/api/advisors/create/`, {
      method: 'POST',

      body: JSON.stringify(data.credentials),
    })
  },

  resendAdvisor: (data) => {
    return fetchRequest(`${apiPath}/api/advisors/resend-invitation/`, {
      method: 'POST',

      body: JSON.stringify(data.credentials),
    })
  },

  deleteAdvisor: (data) => {
    return fetchRequest(`${apiPath}/api/advisors/${data.id}/delete/`, {
      method: 'DELETE',
    })
  },

  setAPIKey: (data) => {
    return fetchRequest(`${apiPath}/api/calcom/api-keys/create/`, {
      method: 'POST',

      body: JSON.stringify(data.info),
    })
  },
  approveTherapist: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/therapists/${data.id}/approve/`,
    })
  },
  declineTherapist: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/api/therapists/${data.id}/decline/`,

      data: JSON.stringify(data),
    })
  },
  updateTherapistInfo: async (data) => {
    return request({
      method: 'PUT',
      url: `${apiPath}/api/therapists/${data.id}/update/`,

      data: JSON.stringify(data.payload),
    })
  },
  getAllMeetings: async () => {
    return request({
      method: 'GET',
      url: `${apiPath}/api/calcom/sessions/`,
    })
  },
  retryZoomMeeting: async (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/api/calcom/sessions/${data.id}/retry/`,
    })
  },
}
