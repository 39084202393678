// Routing
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
// Components
import { Main } from '../components'
// Pages
import {
  PasswordRecoveryPage,
  PasswordResetPage,
  SignInPage,
  SignUpPage,
  VerifyEmailPage,
  ConfirmEmailPage,
  AdminSignInPage,
  AdvisorSignInPage,
  AdvisorSignUpUpdatePage,
  VerificationPage,
} from '../pages'
import { TherapistSuccessAccountCreate } from '../components/Auth/SignUp/TherapistSuccessAccountCreate'

import { FirebaseAuth } from 'pages/FirebaseAuth'
import { ParentSignUp } from 'components/ParentFlow/Therapy/NewParentOnboarding/ParentSignUp'

export const Public = () => {
  console.log('Public')
  return (
    <Routes>
      <Route path="/" exact element={<Main />}>
        <Route index element={<Navigate to="login" />} />
        <Route path="login" element={<Outlet />}>
          <Route index element={<SignInPage />} />
          <Route path="admin" element={<AdminSignInPage />} />
          <Route path="advisor" element={<AdvisorSignInPage />} />
          {/* <Route path="authentication-link" element={<SignInWithMagicLinkPage />} /> */}
        </Route>
        <Route path="pass-recovery" element={<PasswordRecoveryPage />} />
        <Route path="pass-reset" element={<PasswordResetPage />} />
        <Route path="signup" element={<Outlet />}>
          <Route index element={<Navigate to="client" />} />
          <Route path="client" element={<ParentSignUp />} />
          <Route path="therapist" element={<SignUpPage />} />
          <Route path="advisor" element={<AdvisorSignUpUpdatePage />} />
          <Route path="verify-email" element={<VerifyEmailPage />} />
          <Route path="confirm-email" element={<ConfirmEmailPage />} />
          <Route path="therapist/success" element={<TherapistSuccessAccountCreate />} />
        </Route>
        <Route path="verify" element={<VerificationPage />} />
        <Route path="auth" element={<FirebaseAuth />} />
        <Route path="*" element={<SignInPage />} />
      </Route>
    </Routes>
  )
}
