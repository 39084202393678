import { IconButton, Switch } from '@mui/material'
import { type SubscribedDevice, useAddChannelMutation, useGetNotificationChannelsQuery, useRemoveChannelMutation, useSubscriptionsQuery, useUnsubscribeMutation } from './service'
import { CenterColumn, CenterRow, Flex, Text } from 'components/Core'
import { dateFormatter } from 'utils/utils'
import { BrowserUpdated, Delete, Devices, Email, Warning } from '@mui/icons-material'
import { ConfirmationModal } from 'components/shared/ConfimationModal'
import { useDisclosure } from 'hooks/useDisclosure'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { Channels, DeviceInfos } from 'utils/types/types'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useSelector } from 'react-redux'
import { selectSubscription } from 'defaultLayout/slice/selectors'
import { t } from 'i18next'
import { useNotifications } from 'serviceWorker/useNotification'
import { useGetLanguage } from 'hooks/useGetLanguage'

export const NotificationSetting = () => {
  const channelController = useDisclosure()
  const { open, onClose, onOpen } = useDisclosure()
  const subscribing = useDisclosure()
  const { isArabic } = useGetLanguage()
  const [device, setDevice] = useState<{ id: string; device_info: DeviceInfos }>()
  const [channel, setChannel] = useState<Channels>()
  const currentSubscription = useSelector(selectSubscription)
  const { data: subscribedDevices, refetch } = useSubscriptionsQuery(null, {
    refetchOnFocus: true,
  })
  const [unsubscribe, { isLoading: unsubscribing }] = useUnsubscribeMutation()
  const { data: channels, isLoading: fetchingChannels } = useGetNotificationChannelsQuery(null, {
    refetchOnFocus: true,
  })
  const { isEnabled, enableNotifications, disableNotifications } = useNotifications()
  const [removeChannel, { isLoading: removing }] = useRemoveChannelMutation()
  const [addChannel, { isLoading: addingChannel }] = useAddChannelMutation()

  useEffect(() => {
    if (channels?.includes('web push')) {
      subscribing.onOpen()
      enableNotifications().then(() => {
        subscribing.onClose()
        refetch()
      })
    }
  }, [channels])
  const addOrRemoveChannel = (channel: Channels) => {
    setChannel(channel)
    if (channels?.includes(channel)) {
      channelController.onOpen()
    } else {
      addChannel({
        channel,
      })
    }
  }
  const disableCurrentDevice = useCallback(
    (item: SubscribedDevice) => {
      return item?.endpoint === currentSubscription?.endpoint
    },
    [currentSubscription?.endpoint],
  )

  const webPushEnabled = useMemo(() => {
    return channels?.includes('web push')
  }, [channels])

  return (
    <Flex bg="black.100" p={[1, 1, 3]} borderRadius={10} mt={[16]} height="auto" flexDirection="column" gap="16px" width="100%">
      {!fetchingChannels ? (
        <CenterColumn gap={16}>
          <Text variant="heading4" fontWeight="normal">
            {t('profileSettings:notifications')}
          </Text>

          <CenterRow flexWrap="wrap" bg="white" height={['auto', 'auto', '70px']} justifyContent="space-between" p={['16px']} width="100%" borderRadius={'10px'} boxShadow="normal">
            <CenterRow gap="10px">
              <Email />
              <Text fontWeight="bold" fontStyle="capitalize" fontSize={[16]}>
                {t('profileSettings:emailNotifications')}
              </Text>
            </CenterRow>
            {(removing || addingChannel) && channel === 'email' ? (
              <Spinner width="25px" />
            ) : (
              <Switch
                onClick={() => {
                  addOrRemoveChannel('email')
                }}
                checked={channels?.includes('email') ?? false}
                sx={{
                  transform: isArabic ? 'scaleX(-1)' : 'none',
                }}
              />
            )}
          </CenterRow>
          <CenterColumn bg="white" p={[2, 2, '16px']} gap={16} width="100%" borderRadius={'10px'} boxShadow="normal">
            <CenterColumn gap="5px">
              <CenterRow flexWrap="wrap" justifyContent="space-between" width="100%">
                <CenterRow gap="10px">
                  <BrowserUpdated />
                  <Text fontWeight="bold" fontStyle="capitalize" fontSize={[16]}>
                    {t('profileSettings:pushNotifications')}
                  </Text>
                </CenterRow>

                {(removing || addingChannel) && channel === 'web push' ? (
                  <Spinner width="25px" />
                ) : (
                  <Switch
                    onClick={(e) => {
                      addOrRemoveChannel('web push')
                    }}
                    checked={channels?.includes('web push') ?? false}
                    sx={{
                      transform: isArabic ? 'scaleX(-1)' : 'none',
                    }}
                  />
                )}
              </CenterRow>
              {!isEnabled && webPushEnabled && (
                <CenterRow gap="5px">
                  <Warning color="error" fontSize="small" />
                  <Text fontSize={[13]} variant="light" color="error">
                    {t('profileSettings:notificationsDisabledDesc')}
                  </Text>
                </CenterRow>
              )}
            </CenterColumn>

            {webPushEnabled && (
              <CenterColumn gap={[16]}>
                <>
                  <Text fontSize={[16]} fontWeight="normal">
                    {t('profileSettings:devices')}
                  </Text>
                  {subscribedDevices?.length ? (
                    subscribedDevices?.map((item) => (
                      <CenterRow
                        flexWrap={['wrap']}
                        bg="white"
                        gap={[16]}
                        height={['auto', 'auto', 'auto', '70px']}
                        justifyContent="space-between"
                        p={[2, 2, '16px']}
                        width="100%"
                        borderRadius={'10px'}
                        boxShadow="normal"
                        key={item?.device_info?.deviceId}
                        opacity={disableCurrentDevice(item) ? 0.5 : 1}
                      >
                        <CenterRow flexWrap="wrap" gap="10px">
                          <Devices />
                          <CenterColumn gap="5px">
                            <Text fontWeight="bold" fontSize={[16]}>{`${item?.device_info?.browserName} (${item?.device_info?.osName})`}</Text>
                            <Text color="textGray">
                              {t('profileSettings:lastActive')}:
                              {dateFormatter({
                                date: item?.device_info?.lastUpdated,
                                formatType: 'Pp',
                              })}
                            </Text>
                          </CenterColumn>
                        </CenterRow>
                        {!disableCurrentDevice(item) ? (
                          <IconButton
                            color="error"
                            onClick={() => {
                              onOpen()
                              setDevice(item)
                            }}
                          >
                            {unsubscribing && item.id === device?.id ? <Spinner width="25px" /> : <Delete />}
                          </IconButton>
                        ) : (
                          `(${t('profileSettings:currentDevice')})`
                        )}
                      </CenterRow>
                    ))
                  ) : (
                    <Text fontSize={[18]} color="textGray">
                      {t('profileSettings:noDevices')}
                    </Text>
                  )}
                </>
              </CenterColumn>
            )}
          </CenterColumn>
        </CenterColumn>
      ) : (
        <Spinner />
      )}
      <ConfirmationModal
        title={`${t('profileSettings:unsubscribeDeviceTitle')} "${device?.device_info?.browserName} (${device?.device_info?.osName})"`}
        message={t('profileSettings:unsubscribeDeviceDescription')}
        onConfirm={() => {
          unsubscribe({
            id: device?.id,
          })
        }}
        open={open}
        onClose={onClose}
        isLoading={unsubscribing}
        cancelText={t('profileSettings:cancel')}
        confirmText={t('profileSettings:confirm')}
      />
      <ConfirmationModal
        title={`${t('profileSettings:removeChannelTitle')} "${channel === 'web push' ? t('profileSettings:pushNotifications') : channel}"`}
        message={t('profileSettings:removeChannelWarning')}
        onConfirm={() => {
          removeChannel({
            channel,
          }).then((res) => {
            // res can be {data:boolean} or {error:unknown}
            if ('data' in res && channel === 'web push') {
              disableNotifications()
            }
          })
        }}
        open={channelController.open}
        onClose={channelController.onClose}
        isLoading={unsubscribing}
        cancelText={t('profileSettings:cancel')}
        confirmText={t('profileSettings:confirm')}
      />
    </Flex>
  )
}
