// Core
import { string, bool } from 'prop-types'
// Styles
import Styles from './styles.module.scss'

export const SaveButton = (props) => {
  const submitBtn = `${!props?.isValid ? Styles.submit_btn_notActive : Styles.submit_btn} ${props?.class}`

  return (
    <button className={submitBtn} disabled={!props?.isValid} type="submit" onClick={props?.action}>
      {props.title}
    </button>
  )
}

SaveButton.propTypes = {
  isValid: bool,
  class: string,
  title: string,
}
