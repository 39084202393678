//Core
import { useEffect, useState } from 'react'

import { formatDateToYYYYMMDD, returnLanguagesArray } from 'utils'
// i18n
import { useTranslation } from 'react-i18next'
// import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
// Form
import { useForm } from 'react-hook-form'
// Components
import { useCreateNewProfileMutation } from 'defaultLayout/slice/service'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { ArrowLeftIcon, ArrowRightIcon } from 'theme/assets/icons'
import { Button, CenterColumn, CenterRow, Text } from 'components/Core'
import { selectChildInfo } from '../slice/selectors'
import { OnboardingStep } from 'defaultLayout/types'
import { structuredQuestionnaire } from 'data/locale.data'
import { MuiTextField } from 'components/Core/TextArea/MuiTextField'
import { FlowContainer } from '../FlowContainer'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

type Props = {
  setOnboardingStep: (onboardingStep: OnboardingStep) => void
  questionnaireData: typeof structuredQuestionnaire
  decreaseQuestionIndex: () => void
}
export const TellUsMore = ({ setOnboardingStep, decreaseQuestionIndex, questionnaireData }: Props) => {
  const { t, i18n } = useTranslation()

  const [addNewChildProfile, { isLoading, isSuccess, data }] = useCreateNewProfileMutation()

  const { handleSubmit, setValue, watch } = useForm({
    mode: 'all',
    defaultValues: {
      additional_info: localStorage.getItem('additional_info') || '',
    },
  })
  const { language, isArabic, direction } = useGetLanguage()
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'report', en)
    i18n.addResourceBundle('ar', 'report', ar)
  }, [])

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('lastSelectedProfile', String(data?.id))
      localStorage.setItem('account_created', 'true')
      localStorage.removeItem('childInfo')
      localStorage.removeItem('questionnaire')
      setOnboardingStep('book_advisory_call')
    }
  }, [data?.id, isSuccess])

  const handleGoBack = () => {
    decreaseQuestionIndex()
    setOnboardingStep('questionnaire')
  }
  const childInfo = useSelector(selectChildInfo)
  const handleGoToAccountCreating = () => {
    const languages = childInfo?.languages?.map((language) => returnLanguagesArray(language))

    const finalObject = {
      payload: {
        child_info: {
          ...childInfo,
          behavior: [],
          birth_date: formatDateToYYYYMMDD(childInfo?.birth_date),
          languages,
        },
        questionnaire: questionnaireData,
        family_dynamics: [],
        parental_wellbeing: [],
      },
    }
    addNewChildProfile({ payload: finalObject.payload })
  }
  return (
    <FlowContainer direction={direction} gap={[10]}>
      <CenterColumn gap="6px">
        <Text variant="heading4">{t('parentOnboarding:tellUsMoreTitle')}</Text>
        <Text variant="body5">{t('parentOnboarding:tellUsMoreSubtitle')}</Text>
      </CenterColumn>
      <MuiTextField
        maxLength={500}
        // {...register('additional_info')}
        onChange={(e) => {
          setValue('additional_info', e.target.value)
          localStorage.setItem('additional_info', e.target.value)
        }}
        name={'additional_info'}
        multiline
        value={watch('additional_info')}
        maxRows={4}
        rows={4}
        placeholder={t('report:textarea:placeholder')}
        showCharacterCounter
      />

      <Button disabled={isLoading} style={{ width: '100%' }} onClick={handleSubmit(handleGoToAccountCreating)}>
        {isLoading ? <CircularProgress sx={{ color: 'white' }} size={25} /> : t('report:call_to_action')}
      </Button>
      <CenterRow>
        <Button direction={'ltr'} flexDirection={isArabic ? 'row-reverse' : 'row'} onClick={handleGoBack} variant="ghost" color="textLightGray">
          {isArabic ? <ChevronRight /> : <ChevronLeft />}
          <Text fontSize={17}>{t('add_child:footer:back')}</Text>
        </Button>
      </CenterRow>
    </FlowContainer>
  )
}
