import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { userTypes } from '../../../constants'

/**
 * Generates a Yup schema validation object for user settings based on the provided user type and password status.
 *
 * @param {string} userType - The type of user (e.g. 'advisor', 'parent', 'therapist', 'admin').
 * @param {boolean} has_password - Indicates whether the user has a password set.
 * @param {function} t - A translation function for localized error messages.
 * @returns {object} - A Yup schema validation object.
 */
export const schemaValid = (userType, has_password, t) => {
  return yupResolver(
    Yup.object().shape({
      full_name: Yup.string()
        .required(t('validation:required'))
        .min(3, t('validation:atLeastThreeCharLong'))
        .max(30, t('validation:noMoreThan30CharLong'))
        .matches(/^[a-zA-Z\u0600-\u06FF\s.]*$/i, t('validation:nameShouldNotContainNumber')),
      bio: userType === userTypes.therapist ? Yup.string().max(2000, t('validation:noMoreThan510CharLong')).nullable() : Yup.string(),
      email: Yup.string()
        .required(t('validation:required'))
        .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, t('validation:invalidEmail')),
      // languages:
      //   userType === 'advisor'
      //     ? Yup.array()
      //         .of(
      //           Yup.lazy((value) => {
      //             switch (typeof value) {
      //               case 'object':
      //                 return Yup.object().shape({
      //                   value: Yup.string()
      //                     .min(3, t('validation:atLeastThreeCharLong'))
      //                     .max(30, t('validation:noMoreThan30CharLong'))
      //                     .matches(/^[^0-9а-я]*$/i, 'This field should not contain numbers'),
      //                 })
      //               case 'string':
      //                 return Yup.string()
      //                   .min(3, t('validation:atLeastThreeCharLong'))
      //                   .max(30, t('validation:noMoreThan30CharLong'))
      //                   .matches(/^[^0-9а-я]*$/i, t('validation:nameShouldNotContainNumber'))
      //               default:
      //                 return Yup.mixed()
      //             }
      //           }),
      //         )
      //         .nullable(true)
      //         .required(t('validation:required'))
      //         .min(1, t('validation:shouldHaveAtLeastOneOption'))
      //         .max(5, t('validation:noMOreThan5CharLong'))
      //     : Yup.array(),
      country: userType !== 'admin' ? Yup.object().nullable(true).required(t('validation:required')) : Yup.string().nullable(true),
      old_password: (userType === 'parent' || userType === 'therapist') && has_password ? Yup.string() : Yup.string().nullable(true),
      password: userType === 'parent' || userType === 'therapist' ? Yup.string() : Yup.string().nullable(true),
    }),
  )
}
