import { createContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const AuthContext = createContext({})
type Props = {
  children: React.ReactNode
}
export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const user_type = localStorage.getItem('user_type') || null

    let dashboardLink
    switch (String(user_type)) {
      case 'admin':
        dashboardLink = '/admin_panel/admin/parents'
        break
      case 'advisor':
        dashboardLink = '/admin_panel/advisor/applications'
        break
      case 'therapist':
        dashboardLink = '/profile/therapist/applications'
        break
      case 'parent':
        dashboardLink = '/profile/parent/therapy'
        break
    }
    if (pathname === '/' || pathname === '/login' || pathname.includes('signup')) {
      navigate(dashboardLink ?? '/')
    }
  }, [pathname])

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>
}
