import { Box, Button, CenterColumn, CenterRow, Container, Text } from 'components/Core'
import { MuiTextField } from 'components/Core/TextArea/MuiTextField'
import { AuthWithGoogle } from './AuthWithGoogle'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signupValidation } from './validation/signup'
import { useContentBundle } from 'hooks/useContentBundle'
import { arabicValidationErrors } from 'locales/validations/arabicValidationErrors'
import { englishValidationErrors } from 'locales/validations/englishValiationErrors'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useEffect, useMemo, useState } from 'react'
import Turnstile from 'react-turnstile'
import { useSignUpUserMutation } from 'defaultLayout/slice/service'
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material'
import { notify } from 'helpers'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { theme } from 'utils/theme'
import { Check, Visibility, VisibilityOff } from '@mui/icons-material'
import { useDisclosure } from 'hooks/useDisclosure'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'actions'
import { selectIsUserLoggingIn } from 'defaultLayout/slice/selectors'
import { useCheckPromoCodeMutation } from 'components/Auth/ParentOnboarding/FormRegistration/service'
import { useDebounce } from 'utils/hook/useDebounce'
import { Spinner } from 'components/ChatSystem/Common/Spinner'

const GlassCard = styled(Box)`
  // background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  // padding: 40px;
  // box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 670px;
  margin: 20px;
`

const StyledTextField = styled(MuiTextField)`
  border-radius: 12px;
  margin-bottom: 16px;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }
`

const AnimatedButton = styled(Button)`
  border-radius: 12px;
  padding: 12px 24px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`

const GradientText = styled(Text)`
  background: linear-gradient(45deg, #6d308d 30%, #6d308d 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
export const ParentSignUp = () => {
  const navigate = useNavigate()
  const { direction, language, isArabic } = useGetLanguage()
  const { t } = useContentBundle({
    englishContent: englishValidationErrors,
    arabicContent: arabicValidationErrors,
    key: 'validation',
  })
  useContentBundle({
    englishContent: en,
    arabicContent: ar,
    key: 'parentOnboarding',
    language,
  })
  const { formState, handleSubmit, setValue, watch, getValues, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
      full_name: '',
      promo_code: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(signupValidation(t)),
    mode: 'onChange',
  })
  const [signUp, { isLoading, isSuccess, isError, error }] = useSignUpUserMutation()
  const isUserLoggingIn = useSelector(selectIsUserLoggingIn)
  const onSubmit = (data: any) => {
    signUp(data)
  }
  const isLocalDevelopment = process.env.NODE_ENV === 'development'
  const [captcha, setCaptcha] = useState(isLocalDevelopment)
  const dispatch = useDispatch()
  useEffect(() => {
    if (isSuccess) {
      notify(t('parentOnboarding:signupSuccess'), false)
      localStorage.removeItem('promo_code')
      dispatch(
        authActions.loginUser({
          email: watch('email'),
          password: watch('password'),
        }) as any,
      )
    }
    if (isError) {
      notify(t(`parentOnboarding:${(error as any).data}`), true)
    }
  }, [isSuccess, isError, error])
  const passwordVisible = useDisclosure()
  const confirmPasswordVisible = useDisclosure()
  const [searchParams] = useSearchParams()
  const [checkPromoCode, { data, isLoading: checkingPromoCode, isSuccess: promoCodeExist }] = useCheckPromoCodeMutation()
  /**Check Promo Code */
  const handlePromoCodeCheck = () => {
    const promoCode = getValues('promo_code')
    if (promoCode) {
      checkPromoCode(promoCode.toUpperCase())
    }
  }
  const watchPromoCode = useWatch({
    name: 'promo_code',
    control,
  })

  useDebounce(handlePromoCodeCheck, getValues('promo_code'), watchPromoCode)
  const promoCodeError = useMemo(() => {
    const promoCode = getValues('promo_code')
    if (!promoCode) {
      return null
    }
    if (data?.errorMessage) return { message: t(`parentOnboarding:errorMessages:${data?.errorMessage}`) }
    return null
  }, [data?.errorMessage, watchPromoCode])

  /**PROMO CODE CHECK END */

  useEffect(() => {
    const referral = searchParams.get('referral')
    if (referral) {
      localStorage.setItem('promo_code', String(referral))
      setValue('promo_code', referral)
    }
  }, [searchParams.get('referral')])
  return (
    <Container minHeight="100%">
      <GlassCard p={[2, 2, 10, 40]} bg="transparent">
        <CenterColumn gap={[24]} width={['100%']}>
          <CenterColumn width={['100%']}>
            <GradientText textAlign="center" variant="heading2">
              {t('parentOnboarding:welcomeToNumuw')}
            </GradientText>
            <Text color="textLightGray" textAlign="center" variant="body5">
              {t('parentOnboarding:startYourJourney')}
            </Text>
          </CenterColumn>

          <CenterColumn direction={direction} gap="24px" width="100%">
            <CenterRow gap="10px" justifyContent="space-between" width="100%" flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}>
              <Box flexGrow={1}>
                <MuiTextField
                  name="full_name"
                  onChange={(e) => {
                    setValue('full_name', e.target.value, { shouldValidate: true })
                  }}
                  error={Boolean(formState.errors.full_name)}
                  placeholder={t('parentOnboarding:fullName')}
                  fullWidth
                  helperText={formState.errors.full_name?.message}
                  sx={{
                    direction,
                  }}
                />
              </Box>
              <Box flexGrow={1}>
                <MuiTextField
                  name="email"
                  onChange={(e) => {
                    setValue('email', e.target.value, { shouldValidate: true })
                  }}
                  error={Boolean(formState.errors.email)}
                  placeholder={t('parentOnboarding:email')}
                  fullWidth
                  helperText={formState.errors.email?.message}
                  sx={{
                    direction: 'ltr',
                    input: {
                      direction: 'ltr',
                    },
                    '& ::placeholder': {
                      direction,
                      width: '100%',
                      textAlign: isArabic ? 'right' : 'left',
                    },
                  }}
                />
              </Box>
            </CenterRow>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        passwordVisible.toggle()
                      }}
                    >
                      {passwordVisible.open ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              name="password"
              onChange={(e) => {
                setValue('password', e.target.value, { shouldValidate: true })
                if (watch('confirm_password')) {
                  setValue('confirm_password', watch('confirm_password'), { shouldValidate: true })
                }
              }}
              error={Boolean(formState.errors.password)}
              placeholder={t('parentOnboarding:password')}
              type={passwordVisible.open ? 'text' : 'password'}
              fullWidth
              helperText={formState.errors.password?.message}
              sx={{
                direction,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: theme.colors.white,
                },
                borderColor: theme.colors.black[300],
                ':hover': {
                  borderColor: 'none',
                },
              }}
            />
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        confirmPasswordVisible.toggle()
                      }}
                    >
                      {confirmPasswordVisible.open ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              name="confirm_password"
              onChange={(e) => {
                setValue('confirm_password', e.target.value, { shouldValidate: true })
              }}
              error={Boolean(formState.errors.confirm_password)}
              placeholder={t('parentOnboarding:confirmPassword')}
              type={confirmPasswordVisible.open ? 'text' : 'password'}
              fullWidth
              helperText={formState.errors.confirm_password?.message}
              sx={{
                direction,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: theme.colors.white,
                },
                borderColor: theme.colors.black[300],
                ':hover': {
                  borderColor: 'none',
                },
              }}
            />
            <CenterRow width="100%" position="relative">
              {!searchParams.get('referral') && (
                <MuiTextField
                  onChange={(e) => {
                    setValue('promo_code', e.target.value, { shouldValidate: true })
                    localStorage.setItem('promo_code', String(e.target.value))
                  }}
                  placeholder={t('parentOnboarding:promoCode')}
                  error={Boolean(promoCodeError)}
                  name="promo_code"
                  helperText={promoCodeError?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: promoCodeExist && !data?.errorMessage ? <Check color="success" /> : checkingPromoCode ? <Spinner width={'25px'} /> : null,
                  }}
                />
              )}

              {promoCodeError?.message && searchParams.get('referral') && <Text variant="error">{promoCodeError?.message}</Text>}
            </CenterRow>
            <CenterRow justifyContent="flex-end">
              {!isLocalDevelopment && (
                <Turnstile sitekey={process.env.REACT_APP_SITE_KEY as string} onError={() => setCaptcha(false)} onVerify={(token) => token && setCaptcha(true)} theme={'light'} />
              )}
            </CenterRow>
            <AnimatedButton
              disabled={!formState.isValid || !captcha || isLoading || isUserLoggingIn || Boolean(promoCodeError?.message)}
              onClick={handleSubmit(onSubmit)}
              width={['100%']}
            >
              {isLoading || isUserLoggingIn ? <CircularProgress size={25} /> : t('parentOnboarding:createAccount')}
            </AnimatedButton>
          </CenterColumn>

          <Text textAlign="center" color="textLightGray">
            {t('parentOnboarding:continueWith')}
          </Text>

          <AuthWithGoogle disabled={Boolean(promoCodeError?.message)} direction={direction} label={t('parentOnboarding:signUpWithGoogle')} />

          <CenterRow width="100%" justifyContent={['center']} direction={direction}>
            <Text variant="light">{t('parentOnboarding:alreadyHaveAccount')}</Text>
            <Button
              onClick={() => {
                navigate('/login')
              }}
              variant="ghost"
            >
              {t('parentOnboarding:login')}
            </Button>
          </CenterRow>
        </CenterColumn>
      </GlassCard>
    </Container>
  )
}
