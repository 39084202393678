import { styled } from '@mui/material/styles'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { useState } from 'react'
import { Button, CenterRow, Container, Text } from 'components/Core'
import { Progress } from './slice/types'
import { Paper, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow } from '@mui/material'
import { dateFormatter } from 'utils/utils'
import { objectiveTypes, qualitativeValueMap } from 'constants/index'
import { theme } from 'utils/theme'
import { CustomTabs } from 'components/shared/CustomTab/Tabs'
import { QuantitativeProgressChart } from './QuantitativeProgressChart'
import { useUserTypeCheck } from 'hooks/useUserTypeCheck'
import { TFN } from 'utils/types/types'
import { NoteVisibilitySwitch } from 'components/shared/NoteVisibilitySwitch'
import { useUpdateNoteVisibilityMutation } from '../ParentTask/slice/services'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useDisclosure } from 'hooks/useDisclosure'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  // borderTop: `1px solid ${theme.palette.divider}`,
  // borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'transparent',

  paddingLeft: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: '1.5rem' }} />} {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  justifyContent: 'space-between',
  ':hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {},
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
}))

type Props = {
  progresses: Progress[] | undefined
  type: string | undefined
  baselinePercentage: number | undefined
  targetPercentage: number | undefined
  objectiveCreatedAt: string
  t: TFN
  isLoadingPlans: boolean
}
export const Progresses = ({ progresses, type, baselinePercentage, targetPercentage, objectiveCreatedAt, isLoadingPlans, t }: Props) => {
  const [expanded, setExpanded] = useState<boolean>()

  const handleChange = () => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded)
  }
  return (
    <Accordion sx={{ width: '100%' }} expanded={expanded} onChange={handleChange}>
      <AccordionSummary sx={{ gap: 2, direction: 'ltr' }} aria-controls="panel1d-content">
        <Text textAlign="right" width="100%" variant="body1">
          {t('treatmentPlanPlusTask:progress')}
        </Text>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'white', borderRadius: '10px', px: 1, py: 1 }}>
        {type === objectiveTypes.QUALITATIVE && <QualitativeProgressTable isLoadingPlans={isLoadingPlans} t={t} progresses={progresses} />}
        {type === objectiveTypes.QUANTITATIVE && (
          <CustomTabs
            tabs={[
              {
                value: 'chart',
                label: t('treatmentPlanPlusTask:chartView'),
                component: progresses?.length ? (
                  <QuantitativeProgressChart
                    objectiveCreatedAt={objectiveCreatedAt}
                    progresses={progresses}
                    baselinePercentage={baselinePercentage}
                    targetPercentage={targetPercentage}
                    t={t}
                    isLoadingPlans={isLoadingPlans}
                  />
                ) : (
                  <Container py={3}>{t('treatmentPlanPlusTask:noProgress')}</Container>
                ),
              },
              {
                value: 'table',
                label: t('treatmentPlanPlusTask:tableView'),
                component: (
                  <QuantitativeProgressTable
                    isLoadingPlans={isLoadingPlans}
                    t={t}
                    baselinePercentage={baselinePercentage}
                    targetPercentage={targetPercentage}
                    progresses={progresses}
                  />
                ),
              },
            ]}
          />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

type QualitativeProgressTableProps = { progresses: Progress[] | undefined; t: TFN; isLoadingPlans: boolean }
export function QualitativeProgressTable({ progresses, t, isLoadingPlans }: QualitativeProgressTableProps) {
  const { isArabic } = useGetLanguage()
  const [changingSwitchIndex, setChangingSwitchIndex] = useState<number>()
  const rows = progresses?.map((progress) => ({
    id: progress.id,
    date: progress.session.date,
    accuracy: progress.qualitative_value,
    note: progress.note,
    note_visible_to_parent: progress.note_visible_to_parent,
  }))
  const { isUserParent, isUserTherapist } = useUserTypeCheck()
  const [updateNoteVisibility, { isLoading }] = useUpdateNoteVisibilityMutation()
  const HOCTableCell = (props: TableCellProps) => <TableCell align={isArabic ? 'right' : 'left'} {...props} />
  return progresses?.length ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <HOCTableCell>{t('treatmentPlanPlusTask:session')}</HOCTableCell>
            <HOCTableCell>{t('treatmentPlanPlusTask:comment')}</HOCTableCell>
            <HOCTableCell>{t('treatmentPlanPlusTask:note')}</HOCTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <HOCTableCell component="th" scope="row">
                {dateFormatter({ date: row.date, formatType: 'PP' })}
              </HOCTableCell>
              <HOCTableCell>{t(`treatmentPlanPlusTask:${qualitativeValueMap[row.accuracy as keyof typeof qualitativeValueMap]}`) ?? row.accuracy}</HOCTableCell>
              <HOCTableCell>
                <CenterRow gap="5px">
                  {isUserTherapist && row.note && (
                    <NoteVisibilitySwitch
                      value={row.note_visible_to_parent}
                      changingSwitchIndex={changingSwitchIndex}
                      isLoading={isLoading}
                      onChange={(e) => {
                        setChangingSwitchIndex(index)
                        updateNoteVisibility({
                          id: row.id,
                          value: e.target.checked,
                        })
                      }}
                      index={index}
                    />
                  )}
                  <NoteView note={row.note} isUserParent={isUserParent} note_visible_to_parent={row.note_visible_to_parent} />
                </CenterRow>
              </HOCTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Container py={3}>{t('treatmentPlanPlusTask:noProgress')}</Container>
  )
}
type QuantitativeTableProps = { progresses: Progress[] | undefined; baselinePercentage: number | undefined; targetPercentage: number | undefined; t: TFN; isLoadingPlans: boolean }
const QuantitativeProgressTable = ({ progresses, baselinePercentage, targetPercentage, t, isLoadingPlans }: QuantitativeTableProps) => {
  const [changingSwitchIndex, setChangingSwitchIndex] = useState<number>()
  const rows = progresses?.map((progress) => ({
    id: progress.id,
    date: progress.session.date,
    accuracy: progress.quantitative_value ?? progress.qualitative_value,
    note: progress.note,
    note_visible_to_parent: progress.note_visible_to_parent,
  }))
  const { isUserParent, isUserTherapist } = useUserTypeCheck()
  const [updateNoteVisibility, { isLoading }] = useUpdateNoteVisibilityMutation()
  const { isArabic } = useGetLanguage()
  const HOCTableCell = (props: TableCellProps) => <TableCell align={isArabic ? 'right' : 'left'} {...props} />
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <HOCTableCell>{t('treatmentPlanPlusTask:sessionDate')}</HOCTableCell>
            <HOCTableCell>{t('treatmentPlanPlusTask:progress')}</HOCTableCell>
            <HOCTableCell>{t('treatmentPlanPlusTask:note')}</HOCTableCell>
          </TableRow>
          <TableRow>
            <HOCTableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>{t('treatmentPlanPlusTask:baselinePercentage')}</HOCTableCell>
            <HOCTableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>{baselinePercentage}%</HOCTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <HOCTableCell sx={{ pl: 10 }} component="th" scope="row">
                {dateFormatter({ date: row.date, formatType: 'PP' })}
              </HOCTableCell>
              <HOCTableCell>{row.accuracy}%</HOCTableCell>
              <HOCTableCell sx={{ minWidth: '300px' }}>
                <CenterRow gap="5px">
                  {isUserTherapist && row.note && (
                    <NoteVisibilitySwitch
                      value={row.note_visible_to_parent}
                      changingSwitchIndex={changingSwitchIndex}
                      isLoading={isLoading}
                      onChange={(e) => {
                        setChangingSwitchIndex(index)
                        updateNoteVisibility({
                          id: row.id,
                          value: e.target.checked,
                        })
                      }}
                      index={index}
                    />
                  )}
                  <NoteView note={row.note} isUserParent={isUserParent} note_visible_to_parent={row.note_visible_to_parent} />
                </CenterRow>
              </HOCTableCell>
            </TableRow>
          ))}
          <TableRow>
            <HOCTableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>{t('treatmentPlanPlusTask:targetPercentage')}</HOCTableCell>
            <HOCTableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>{targetPercentage}%</HOCTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type NoProps = {
  note: string | undefined
  isUserParent: boolean
  note_visible_to_parent: boolean
}
const NoteView = ({ note, isUserParent, note_visible_to_parent }: NoProps) => {
  const { open, onOpen } = useDisclosure()
  return (
    <CenterRow gap="10px">
      <Text truncateLines={3} style={{ textWrap: 'initial' }}>
        {!isUserParent ? note : note_visible_to_parent ? note : '--'}
      </Text>
    </CenterRow>
  )
}
