import type { ABTestingState, AlertState, DefaultLayoutState } from 'defaultLayout/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import type { PushSubscriptionData } from 'serviceWorker/type'
import type { User } from 'utils/types/types'

const initialState: DefaultLayoutState = {
  alert: {
    open: false,
    message: '',
    type: 'info',
  },
  zoomLeaveUrl: localStorage.getItem('zoomLeaveUrl') || process.env.REACT_APP_ZOOM_LEAVE_URL || '',
  abTesting: {
    variant: 'A',
    onboardingStep: 'add_child',
    addChildStep: 1,
    questionnaireStatementStep: 0,
  },
  openedTab: '',
  firebaseToken: '',
  isAuthenticating: true,
  isUserLoggingIn: false,
  subscription: {},
  user: {},
}

export const defaultLayoutSlice = createSlice({
  name: 'defaultLayout',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<Partial<User>>) {
      state.user = action.payload
    },
    setSubscription(state, action: PayloadAction<Partial<PushSubscriptionData> | undefined>) {
      state.subscription = action.payload
    },
    setFirebaseToken: (state, action: PayloadAction<string | undefined | null>) => {
      state.firebaseToken = action.payload
    },
    setIsAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticating = action.payload
    },
    setIsLoggingIn(state, { payload }: PayloadAction<boolean>) {
      state.isUserLoggingIn = payload
    },
    setAlert: (state, { payload }: PayloadAction<Partial<Omit<AlertState, 'open'>>>) => {
      state.alert = { ...state.alert, ...payload, open: true }
    },
    closeAlert: (state) => {
      state.alert = { ...initialState.alert }
    },
    setZoomLeaveUrl: (state, { payload }: PayloadAction<string>) => {
      state.zoomLeaveUrl = payload
    },
    setABTestingState: (state, { payload }: PayloadAction<Partial<ABTestingState>>) => {
      state.abTesting = { ...state.abTesting, ...payload }
    },
    setOpenedTab(state, action: PayloadAction<string | number>) {
      state.openedTab = action.payload
    },
  },
})

export const useDefaultLayoutSlice = () => {
  const dispatch = useDispatch()
  const { actions } = defaultLayoutSlice
  type Options = { url: string }
  const setZoomLeaveUrl = (options?: Options) => {
    localStorage.setItem('zoomLeaveUrl', options?.url ?? window.location.href)
    dispatch(actions.setZoomLeaveUrl(options?.url ?? window.location.href))
  }
  return { actions, dispatch, setZoomLeaveUrl }
}

export const defaultLayoutReducer = defaultLayoutSlice.reducer
export const defaultLayoutActions = defaultLayoutSlice.actions
