import { useCallback, useMemo } from 'react'
import { CourseJourneyItem } from 'utils/types/types'

export const useCourseProgress = (journey?: Array<CourseJourneyItem> | undefined) => {
  const getProgress = useCallback((journey: Array<CourseJourneyItem> | undefined) => {
    const completedLessons = journey?.filter((item) => item.completed).length || 0
    const totalLessons = journey?.length || 1
    return Math.floor((completedLessons / totalLessons) * 100)
  }, [])
  const progress = useMemo(() => {
    const completedLessons = journey?.filter((item) => item.completed).length || 0
    const totalLessons = journey?.length || 1
    return (completedLessons / totalLessons) * 100
  }, [journey])

  return {
    progress,
    getProgress,
  }
}
