import { useDisclosure } from 'hooks/useDisclosure'
import { useUserTypeCheck } from 'hooks/useUserTypeCheck'
import { useState, useEffect } from 'react'

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>
}

export const usePWA = () => {
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent | null>(null)
  const { isUserParent, isUserTherapist } = useUserTypeCheck()
  const [isInstalling, setIsInstalling] = useState<boolean>(false)
  const [isInstalled, setIsInstalled] = useState<boolean>(JSON.parse(localStorage.getItem('isInstalled') ?? 'false'))
  const [doNotShow, setDoNotShow] = useState<boolean>(JSON.parse(localStorage.getItem('donNotShow') ?? 'false'))
  const [remindMeLater, setRemindMeLater] = useState<Date>(localStorage.getItem('remindMeLater') ? new Date(localStorage.getItem('remindMeLater') as string) : new Date())

  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault()
      setInstallPrompt(e)
    }

    const installHandler = () => {
      setIsInstalling(false)
    }

    window.addEventListener('beforeinstallprompt', handler as any)
    window.addEventListener('appinstalled', installHandler)

    return () => {
      window.removeEventListener('beforeinstallprompt', handler as any)
      window.removeEventListener('appinstalled', installHandler)
    }
  }, [])

  const { open, onOpen, onClose } = useDisclosure()
  const handleInstallApp = async () => {
    setIsInstalling(true)
    setIsInstalled(true)
    localStorage.setItem('isInstalled', 'true')
    try {
      await installPrompt?.prompt()
      const result = await installPrompt?.userChoice

      if (result?.outcome !== 'accepted' && installPrompt?.userChoice) {
        setIsInstalling(false)
        localStorage.setItem('isInstalled', 'false')
        throw new Error('User dismissed the installation prompt')
      }
    } catch (error) {
      console.log(error)
    }
  }
  // @ts-ignore
  const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://')
  const showPrompt = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    const isMobile = regex.test(navigator.userAgent)
    if (isInStandaloneMode()) return
    if (!isUserParent && !isUserTherapist) return
    if (!isMobile) return
    if (doNotShow) return
    if (isInstalled) return
    if (remindMeLater < new Date()) {
      onOpen()
      return
    }
  }

  const handleRemindMeLater = () => {
    const dateToRemindOn = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    setRemindMeLater(dateToRemindOn)
    localStorage.setItem('remindMeLater', dateToRemindOn.toISOString())
    onClose()
  }
  const handleDoNotShow = () => {
    setDoNotShow(true)
    localStorage.setItem('donNotShow', 'true')
    onClose()
  }

  useEffect(() => {
    showPrompt()
  }, [])

  return {
    isInstalled,
    isInstalling,
    handleInstallApp,
    remindMeLater,
    doNotShow,
    open,
    onClose,
    showPrompt,
    setRemindMeLater,
    handleRemindMeLater,
    handleDoNotShow,
  }
}
