// Core
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
//Actions
import { clearError } from '../../../reducers/generalSlice'
import { verificationActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, TextFieldPassword, Loader, SaveButton } from '../../GeneralComponents'
//Icons
import { ReactComponent as SuccessIcon } from '../../../theme/assets/images/thanks.svg'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { authInstance } from 'utils/utils'
import { Button } from 'components/Core'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { notify } from 'helpers'

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email address')
    .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com'),
})

export const SignInWithMagicLink = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const error = useSelector((state) => state.general.error)
  // const loading = useSelector((state) => state.general.loading)
  const user = useSelector((state) => state.auth.user)

  const [isSuccess, setIsSuccess] = useState(false)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email, { shouldValidate: true })
    }
  }, [user?.email])

  const handleSignIn = (data) => {
    // const dataForSubmit = {
    //   payload: {
    //     email: data.email,
    //   },
    // }

    // dispatch(verificationActions.createVerificationLink(dataForSubmit)).then((res) => {
    //   if (res.status === 200) {
    //     setIsSuccess(true)
    //   }
    // })
    setLoading(true)
    sendSignInLinkToEmail(authInstance, data?.email, {
      url: `${process.env.REACT_APP_SITE_URL}/verify`,
      handleCodeInApp: true,
    })
      .then(() => {
        localStorage.setItem('emailForSignIn', data?.email)
        setIsSuccess(true)
      })
      .catch((error) => {
        console.log(error)
        notify(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signInWithEmail = () => {
    dispatch(clearError())
    navigate(-1)
  }

  return (
    <section className={Styles.main}>
      <div className={Styles.form_container}>
        {error && (
          <div className={Styles.signIn_error}>
            <span />
            <span>{error}</span>
          </div>
        )}
        {!isSuccess ? (
          <>
            <div className={Styles.header}>
              <h4>Sign in</h4>
              <span className={Styles.alt_login}>
                You can also sign in with <span onClick={signInWithEmail}>email and password</span>
              </span>
            </div>
            <div className={Styles.input}>
              <TextField label={'Email'} class={Styles.form_item} requiredIcon={' *'} placeholder={'youremail@gmail.com'} error={errors?.email} register={register('email')} />
            </div>
            <Button onClick={handleSubmit(handleSignIn)} width="100%" disabled={!(isValid && !loading)}>
              {loading ? <Spinner width="25px" /> : 'Send a link'}
            </Button>
            {/* <SaveButton class={Styles.login_btn} isValid={isValid && !loading} title={'Send a link'} action={handleSubmit(handleSignIn)} /> */}
          </>
        ) : (
          <div className={Styles.success_conatiner}>
            <SuccessIcon />
            <div className={Styles.success_conatiner__text}>
              <h4>Link sent successfully!</h4>
              <p>We've sent a one-time authentication link to your email. Please check your inbox (and spam folder, just in case) to proceed. This link is valid for 15 minutes.</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
