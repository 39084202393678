// Core
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Components
import { ChildProfileTherapistHistory, GeneralProfileInformation } from '../../components'
// Actions
import { therapistActions } from '../../actions'
import { childOnboardingQuestions } from '../../data'
import { ParentProfileQuestionnairePartWithTranslation } from '../../components/ClientProfileComponents/QuestionnairePartWithTranslation'
import { Box, Flex } from 'components/Core'
import { CustomTabs } from 'components/shared/CustomTab/Tabs'
import { TreatmentPlan } from '../ShardPage/TreatmentPlan/TreatmentPlan'
import { ParentTask } from '../ShardPage/ParentTask/ParentTask'
import { CustomAccordion } from 'components/shared/CustomAccordion'
import { theme } from 'utils/theme'

export const ChildProfilePage = () => {
  const dispatch = useDispatch()

  const currentProfile = useSelector((state) => state.therapist.oneApplication)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [advisorInfo, setAdvisorInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)
  useEffect(() => {
    if (profileId) {
      const dataForRequest = {
        id: profileId,
      }

      dispatch(therapistActions.getOneApplication(dataForRequest))
    }
  }, [profileId])
  const onboardingQuestions = useMemo(() => {
    return Array.isArray(currentProfile?.onboarding) ? currentProfile?.onboarding : Object.values(currentProfile?.onboarding || {})
  }, [currentProfile?.onboarding])
  const returnPreviousOnboarding = () => {
    if (currentProfile?.onboarding) {
      const finalOnboarding = []

      // const onboardingQuestions = Array.isArray(currentProfile?.onboarding) ? currentProfile?.onboarding : Object.values(currentProfile?.onboarding || {})

      for (const sections of childOnboardingQuestions) {
        try {
          for (const onboardSection of onboardingQuestions) {
            if (sections?.id === onboardSection?.id)
              finalOnboarding?.push({ id: sections?.id - 1, main_statement: sections?.question, main_statement_answer: onboardSection?.answer })
          }
        } catch (error) {}
      }

      return finalOnboarding
    }
  }

  const returnNewOnboarding = () => {
    if (currentProfile?.questionnaire?.questionnaire) {
      const finalOnboarding = []

      try {
        // const onboardingQuestions = Array.isArray(currentProfile?.onboarding) ? currentProfile?.onboarding : Object.values(currentProfile?.onboarding || {})
        for (const sections of currentProfile?.questionnaire?.questionnaire) {
          for (const onboardSection of onboardingQuestions) {
            if (sections?.id === onboardSection?.id) finalOnboarding?.push({ ...sections, advisor_comment: onboardSection?.advisor_comment })
          }
        }
      } catch (error) {}

      return finalOnboarding
    } else {
      return null
    }
  }

  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.full_name,
        age: currentProfile?.age,
        birth_date: currentProfile?.birth_date,
        languages: currentProfile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
        parent_preferred_language: currentProfile?.parent?.preferred_language ?? 'en',
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const advisorInfo = {
        full_name: currentProfile?.advisor,
      }

      const questionnaire = {
        familyDynamics: currentProfile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.questionnaire?.parental_wellbeing,
        questionnaire: returnNewOnboarding() || returnPreviousOnboarding(),
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setAdvisorInfo(advisorInfo)
      setQuestionnaire(questionnaire)
    }
  }, [currentProfile])

  const therapistChildProfileTabs = [
    {
      label: 'User profile',
      value: 0,
      component: (
        <>
          <Box flexGrow={[0, 0, 1]}>
            <GeneralProfileInformation profile={profileInfo} client={clientInfo} advisor={advisorInfo} questionnaire={questionnaire} />
          </Box>
          <Box p={[1, 1, 3]} bg="white" height={['50%']}>
            <CustomAccordion
              accordionSummaryProps={{
                sx: {
                  backgroundColor: theme.colors.black[100],
                  borderRadius: '10px',
                  px: 1,
                },
              }}
              titleAlignment="left"
              title={'Questionnaire and Reports'}
            >
              <ParentProfileQuestionnairePartWithTranslation
                advisor_additional_comment={onboardingQuestions?.[onboardingQuestions?.length - 1]?.additional_advisor_comments}
                questionnaire={questionnaire}
                profile={profileInfo}
                style={{ width: '100%' }}
              />
            </CustomAccordion>
          </Box>
        </>
      ),
    },
    { label: 'Treatment Plan', value: 1, component: <TreatmentPlan allowEdit name={profileInfo?.full_name} profile_id={profileId} /> },
    { label: 'Parent Task', value: 2, component: <ParentTask expandAllAccordion profile_id={profileId} profile_name={profileInfo?.full_name} /> },
  ]
  return (
    <Flex padding={['5px', '10px', '20px']} pb={['20px']} px={['30px']} gap={['10px']} flexDirection={['column', 'column', 'column', 'column', 'row']}>
      <Flex gap={['10px']} flexDirection={['column']} width={['100%', '100%', '100%', '100%', '60%']} height={['auto', 'auto', 'auto', 'auto', '90vh']}>
        <CustomTabs tabs={therapistChildProfileTabs} />
      </Flex>
      <Box width={['100%', '100%', '100%', '100%', '40%']}>
        <ChildProfileTherapistHistory profile_id={profileId} />
      </Box>
    </Flex>
  )
}
