import { useState, useEffect } from 'react'
import { NotificationService } from './notification-service'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'

export const useNotifications = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const { dispatch, actions } = useDefaultLayoutSlice()
  const notificationService = NotificationService.getInstance()

  useEffect(() => {
    checkPermission()
  }, [])

  const checkPermission = async () => {
    if ('Notification' in window) {
      setIsEnabled(Notification.permission === 'granted')
    }
  }

  const enableNotifications = async () => {
    await notificationService.init((value) => {
      dispatch(actions.setSubscription(value))
    })
    await checkPermission()
  }

  const disableNotifications = async () => {
    try {
      await notificationService.unsubscribe()
    } catch (error) {
      console.log(error)
    }
    await checkPermission()
  }

  return {
    isEnabled,
    enableNotifications,
    disableNotifications,
  }
}
