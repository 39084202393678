export const ONBOARDING_STEP = {
  ADD_CHILD: 'add_child',
  QUESTIONNAIRE: 'questionnaire',
  FAMILY_DYNAMICS_DISCLAIMER: 'family_dynamics_disclaimer',
  FAMILY_DYNAMICS: 'family_dynamics',
  PARENTAL_WELLBEING_DISCLAIMER: 'parental_wellbeing_disclaimer',
  PARENTAL_WELLBEING: 'parental_wellbeing',
  REPORT: 'report',
  FORM_REGISTRATION: 'form_registration',
  SUCCESS: 'success',
  CHILD_FORM: 'child_form',
  BOOK_ADVISORY_CALL: 'book_advisory_call',
}

export const OTHER_OPTION = 'Other (please specify)'

export const SKIPPED_VALUE = 'Skipped'

export const SUB_STATEMENTS_ANSWERS = {
  0: 'Not at all',
  1: 'Sometimes',
  2: 'Often',
}

export const MAIN_STATEMENTS_ANSWERS = {
  0: 'Yes',
  1: 'No',
}

export const PARENT_ONBOARDING_COOKIES_NAME = {
  PARENT_ONBOARDING: 'parent_onboarding',
  QUESTIONNAIRE: 'questionnaire',
  FAMILY_DYNAMICS: 'family_dynamics',
  PARENTAL_WELLBEING: 'parental_wellbeing',
}
