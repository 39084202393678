import { Search } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { ComponentProps, useCallback, useRef } from 'react'

export const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>()

  return useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay],
  )
}
interface Props extends ComponentProps<typeof TextField> {
  getValueChange: (value: string) => void
}
export const SearchInput = ({ getValueChange, ...rest }: Props) => {
  const debouncedSearch = useDebounce(getValueChange, 500)

  return (
    <TextField
      placeholder=""
      InputProps={{
        endAdornment: <Search />,
      }}
      onChange={(e) => debouncedSearch(e.target.value)}
      {...rest}
    />
  )
}
