import axios, { AxiosRequestConfig } from 'axios'
import { apiPath } from 'constants/index'
import { authInstance } from 'utils/utils'

// axios.interceptors.request.use(async (config) => {
//   const token = await authInstance?.currentUser?.getIdToken()
//   config.headers!['Authorization'] = `Bearer ${token}`
//   config.headers!['Content-Type'] = 'application/json'
//   return config
// })

export const request = async (config: AxiosRequestConfig) => {
  const token = await authInstance?.currentUser?.getIdToken()
  return axios({
    baseURL: `${apiPath}/api`,
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...config.headers,
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw err
    })
}
