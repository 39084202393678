import type { Direction } from 'components/Core/common/types'
import { supportedLanguages, userTypes } from 'constants/index'
import i18n from 'i18n'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type Options = { userPreferredLanguage?: string; userType?: keyof typeof userTypes }
export const useGetLanguage = (options?: Options) => {
  const [searchParams] = useSearchParams()
  const localStorageLanguage = localStorage.getItem('language')
  const [language, setLanguage] = useState(localStorageLanguage)
  const queryLanguage = searchParams.get('lng')
  useEffect(() => {
    if (queryLanguage) {
      setLanguage(queryLanguage as string)
      localStorage.setItem('language', queryLanguage)
    } else {
      if (localStorageLanguage) {
        setLanguage(localStorageLanguage)
      } else {
        localStorage.setItem('language', options?.userPreferredLanguage ?? 'en')
        setLanguage(options?.userPreferredLanguage ?? 'en')
      }
    }
  }, [localStorageLanguage, options?.userPreferredLanguage, queryLanguage])
  const isArabic = language === 'ar'
  const direction = (isArabic ? 'rtl' : 'ltr') as Direction

  useEffect(() => {
    if (language) {
      localStorage.setItem('language', language)
    }
  }, [language])

  const checkedLanguage = useMemo(() => {
    if (Object.keys(supportedLanguages).includes(String(language))) {
      return language
    } else {
      return 'en'
    }
  }, [language])
  useEffect(() => {
    i18n.changeLanguage(String(checkedLanguage))
  }, [checkedLanguage])
  return { language: String(checkedLanguage), isArabic, direction, setLanguage }
}
