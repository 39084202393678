// Instruments
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import type { TFN } from 'utils/types/types'
import * as Yup from 'yup'

/**
 *
 * @param {import('i18next').TFunction} t - The translation function to use for error messages.
 * @returns {object} - A Yup schema that can be used to validate the form fields.
 */
export const schemaValid = (t: TFN) => {
  return yupResolver(
    Yup.object().shape({
      child_name: Yup.string()
        .required(t('validation:required'))
        .max(30, t('validation:noMoreThan30CharLong'))
        .min(3, t('validation:atLeastThreeCharLong'))
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, t('validation:nameShouldNotContainNumber')),
      birth_date: Yup.string()
        .typeError(t('validation:invalidDate'))
        // .test('not-in-future', t('validation:invalidDate'), (value) => !value || new Date(value) <= new Date())
        .nullable(true)
        .required(t('validation:required')),
      concerns: Yup.array()
        // .of(
        //   Yup.lazy((value) => {
        //     switch (typeof value) {
        //       case 'object':
        //         return Yup.object().shape({
        //           value: Yup.string().min(3, t('validation:atLeastThreeCharLong')),
        //         })
        //       case 'string':
        //         return Yup.string().min(3, t('validation:atLeastThreeCharLong'))
        //       default:
        //         return Yup.mixed()
        //     }
        //   }),
        // )
        .nullable(true)
        .required(t('validation:required'))
        .min(1, t('validation:shouldHaveAtLeastOneOption')),
      languages: Yup.array()
        // .of(
        //   Yup.lazy((value) => {
        //     switch (typeof value) {
        //       case 'object':
        //         return Yup.object().shape({
        //           value: Yup.string().min(3, t('validation:atLeastThreeCharLong')),
        //         })
        //       case 'string':
        //         return Yup.string().min(3, t('validation:atLeastThreeCharLong'))
        //       default:
        //         return Yup.mixed()
        //     }
        //   }),
        // )
        .nullable(true)
        .required(t('validation:required'))
        .min(1, t('validation:shouldHaveAtLeastOneOption')),
    }),
  )
}
