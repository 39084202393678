import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import type { Course, CourseFeedbackType, Courses } from 'utils/types/types'

type RatingType = { feedbacks_count: number; feedback_average: number }

const courseServices = rootServiceApi.injectEndpoints({
  endpoints(build) {
    return {
      getCourses: build.query<Courses, { search_query: string }>({
        query: ({ search_query }) => ({
          url: apiRoutes.courses.getCourses,
          method: 'GET',
          params: {
            search_query,
          },
        }),
        providesTags: ['get-all-course'],
      }),
      getCourseDetail: build.query<Course, string>({
        query: (id) => ({
          url: `${apiRoutes.courses.getCourseDetail}${id}/`,
          method: 'GET',
        }),
        providesTags: ['get-course'],
      }),
      streamVideo: build.mutation<any, { videoId: number | string }>({
        query: ({ videoId }) => ({
          url: `${apiRoutes.courses.streamVideo}${videoId}/`,
          method: 'GET',
          responseType: 'blob',
        }),
      }),
      enrollCourse: build.mutation<any, { course_id: number | string }>({
        query: ({ course_id }) => ({
          url: apiRoutes.courses.purchase,
          method: 'POST',
          data: {
            course_id,
          },
        }),
        invalidatesTags: ['get-course'],
      }),
      updateVideoTime: build.mutation<any, { duration_seconds: number; course_id: number; journey_uid: string | undefined }>({
        query: (data) => {
          return {
            url: apiRoutes.courses.updateVideoTime,
            method: 'PUT',
            data,
          }
        },
        // invalidatesTags: ['get-course'],
      }),
      getSignedUrl: build.query<{ url: string }, { file_id: number }>({
        query: (data) => ({
          url: apiRoutes.courses.getSignedUrl,
          method: 'POST',
          data,
        }),
      }),

      getDocumentsForDocumentSection: build.query<
        Array<{ title: string; description: string; url: string; file_name: string }>,
        { course_id: number | undefined; journey_uid: string | undefined }
      >({
        query: (data) => ({
          url: apiRoutes.courses.getDocuments,
          method: 'POST',
          data,
        }),
      }),

      answerQuestion: build.mutation<any, { answer: string; course_id: number; journey_index: number; current_question: number; onSuccess: (res: any) => void }>({
        query: (data) => ({
          url: apiRoutes.courses.answerMcq,
          method: 'PUT',
          data,
        }),
        invalidatesTags: ['get-course'],
        async onQueryStarted(arg, api) {
          try {
            const { onSuccess } = arg
            const result = await api.queryFulfilled
            onSuccess(result.data)
          } catch (error) {
            console.log(error)
          }
        },
      }),
      completeLesson: build.mutation<any, { course_id: number; journey_uid: string | undefined }>({
        query: (data) => ({
          url: apiRoutes.courses.completeCourse,
          method: 'PUT',
          data,
        }),
        invalidatesTags: ['get-all-course'],
      }),

      rateCourse: build.mutation<any, { course_id: number | string | undefined; rating: number; comment: string }>({
        query: (data) => ({
          url: apiRoutes.courses.rateCourse,
          method: 'PUT',
          data,
        }),
        invalidatesTags: ['get-course', 'get-course-ratings', 'get-user-rating'],
      }),
      getCourseRatings: build.query<RatingType, { course_id: number | string | undefined }>({
        query: ({ course_id }) => ({
          url: `${apiRoutes.courses.getCourseRatings}${course_id}/`,
          method: 'GET',
        }),
        providesTags: ['get-course-ratings'],
      }),
      getUserRating: build.query<CourseFeedbackType, { course_id: string | number | undefined }>({
        query: ({ course_id }) => ({
          url: `${apiRoutes.courses.getUserRating}${course_id}`,
          method: 'GET',
        }),
        providesTags: ['get-user-rating'],
      }),
    }
  },
})

export const {
  useGetCoursesQuery,
  useGetCourseDetailQuery,
  useStreamVideoMutation,
  useEnrollCourseMutation,
  useUpdateVideoTimeMutation,
  useGetSignedUrlQuery,
  useAnswerQuestionMutation,
  useCompleteLessonMutation,
  useGetDocumentsForDocumentSectionQuery,
  useRateCourseMutation,
  useGetCourseRatingsQuery,
  useGetUserRatingQuery,
} = courseServices
