import { useCallback, useMemo } from 'react'

import { Direction } from 'components/Core/common/types'
import { courseArabic } from 'navigations/locale/course_arabic'
import { courseEnglish } from 'navigations/locale/course_english'

export const useCourseContent = (language?: string | undefined) => {
  const courseContent = useMemo(() => {
    if (language === 'ar') return { content: courseArabic, direction: 'rtl' as Direction }

    return { content: courseEnglish, direction: 'ltr' as Direction }
  }, [language])

  const getContent = useCallback((lng: string) => {
    if (lng === 'ar') return courseArabic

    return courseEnglish
  }, [])

  return { ...courseContent, getContent }
}
