import { ONBOARDING_STEP } from 'data/enums'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectABTestingState } from 'defaultLayout/slice/selectors'
import type { OnboardingStep } from 'defaultLayout/types'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import type { ABTestingVariant } from 'utils/types/types'
// TODO: remove variant B and change the code to use only variant A
export const useQuestionnaireForm = (skipQueryParams = false) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { actions, dispatch } = useDefaultLayoutSlice()
  const abTestingState = useSelector(selectABTestingState)

  /**@MemoState */

  // const isVariantA = useMemo(() => abTestingState.variant === 'A', [abTestingState.variant])

  // const isVariantB = false

  /**@Effects */
  useEffect(() => {
    // let variant = 'A' as ABTestingVariant
    localStorage.setItem('variant', 'A')

    // if (!variant) {
    //   variant = Math.random() < 0.5 ? 'A' : 'B'
    //   localStorage.setItem('variant', variant)
    // }
    // dispatch(actions.setABTestingState({ variant: 'A' }))

    dispatch(
      actions.setABTestingState({
        addChildStep: 2,
      }),
    )
  }, [])

  const queries = useMemo(() => {
    const languageFromSearchQuery = searchParams?.get('lng')
    // const lng = languageFromSearchQuery || 'en'
    return [
      // { key: 'variant', value: abTestingState.variant },
      { key: 'questionnaireStep', value: abTestingState.questionnaireStatementStep },
      { key: 'step', value: abTestingState.onboardingStep },
      // { key: 'lng', value: lng },
    ]
  }, [abTestingState, searchParams.get('lng')])

  const formatUrl = useCallback(() => {
    // Create a new URLSearchParams object with 'lng' as the first parameter
    const newSearchParams = new URLSearchParams()
    // Add all other existing parameters
    queries.forEach((item) => {
      if (item.value) {
        if (abTestingState.onboardingStep !== ONBOARDING_STEP.QUESTIONNAIRE && item.key === 'questionnaireStep') {
          newSearchParams.delete(item.key)
        } else {
          if (newSearchParams.has(item.key)) {
            newSearchParams.delete(item.key)
          }
          newSearchParams.append(item.key, String(item.value))
        }
      }
    })

    // Set the new search params
    setSearchParams(newSearchParams)
  }, [queries])

  useEffect(() => {
    if (!skipQueryParams) formatUrl()
  }, [formatUrl, skipQueryParams])

  /**@EndEffects */

  /**@Handlers */
  const getSteps = useCallback((questionnaireStatementStep: number | string) => {
    dispatch(
      actions.setABTestingState({
        questionnaireStatementStep,
      }),
    )
  }, [])

  const navigateToParentForm = useCallback(() => {
    if (abTestingState.variant === 'A') {
      dispatch(
        actions.setABTestingState({
          onboardingStep: 'form_registration',
        }),
      )
    }
  }, [abTestingState.variant])

  // const removeStepQuery = useCallback(() => {
  //   searchParams.delete('questionnaireStep')
  //   setSearchParams(searchParams)
  // }, [searchParams])

  const setOnboardingStep = useCallback((onboardingStep: OnboardingStep) => {
    dispatch(
      actions.setABTestingState({
        onboardingStep,
      }),
    )
  }, [])
  const setAddChildStep = useCallback((addChildStep: number) => {
    dispatch(
      actions.setABTestingState({
        addChildStep,
      }),
    )
  }, [])

  /**@EndHandlers */

  return { abTestingState, isVariantA: true, isVariantB: false, setOnboardingStep, getSteps, navigateToParentForm, setAddChildStep }
}
