// Core
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Actions
import { advisorOnbordingActions } from '../../actions/advisorOnbordingActions'
//Components
import { GeneralProfileInformation, ParentProfileQuestionnairePart, ProfileTherapistsBlock } from '../../components'
// Styles
import Styles from './Styles.module.scss'
import { CustomTabs } from 'components/shared/CustomTab/Tabs'
import { TreatmentPlan } from 'pages/ShardPage/TreatmentPlan/TreatmentPlan'
import { ParentProfileQuestionnairePartWithTranslation } from 'components/ClientProfileComponents/QuestionnairePartWithTranslation'

export const AdvisorAssignTherapistsPage = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const currentProfile = useSelector((state) => state.advisorOnbording.currentParent)
  const allTherapists = useSelector((state) => state.advisorOnbording.allTherapists)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [assignedTherapists, setAssignedTherapists] = useState(null)
  const [refetch, setRefetch] = useState(true)

  useEffect(() => {
    if (profileId) {
      const dataForRequest = {
        id: profileId,
      }

      dispatch(advisorOnbordingActions.getParentInfoForAssigning(dataForRequest))
      dispatch(advisorOnbordingActions.getAllTherapists())
    }
  }, [profileId, refetch])
  const questions = useMemo(() => {
    return Array.isArray(currentProfile?.questions) ? currentProfile?.questions : Object.values(currentProfile?.questions || {})
  }, [currentProfile?.questions])
  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.profile?.full_name,
        age: currentProfile?.profile?.age,
        birth_date: currentProfile?.profile?.birth_date,
        languages: currentProfile?.profile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
        assignedTherapists: currentProfile?.therapists,
        parent_preferred_language: currentProfile?.parent?.preferred_language,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const finalQuestionnaire = currentProfile?.profile?.questionnaire?.questionnaire.map((category) => {
        // const questions = Array.isArray(currentProfile?.questions) ? currentProfile?.questions : Object.values(currentProfile?.questions || {})

        const matchingQuestion = questions.find((q) => q.id === category.id)

        return {
          ...category,
          advisor_comment: matchingQuestion?.advisor_comment || '',
        }
      })

      const questionnaire = {
        familyDynamics: currentProfile?.profile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.profile?.questionnaire?.parental_wellbeing,
        questionnaire: finalQuestionnaire,
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setQuestionnaire(questionnaire)
      setAssignedTherapists(currentProfile?.therapists)
    }
  }, [currentProfile])
  return (
    <div className={Styles.main_container}>
      <div className={Styles.general_block}>
        <CustomTabs
          tabs={[
            {
              label: 'User Profile',
              component: (
                <>
                  <div className={Styles.general_block__info}>
                    <GeneralProfileInformation profile={profileInfo} client={clientInfo} />
                  </div>
                  <div className={Styles.questionnaire_block}>
                    <ParentProfileQuestionnairePartWithTranslation
                      advisor_additional_comment={questions?.[questions?.length - 1]?.additional_advisor_comments}
                      questionnaire={questionnaire}
                      profile={profileInfo}
                      style={{ width: '100%' }}
                    />
                  </div>
                </>
              ),
            },
            {
              label: 'Treatment Plan',
              component: <TreatmentPlan allowEdit={false} name={profileInfo?.full_name} profile_id={currentProfile?.profile?.id} />,
            },
          ]}
        />
      </div>
      <div className={Styles.therapists_block}>
        <ProfileTherapistsBlock allTherapists={allTherapists} assignedTherapists={assignedTherapists} setRefetch={setRefetch} />
      </div>
    </div>
  )
}
