import { useEffect, useState } from 'react'
import { useGetActiveUsersQuery } from './service'
import { CenterColumn, CenterRow, Text } from 'components/Core'
import { DateRangePopover } from './DatePopover'
import { RangeKeyDict } from 'react-date-range'
import { usePagination } from 'hooks/usePagination'
import { MuiTable } from 'components/MuiTable/MuiTable'
import { currencyFormatter } from 'utils/utils'

export const ActiveUsers = () => {
  const { page, setTotalItems, goToPage, totalPages, pageSize, changePageSize } = usePagination({
    initialPageSize: 10,
    initialPage: 0,
  })
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const { data, isFetching } = useGetActiveUsersQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
    page: page + 1,
    page_size: pageSize,
  })
  const handleSelect = (ranges: RangeKeyDict) => {
    setStartDate(ranges.selection.startDate!)
    setEndDate(ranges.selection.endDate!)
    goToPage(0)
  }
  useEffect(() => {
    if (data?.count) setTotalItems(data?.count)
  }, [data?.count])
  return (
    <CenterColumn gap="20px" p={2} pt={20}>
      <CenterRow width="100%" justifyContent="space-between">
        <Text variant="heading2">Active Users</Text>
        <DateRangePopover
          handleClear={() => {
            setStartDate(undefined)
            setEndDate(undefined)
            goToPage(0)
          }}
          endDate={endDate}
          startDate={startDate}
          handelSelect={handleSelect}
        />
      </CenterRow>
      <CenterRow bg="white" p={3} borderRadius={1} width="100%" gap="30px">
        <CenterRow gap="2px">
          <Text width="auto" variant="heading4">
            Overall totals :
          </Text>
          {/* <Tooltip title="Total session and total revenue, can be affected by date range, if date range is not selected then it is going be over all total">
            <IconButton size="small">
              <QuestionMark fontSize="small" />
            </IconButton>
          </Tooltip> */}
          {/* <Text width="auto" variant="heading4">:</Text> */}
        </CenterRow>
        <CenterRow gap={10} borderRight={1} pr={3}>
          {/* Total session */}
          <Text fontWeight="bold">Total non paid sessions:</Text>
          <Text>{data?.totals.non_paid_sessions}</Text>
        </CenterRow>
        <CenterRow gap={10} borderRight={1} pr={3}>
          {/* Total session */}
          <Text fontWeight="bold">Total paid session:</Text>
          <Text>{data?.totals.paid_sessions}</Text>
        </CenterRow>
        <CenterRow gap={10} borderRight={1} pr={3}>
          {/* Total session */}
          <Text fontWeight="bold">Total Sessions:</Text>
          <Text>{data?.totals.total_sessions}</Text>
        </CenterRow>
        <CenterRow gap={10}>
          {/* Total session */}
          <Text fontWeight="bold">Total Revenue:</Text>
          <Text>
            {currencyFormatter({
              amount: data?.totals.total_revenue,
            })}
          </Text>
        </CenterRow>
      </CenterRow>
      <MuiTable data={data} isFetching={isFetching} changePageSize={changePageSize} pageSize={pageSize} goToPage={goToPage} page={page} />
    </CenterColumn>
  )
}
