import type { ChildInfoType } from './slice/type'
import { useForm } from 'react-hook-form'
import { Box, Button, CenterColumn, CenterRow, Chip, Container, Flex, Text } from 'components/Core'
import { TextField } from '@mui/material'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { languages, parent_onboard_child_concerns } from 'data'
import { statusColor } from 'constants/index'
import { schemaValid } from './hookForm'

import { useParentOnboardingSlice } from './slice'
import { TFN } from 'utils/types/types'
import type { OnboardingStep } from 'defaultLayout/types'
import { Check } from '@mui/icons-material'

type Props = {
  t: TFN
  setOnboardingStep: (onboardingStep: OnboardingStep) => void
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  getProgressUpdate: () => void
  decreaseQuestionIndex: () => void
  increaseQuestionIndex: () => void
}

export const ChildInfo = ({ t, setOnboardingStep, currentStep, setCurrentStep, getProgressUpdate, decreaseQuestionIndex, increaseQuestionIndex }: Props) => {
  const { isArabic, direction } = useGetLanguage()
  const { childInfo, updateChildInfo } = useParentOnboardingSlice()

  const { register, watch, setValue, formState, trigger } = useForm<ChildInfoType>({
    defaultValues: childInfo,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: schemaValid(t),
  })

  const validateAndProceed = (nextStep: number) => {
    getProgressUpdate()
    increaseQuestionIndex()
    switch (currentStep) {
      case 1:
        if (!formState.errors.child_name && watch('child_name')) {
          setCurrentStep(nextStep)
        } else {
          trigger('child_name')
        }
        break
      case 2:
        if (!formState.errors.birth_date && watch('birth_date')) {
          setCurrentStep(nextStep)
        } else {
          trigger('birth_date')
        }
        break
      case 3:
        if (!formState.errors.concerns && watch('concerns').length > 0) {
          setCurrentStep(nextStep)
        } else {
          trigger('concerns')
        }
        break
      case 4:
        if (watch('languages').length > 0) {
          // Handle final step submission
          setOnboardingStep('questionnaire')
        } else {
          trigger('languages')
        }
        break
    }
  }

  useEffect(() => {
    localStorage.setItem('childInfo', JSON.stringify(childInfo))
  }, [childInfo])
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <CenterColumn width={['100%']} gap="25px">
            <CenterColumn width={['100%']} gap="5px">
              <Text maxWidth="100%" variant="heading4">
                {t('parentOnboarding:whatIsYourChildName')}
              </Text>
            </CenterColumn>
            <CenterColumn gap="5px">
              <TextField
                error={Boolean(formState.errors.child_name?.message)}
                placeholder={t('parentOnboarding:childName')}
                {...register('child_name')}
                onChange={(e) => {
                  setValue('child_name', e.target.value, { shouldValidate: true })
                  updateChildInfo({ child_name: e.target.value })
                }}
              />
              <Text pr={1} variant="error">
                {formState.errors.child_name?.message}
              </Text>
            </CenterColumn>
            <CenterRow justifyContent={'center'} gap="10px" flexWrap="wrap">
              <Button
                disabled={Boolean(formState.errors.child_name || !watch('child_name'))}
                alignSelf="center"
                flexGrow={1}
                onClick={() => {
                  validateAndProceed(2)
                }}
              >
                {t('parentOnboarding:next')}
              </Button>
            </CenterRow>
          </CenterColumn>
        )
      case 2:
        return (
          <CenterColumn gap="25px">
            <CenterColumn width={['100%']} gap="5px">
              <Text maxWidth="100%" variant="heading4">
                {t('parentOnboarding:whatIsYourChildBirthdate')}
              </Text>
              <Text maxWidth="100%" variant="body5">
                {t('parentOnboarding:childBirthdateSubtitle')}
              </Text>
            </CenterColumn>
            <Box direction="ltr">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* @ts-ignore */}
                <StaticDatePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={watch('birth_date')}
                  onChange={(date) => {
                    setValue('birth_date', date, { shouldValidate: true })
                    updateChildInfo({ birth_date: date })
                  }}
                  // substruct from current date 18years
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                  disableFuture
                  componentsProps={{
                    actionBar: {
                      actions: [],
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Text variant="error">{formState.errors.birth_date?.message}</Text>
            <CenterRow justifyContent={'center'} gap="10px" flexWrap="wrap">
              <Button
                variant="secondary"
                alignSelf="center"
                flexGrow={1}
                onClick={() => {
                  setCurrentStep(1)
                  decreaseQuestionIndex()
                }}
              >
                {t('parentOnboarding:back')}
              </Button>
              <Button disabled={Boolean(formState.errors.birth_date || !watch('birth_date'))} alignSelf="center" flexGrow={1} onClick={() => validateAndProceed(3)}>
                {t('parentOnboarding:next')}
              </Button>
            </CenterRow>
          </CenterColumn>
        )
      case 3:
        return (
          <CenterColumn height="auto" width="100%" gap="25px">
            <CenterColumn width={['100%']} gap="5px">
              <Text maxWidth="100%" variant="heading4">
                {t('parentOnboarding:whatConcernsDoYouHave')}
              </Text>
              <Text variant="body5"> {t('parentOnboarding:childConcernsSubtitle')}</Text>
            </CenterColumn>
            {/* Concerns selection component */}
            <CenterColumn gap="5px">
              <CenterRow pl={[3, 3, 0]} flexWrap={['wrap']} gap="10px">
                {parent_onboard_child_concerns.map((concern) => (
                  <Chip
                    onClick={() => {
                      if (watch('concerns').includes(concern.value)) {
                        const updatedConcerns = watch('concerns').filter((c) => c !== concern.value)
                        setValue('concerns', updatedConcerns, { shouldValidate: true })

                        updateChildInfo({ concerns: updatedConcerns })
                      } else {
                        setValue('concerns', [...watch('concerns'), concern.value], { shouldValidate: true })
                        updateChildInfo({ concerns: [...watch('concerns'), concern.value] })
                      }
                    }}
                    gap={'5px'}
                    padding={15}
                    fontSize={isArabic ? [13, 13, 15, 18] : [14, 15, 18, 20]}
                    cursor="pointer"
                    key={concern.value}
                    value={isArabic ? concern.ar : concern.en}
                    bg={watch('concerns').includes(concern.value) ? statusColor.default.color : statusColor.default.backgroundColor}
                    color={watch('concerns').includes(concern.value) ? 'white' : statusColor.default.color}
                    leftAdornment={watch('concerns').includes(concern.value) && <Check fontSize="small" />}
                  />
                ))}
              </CenterRow>
              <Text pl={[3]} variant="error">
                {formState.errors.concerns?.message}
              </Text>
            </CenterColumn>
            <CenterRow justifyContent={'center'} gap="10px" flexWrap="wrap">
              <Button
                variant="secondary"
                alignSelf="center"
                flexGrow={1}
                onClick={() => {
                  setCurrentStep(2)
                  decreaseQuestionIndex()
                }}
              >
                {t('parentOnboarding:back')}
              </Button>
              <Button disabled={Boolean(formState.errors.concerns || !watch('concerns')?.length)} alignSelf="center" flexGrow={1} onClick={() => validateAndProceed(4)}>
                {t('parentOnboarding:next')}
              </Button>
            </CenterRow>
          </CenterColumn>
        )
      case 4:
        return (
          <CenterColumn gap="25px">
            <CenterColumn width={['100%']} gap="5px">
              <Text maxWidth="100%" variant="heading4">
                {t('parentOnboarding:whatLanguageYourChildSpeaks')}
              </Text>
              <Text variant="body5">{t('parentOnboarding:childLanguageSubtitle')}</Text>
            </CenterColumn>
            <CenterColumn gap="5px">
              <CenterRow pl={[3, 3, 0]} flexWrap={['wrap']} gap="10px">
                {languages.map((language) => (
                  <Chip
                    onClick={() => {
                      if (watch('languages').includes(language.value)) {
                        const updatedLanguages = watch('languages').filter((c) => c !== language.value)
                        setValue('languages', updatedLanguages, { shouldValidate: true })
                        updateChildInfo({ languages: updatedLanguages })
                      } else {
                        setValue('languages', [...watch('languages'), language.value], { shouldValidate: true })
                        updateChildInfo({ languages: [...watch('languages'), language.value] })
                      }
                    }}
                    gap={'5px'}
                    padding={10}
                    fontSize={isArabic ? [13, 13, 15, 18] : [14, 14, 18, 20]}
                    cursor="pointer"
                    bg={watch('languages').includes(language.value) ? statusColor.default.color : statusColor.default.backgroundColor}
                    color={watch('languages').includes(language.value) ? 'white' : statusColor.default.color}
                    key={language.value}
                    value={isArabic ? language.ar : language.value}
                    leftAdornment={
                      watch('languages').includes(language.value) && (
                        <Check
                          // fontSize="small"
                          sx={{
                            fontSize: '14px',
                          }}
                        />
                      )
                    }
                  />
                ))}
              </CenterRow>
              <Text pl={[3]} variant="error">
                {formState.errors.languages?.message}
              </Text>
            </CenterColumn>
            <CenterRow justifyContent={'center'} gap="10px" flexWrap="wrap">
              <Button
                variant="secondary"
                alignSelf="center"
                flexGrow={1}
                onClick={() => {
                  setCurrentStep(3)
                  decreaseQuestionIndex()
                }}
              >
                {t('parentOnboarding:back')}
              </Button>
              <Button disabled={Boolean(formState.errors.languages || !watch('languages')?.length)} onClick={() => validateAndProceed(5)} alignSelf="center" flexGrow={1}>
                {t('parentOnboarding:next')}
              </Button>
            </CenterRow>
          </CenterColumn>
        )
    }
  }

  return (
    <Flex
      justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']}
      alignItems={['center']}
      bg="white"
      flexDirection="column"
      direction={direction}
      // minHeight={'100%'}
      py={16}
      height="auto"
      width="100%"
    >
      <Container
        flexDirection={['column']}
        borderRadius={10}
        bg="white"
        width={['100%', '100%', '90%', '804px']}
        px={[2, 2, 24]}
        overflowY="scroll"
        pb={['40px !important']}
        gap="25px"
        direction={direction}
      >
        {renderStep()}
      </Container>
    </Flex>
  )
}
