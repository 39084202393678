//Core
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//MUI
import { Modal, ToggleButtonGroup, ToggleButton, IconButton } from '@mui/material'
//Images
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
//Components
import { CalComIntegration } from '../../../GeneralComponents'
import { useTranslation } from 'react-i18next'
import english from './locale/en.json'
import arabic from './locale/ar.json'
import { Box, Button, CenterRow, Container } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'

const AddProfilePopup = ({ open, onClose, isAdultHasProfile }) => {
  const language = localStorage.getItem('language')
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.user)
  const [calComLink, setCalComLink] = useState('')

  const [profileOption, setProfileOption] = useState('')
  const [isOpenCalcomPopup, setIsOpenCalcomPopup] = useState(false)
  const [searchParams] = useSearchParams()

  // const handleOpenAddProfileCalcomPopup = useCallback(() => {
  //   if (profileOption === 'adult') {
  //     setIsOpenCalcomPopup(true)
  //     setCalComLink(process.env.REACT_APP_CAL_COM_LINK_PARENT)
  //   }

  //   if (profileOption === 'child') {
  //     navigate(`add_questionnaire?lng=${searchParams.get('lng') ?? localStorage.getItem('language') ?? 'en'}`)
  //     onClose()
  //   }
  // }, [profileOption, searchParams.get('lng')])

  const handleCloseAddProfileCalcomPopup = () => {
    setIsOpenCalcomPopup(false)
    onClose()
  }

  const handleChange = (_, newProfileOption) => {
    if (newProfileOption === 'adult') {
      setIsOpenCalcomPopup(true)
      setCalComLink(process.env.REACT_APP_CAL_COM_LINK_PARENT)
    }

    if (newProfileOption === 'child') {
      // navigate(`add_questionnaire?lng=${searchParams.get('lng') ?? localStorage.getItem('language') ?? 'en'}`)
      navigate('/profile/parent/additional-info')
      onClose()
    }
  }
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.addResourceBundle('en', 'addProfilePopup', english)
    i18n.addResourceBundle('ar', 'addProfilePopup', arabic)
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const { direction } = useGetLanguage({ userType: 'parent' })

  return (
    <Modal open={open} onClose={onClose}>
      <Container gap={[16]} variant="modal-container">
        <CenterRow width="100%" direction={direction} justifyContent="space-between">
          <h2 className={Styles.profile_title}>{t('addProfilePopup:newProfile')}</h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </CenterRow>
        <p className={Styles.profile_question}>{t('addProfilePopup:createProfileFor')}</p>
        <CenterRow py={10} className={Styles.profile_buttons}>
          <ToggleButtonGroup color="secondary" exclusive value={profileOption} onChange={handleChange}>
            <ToggleButton disabled={isAdultHasProfile} value="adult">
              {t('addProfilePopup:myself')}
            </ToggleButton>
            <ToggleButton value="child">{t('addProfilePopup:child')}</ToggleButton>
          </ToggleButtonGroup>
        </CenterRow>
        {/* <div className={Styles.profile_next_block}>
          <Button flexGrow={1} onClick={handleOpenAddProfileCalcomPopup}>
            {t('addProfilePopup:next')}
          </Button>
        </div> */}
        {isOpenCalcomPopup && (
          <CalComIntegration userInfo={user?.user ?? user} open={isOpenCalcomPopup} onClose={handleCloseAddProfileCalcomPopup} link={calComLink} isRegularCall={false} />
        )}
      </Container>
    </Modal>
  )
}

export default AddProfilePopup
