import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type MutationQueryArgs = {
  body: {
    feedback: string | undefined
    tell_us_more: string | undefined
    meeting_id: string | number | null
  }
}

type GetQueryArgs = {
  meeting_id: string | number | null
  token: string | undefined | null
}

export const checkEmailExistenceServiceForAuthenticatedUser = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    feedBack: build.mutation<{ message: string }, MutationQueryArgs>({
      query: ({ body }) => {
        return {
          url: apiRoutes.submitFeedback,
          method: 'POST',
          data: body,
        }
      },
    }),
    getFeedback: build.query<boolean, GetQueryArgs>({
      query: ({ meeting_id, token }) => {
        return {
          url: `${apiRoutes.submitFeedback}${meeting_id}`,
          method: 'GET',
          // headers: {
          //   Authorization: `Token ${token}`,
          // },
        }
      },
    }),
  }),
})

export const { useFeedBackMutation, useGetFeedbackQuery } = checkEmailExistenceServiceForAuthenticatedUser
